import React, { useCallback, useMemo, useState } from 'react';
import store from 'store';

import {
  AppQuery,
  AppQueryFilter,
  FilterOperator,
  QueryFilterCombinator,
  combineFilters,
} from '@nl-lms/common/shared';
import {
  Button,
  FloatingMenu,
  Icon,
  PrettyDate,
  StatusTag,
  Timeline,
  TimelineActions,
  TimelineBody,
  TimelineDayEvent,
  TimelineHeader,
  TimelineHeaderTooltip,
  TimelineName,
  TimelineNavigation,
  TimelineProvider,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { dateFns } from '@nl-lms/vendor';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { authStore } from '../../../../_common/modules/Auth/auth';
import { adminApi } from '../../../../_common/services/api';
import { routes } from '../../../../lib/routes';
import './AdminLiveSessionDayTimeline.scss';

const { useListLiveSessionsQuery } = adminApi;

const StatusToEventTypeRecord: Record<
  keyof typeof C.SESSION_STATUSES,
  TimelineDayEvent['type']
> = {
  [C.I_SESSION_STATUSES.DRAFT]: 'grey',
  [C.I_SESSION_STATUSES.CANCELED]: 'red',
  [C.I_SESSION_STATUSES.COMPLETED]: 'green',
  [C.I_SESSION_STATUSES.READY]: 'blue',
  [C.I_SESSION_STATUSES.PROCESSING]: 'yellow',
};

export const AdminLiveSessionDayTimeline = ({
  baseFilters,
  title,
  description,
}: {
  baseFilters?: AppQueryFilter;
  title?: string;
  description?: string;
}) => {
  const defaultCurrentDate = useMemo(() => {
    const defaults = store.get('liveSessionDayTimelineDefaults') || {};
    try {
      return defaults.currentDate ? new Date(defaults.currentDate) : new Date();
    } catch (e) {
      return new Date();
    }
  }, []);

  const activeDate = useMemo(() => defaultCurrentDate, []);
  const [startDate, setStartDate] = useState(() =>
    dateFns.startOfWeek(activeDate),
  );
  const [endDate, setEndDate] = useState(() => dateFns.endOfWeek(activeDate));
  const [sessionsVisibility, setSessionsVisibility] = useState<'owned' | 'all'>(
    'all',
  );
  const userId = authStore.user.id;

  const onChangeDates = useCallback((start, end) => {
    setStartDate(dateFns.startOfDay(start));
    setEndDate(dateFns.endOfDay(end));
    store.set('liveSessionDayTimelineDefaults', {
      currentDate: dateFns.startOfDay(start),
    });
  }, []);

  // @ts-ignore
  const query: AppQuery = useMemo(() => {
    const baseQuery = {
      pagination: { disabled: true },
      sorting: [['startDate', 'asc']],
      filters: combineFilters(
        {
          id: 'timeline',
          value: [
            {
              id: 'date-filter-start',
              combinator: QueryFilterCombinator.And,
              value: {
                field: { field: 'start_date', label: 'startDate' },
                operator: FilterOperator.GreaterThan,
                value: {
                  value: startDate.toISOString(),
                  label: 'value',
                },
              },
            },
            {
              id: 'date-filter-end',
              combinator: QueryFilterCombinator.And,
              value: {
                field: { field: 'start_date', label: 'startDate' },
                operator: FilterOperator.LowerThan,
                value: { value: endDate.toISOString(), label: 'value' },
              },
            },
            ...(sessionsVisibility === 'owned'
              ? [
                  {
                    id: 'owner',
                    combinator: QueryFilterCombinator.And,
                    value: {
                      field: { field: 'userId', label: 'userId' },
                      operator: FilterOperator.Includes,
                      value: { value: userId, label: userId },
                    },
                  },
                ]
              : []),
          ],
        },
        baseFilters,
      ) as AppQueryFilter,
    };

    return baseQuery;
  }, [startDate, endDate, sessionsVisibility]);
  const { data, error } = useListLiveSessionsQuery(query);
  useQueryErrorHandler({ error });

  const sessions = data ? data.rows : [];

  const events = sessions.map((s) => ({
    title: s.name,
    id: s.id,
    start: new Date(s.startDate),
    end: new Date(s.endDate),
    type: StatusToEventTypeRecord[s.status],
    TooltipComponent: LiveSessionTimelineTooltip,
    eventObject: s,
  }));

  return (
    <TimelineProvider defaultWeeks={1} defaultCurrentDate={defaultCurrentDate}>
      <TimelineHeader>
        <TimelineName>
          {title || 'Live Sessions'}
          <TimelineHeaderTooltip title={description} />
        </TimelineName>

        <TimelineNavigation
          onChange={onChangeDates}
          showUpcomingEvents={false}
        />
        <TimelineActions>
          <FloatingMenu
            items={[
              {
                name: 'All Sessions',
                handler: () => setSessionsVisibility('all'),
              },
              {
                name: 'My Sessions',
                handler: () => setSessionsVisibility('owned'),
              },
            ]}
          >
            <Button
              label={
                sessionsVisibility === 'owned' ? 'My Sessions' : 'All sessions'
              }
              icon={<Icon.ArrowDownIcon />}
              small
              regular
            />
          </FloatingMenu>
        </TimelineActions>
      </TimelineHeader>
      <TimelineBody events={events} tooltipHeight={120} />
    </TimelineProvider>
  );
};

const LiveSessionTimelineTooltip = ({ eventObject: session }) => {
  const goToLiveSessionViewPage = useRoutingAction({
    route: (id) => routes.admin.manage.liveSessions.item.path.full(id),
  });
  return (
    <>
      <div
        className="live-session-timeline-tooltip__link"
        onMouseDown={() => goToLiveSessionViewPage(session.id)}
      >
        <Typography.h3>{session.name}</Typography.h3>
      </div>
      <div style={{ marginTop: '10px' }}>
        <StatusTag status={C.SESSION_STATUSES[session.status]} />
      </div>
      <Typography.p style={{ color: '#7A7F82', marginTop: '10px' }}>
        <PrettyDate value={session.startDate} withTime />
        -
        <PrettyDate value={session.endDate} withTime />
      </Typography.p>
      <Typography.p style={{ color: '#7A7F82', marginTop: '5px' }}>
        {session.registered}/{session.maxPax} Learners
      </Typography.p>
    </>
  );
};
