import {
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { RegistrationRouterContract } from './registration.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'registrationStore';

  const tsRest = buildTsRestInstance<typeof RegistrationRouterContract>(
    apiInstance,
    RegistrationRouterContract,
    apiOptions,
  );

  const rtk = buildRtkInstance(tsRest, RegistrationRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
