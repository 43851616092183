import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { ArrowDownIcon } from '../Icon';
import { TidComponent } from '../index.types';
import './DownshiftSelect.scss';

export const SelectSkeleton = (
  props: TidComponent<{
    hasError?: boolean;
    placeholder?: string;
    disabled?: boolean;
  }>,
) => {
  const {
    hasError = false,
    placeholder = 'Select or type value to search',
    disabled = false,
  } = props;
  const wrapperClassNameProps = useClassNameProps(
    'downshift-select__input-wrapper',
    hasError ? 'downshift-select__input-wrapper--has-error' : null,
    disabled ? 'downshift-select__input-wrapper--disabled' : null,
  );
  const commonProps = useTestProps(props);

  return (
    <div className="downshift-select" {...commonProps}>
      <div {...wrapperClassNameProps}>
        <div
          className={`downshift-select__input downshift-select__input--skeleton ${
            disabled ? 'downshift-select__input--disabled' : ''
          }`}
        >
          {placeholder}
        </div>
        <div className="downshift-select__input-icons downshift-select__input-icons--skeleton">
          <div className="downshift-select__spinner" />
          <div
            className={`downshift-select__input-icon ${
              disabled ? 'downshift-select__input-icon--disabled' : ''
            }`}
            aria-label="toggle menu"
          >
            <ArrowDownIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
