import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AdminApiEndpointNames, adminApi } from './adminApi';
import { OrganizationApiEndpointNames, organizationApi } from './organizationApi';

export function useGetResourceForTableRowAction(
  endpoint: AdminApiEndpointNames | OrganizationApiEndpointNames,
  _api?: (typeof  adminApi) | (typeof organizationApi),
) {
  const dispatch = useDispatch();
  const api = _api || adminApi;
  const buildArg = _api ? row => ({id: row.id}) : row => row.id;

  // @ts-ignore;
  const prefetchResource = api.usePrefetch(endpoint);

  const prefetch = useCallback((row) => {
    console.log(buildArg(row));
    prefetchResource(buildArg(row));
  }, []);

  const get = useCallback(async (row) => {
    const resultPromise = dispatch(
      // @ts-ignore
      api.endpoints[endpoint].initiate(buildArg(row)),
    );
    const result = await resultPromise;
    // @ts-ignore
    if (result.status == 'fulfilled') {
      // @ts-ignore
      resultPromise.unsubscribe();
      // @ts-ignore
      return result.data;
    }
    return false;
  }, []);

  return { get, prefetch };
}
