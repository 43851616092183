import React, { useCallback, useState } from 'react';

import {
  Box,
  Button,
  FormField,
  Modal,
  SingleSelect,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { getSessionLearnerStatusOptions } from '@nl-lms/ui/utils';

import { useAction } from '../../../../_common/hooks/useAction';
import { adminApi } from '../../../../_common/services/api';

const { useUpdateLiveSessionLearnerStatusMutation } = adminApi;

export const AdminLiveSessionUpdateLearnerStatusModal = ({ list }) => {
  const { hide } = useModal();
  const [status, setLearnerStatus] = useState(null);
  const [updateLiveSessionLearnerStatus, { isLoading, error }] =
    useUpdateLiveSessionLearnerStatusMutation();
  const updateLiveSessionLearnerStatusAction = useAction(
    updateLiveSessionLearnerStatus,
    {
      successMessage:
        'The live session learner status has been updated successfully',
    },
  );

  const onSubmit = useCallback(async () => {
    if (!status) return;
    const result = await updateLiveSessionLearnerStatusAction({ status, list });
    if (result && 'data' in result) {
      hide({ success: true });
    }

    return result;
  }, [status, list]);

  return (
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>Update Learner Status</Modal.Title>
      </Modal.Header>
      <Box>
        <FormField
          label="Learner Status"
          errorMessage={getTranslatedMessageFromError(error)}
        >
          <SingleSelect
            hasError={!!error}
            name="learnerStatus"
            options={getSessionLearnerStatusOptions()}
            onChange={setLearnerStatus}
          />
        </FormField>
      </Box>
      <Modal.Actions>
        <Button
          label="Submit"
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={!status}
        />
      </Modal.Actions>
    </Modal.Content>
  );
};
