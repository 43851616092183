import { z } from 'zod';

// Used to apply array.filter in a typesafe way
export function isValue<T>(argument: T | undefined | null): argument is T {
  return argument !== undefined && !!argument;
}

export const isObject = (arg: unknown): arg is Record<string, unknown> =>
  typeof arg === 'object' && !Array.isArray(arg) && arg !== null;

export function getEnumKeys<T extends object>(enumObj: T): Array<keyof T> {
  return Object.keys(enumObj).filter((key) => isNaN(Number(key))) as Array<
    keyof T
  >;
}

export type ListResponse<T> = {
  rows: T[];
  count: number;
};

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> &
    Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
