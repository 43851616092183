import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { LearningAssignmentRouterContract } from './learning-assignment.router-contract.sdk';

export const getLearningAssignmentClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'learningAssignmentStore';

  const tsRest = buildTsRestInstance<typeof LearningAssignmentRouterContract>(
    apiInstance,
    LearningAssignmentRouterContract,
    apiOptions,
  );

  const rtk = buildRtkInstance(tsRest, LearningAssignmentRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
