import i18n from 'i18next';

import { C } from '@nl-lms/ui/constants';
import { _ } from '@nl-lms/vendor';

export const updateLanguage = (activeApp, user) => {
  const userAdminLanguage = _.get(user, 'settings.i18nAdmin') || C.I18N;
  let userPortalLanguage = _.get(user, 'settings.i18nPortal') || C.I18N;

  // TODO: I'm forcing the language because there is some issue
  // with how the language preferences are loaded
  // if a user is not authenticated userPortalLanguage will be set to 'en'
  // instead of 'ro' by default
  if (userPortalLanguage === 'en' && activeApp === 'public')
    userPortalLanguage = 'ro';

  let appLanguage;
  if (activeApp === 'admin') {
    appLanguage = userAdminLanguage;
    if (window.location.pathname.includes('external')) appLanguage = C.I18N;
  } else {
    appLanguage = userPortalLanguage;
  }

  i18n.changeLanguage(appLanguage).then(console.log).catch(console.error);
};
