import * as Dialog from '@radix-ui/react-dialog';
import React, { useCallback, useContext } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import * as Icon from '../Icon';
import { Separator } from '../Separator/Separator';
import { Typography } from '../Typography/Typography';
import { TidComponent } from '../index.types';
import { ModalContext } from './Modal';
import './SideModal.scss';

export const SideModalContent = React.forwardRef<
  HTMLDivElement,
  TidComponent<{
    children: React.ReactNode;
    container?: Dialog.PortalProps['container'];
    renderWhileClosed?: boolean;
    className?: string;
    fullScreen?: boolean;
    Context?: typeof ModalContext;
  }>
>(
  (
    {
      children,
      renderWhileClosed = false,
      className = '',
      fullScreen = false,
      Context = ModalContext,
      ...rest
    },
    forwardedRef,
  ) => {
    const commonProps = useTestProps(rest);

    const { setIsOpen, isOpen } = useContext(Context);

    // Prevent previous modal from closing when pressing escape
    const onEscapeKeyDown = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isOpen) setIsOpen(false);
        return false;
      },
      [isOpen],
    );

    return (
      <Dialog.Portal {...rest}>
        <Dialog.Overlay className="radix-modal__overlay" />
        <Dialog.Content
          className={`radix-side-modal ${
            fullScreen ? 'radix-side-modal--fullscreen' : ''
          } ${className}`}
          onEscapeKeyDown={onEscapeKeyDown}
          ref={forwardedRef}
          {...commonProps}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    );
  },
);

const SideModalHeader = ({ children, withCloseAction = true, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="radix-side-modal__header" {...commonProps}>
      {children}
      {withCloseAction ? (
        <Dialog.Close asChild>
          <button
            title="Close"
            className="radix-side-modal__header-close-button"
          >
            <Icon.CloseIcon />
          </button>
        </Dialog.Close>
      ) : null}
      <Separator marginTop={20} marginBottom={0} />
    </div>
  );
};

const SideModalTitle = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <Dialog.Title asChild {...commonProps}>
      <Typography.h1>{children}</Typography.h1>
    </Dialog.Title>
  );
};

const SideModalActions = ({ children, withBorder = false, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="radix-side-modal__actions" {...commonProps}>
      {children}
    </div>
  );
};
const SideModalBody = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('radix-side-modal__body', props);
  return (
    <div {...classNameProps} {...commonProps}>
      {children}
    </div>
  );
};

const SideModalError = ({ children, ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return (
    <Typography.p
      className="radix-side-modal__error"
      type="alert"
      {...commonProps}
    >
      {children}
    </Typography.p>
  );
};

export const SideModalClose = ({ children, disabled = false, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <Dialog.Close asChild disabled={disabled} {...commonProps}>
      {children}
    </Dialog.Close>
  );
};

export const SideModal = {
  Title: SideModalTitle,
  Header: SideModalHeader,
  Content: SideModalContent,
  Body: SideModalBody,
  Actions: SideModalActions,
  Error: SideModalError,
  Close: SideModalClose,
};
