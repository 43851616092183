import {
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { ReportsRouterContract } from './report.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'reportsStore';

  const tsRest = buildTsRestInstance<typeof ReportsRouterContract>(
    apiInstance,
    ReportsRouterContract,
    apiOptions,
  );

  const rtk = buildRtkInstance(tsRest, ReportsRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
