import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { LearningPath } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Icon,
  Input,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { ImageUploadInput } from '../../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { TextEditorWithFileUpload } from '../../../../_common/modules/TextEditorWithFileUpload';
import { AdminCompetencySelect } from '../../AdminCompetency/AdminCompetencySelect';
import { AdminLearnerGroupMultiSelect } from '../../AdminLearner/AdminLearnerGroupSelect';
import { AdminTagSelect } from '../../AdminTag/AdminTagSelect';
import './AdminLearningPathEditFormSideModal.scss';
import { LearningPathLearningUnitsCardSection } from './AdminLearningPathLearningUnitsSection/AdminLearningPathLearningUnitsCardSection';

enum FormStep {
  General = 'General',
  LearningUnits = 'Learning Units',
  Dates = 'Dates',
}

const Schema = yup.object().shape({
  name: yup.string().required(),
  targetLearnerGroupIds: yup.array(),
  competencyIds: yup.array(),
  tagIds: yup.array(),
  thumbnail: yup.string().nullable(),
  description: yup.string().nullable(),
  learningPathItems: yup
    .array()
    .of(
      yup.object().shape({
        mandatory: yup.boolean().required(),
        sequential: yup.boolean().required(),
        order: yup.number().required(),
        courseId: yup.string().nullable(),
        elearningCourseId: yup.string().nullable(),
        assessmentFormId: yup.string().nullable(),
        course: yup.object().nullable(),
        elearningCourse: yup.object().nullable(),
        assessmentForm: yup.object().nullable(),
      }),
    )
    .required()
    .min(1, 'At least one learning unit is required'),
  duration: yup.number().nullable(),
});

const LearningPathGeneralSection = ({
  errors,
  control,
  register,
  getValues,
}) => {
  return (
    <>
      <Box margin={{ bottom: 'm' }}>
        <Typography.h2>General</Typography.h2>
      </Box>
      <Box>
        <Box style={{ width: '50%' }}>
          <FormField label="Name" required errorMessage={errors?.name?.message}>
            <Input
              required
              {...register('name')}
              hasError={!!errors?.name?.message}
            />
          </FormField>
        </Box>
        <Box style={{ width: '50%' }}>
          <FormField label="Learner Visibility">
            <Controller
              name="targetLearnerGroupIds"
              control={control}
              render={({ field }) => (
                <AdminLearnerGroupMultiSelect
                  selectedItems={field.value}
                  {...field}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box style={{ width: '50%' }}>
          <FormField label="Competencies">
            <Controller
              name="competencyIds"
              control={control}
              render={({ field }) => (
                <AdminCompetencySelect {...field} selectedItems={field.value} />
              )}
            />
          </FormField>
        </Box>
        <Box style={{ width: '50%' }}>
          <FormField label="Tags">
            <Controller
              name="tagIds"
              control={control}
              render={({ field }) => (
                <AdminTagSelect {...field} selectedItems={field.value} />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField label="Add Cover" errorMessage={errors?.cover?.message}>
            <Controller
              name="thumbnail"
              control={control}
              render={({ field }) => (
                <ImageUploadInput
                  {...field}
                  initialS3Url={getValues('thumbnail')}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Description"
            errorMessage={errors?.description?.message}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => <TextEditorWithFileUpload {...field} />}
            />
          </FormField>
        </Box>
      </Box>
    </>
  );
};

const LearningPathDatesSection = ({ getValues, register, errors }) => {
  return (
    <>
      <Box margin={{ bottom: 'm' }}>
        <Typography.h2>Time Allocated</Typography.h2>
      </Box>
      <Box>
        <div className="admin-learning-path-create-form-side-modal__allocated-time-item">
          <div className="admin-learning-path-create-form-side-modal__allocated-time-item__label">
            Learning modules
          </div>
          <div className="admin-learning-path-create-form-side-modal__allocated-time-item__value">
            {getValues('learningPathItems')?.length}
          </div>
        </div>
        <div className="admin-learning-path-create-form-side-modal__allocated-time-item">
          <div className="admin-learning-path-create-form-side-modal__allocated-time-item__label">
            Learner groups
          </div>
          <div className="admin-learning-path-create-form-side-modal__allocated-time-item__value">
            {getValues('targetLearnerGroupIds')?.length || 0}
          </div>
        </div>
      </Box>
      <Separator />
      <Box margin={{ bottom: 'm' }}>
        <Typography.h2>Duration</Typography.h2>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Duration in hours"
            errorMessage={errors?.duration?.message}
          >
            <Input
              {...register('duration', {
                valueAsNumber: true,
              })}
              type="number"
              hasError={!!errors?.duration?.message}
            />
          </FormField>
        </Box>
      </Box>
    </>
  );
};

const CurrentSectionNavigation = ({
  formStep,
  setFormStep,
}: {
  formStep: FormStep;
  setFormStep: any;
}) => {
  switch (formStep) {
    case FormStep.General:
      return (
        <Button
          regular
          onClick={() => setFormStep(FormStep.LearningUnits)}
          label="Learning Units"
          icon={<Icon.ArrowRightIcon />}
        />
      );
    case FormStep.LearningUnits:
      return (
        <>
          <Button
            regular
            onClick={() => setFormStep(FormStep.General)}
            label="General"
            icon={<Icon.ArrowLeftIcon />}
          />
          <Button
            regular
            onClick={() => setFormStep(FormStep.Dates)}
            label="Dates"
            icon={<Icon.ArrowRightIcon />}
          />
        </>
      );
    case FormStep.Dates:
      return (
        <Button
          regular
          onClick={() => setFormStep(FormStep.LearningUnits)}
          label="Learning Units"
          icon={<Icon.ArrowLeftIcon />}
        />
      );
    default:
      return null;
  }
};

const CurrentSectionComponent = ({
  formStep,
  control,
  learningPath,
  ...props
}: {
  formStep: FormStep;
  register: any;
  errors: any;
  control: any;
  getValues: any;
  setValue: any;
  learningPath: LearningPath;
}) => {
  const { errors } = props;
  switch (formStep) {
    case FormStep.General:
      return <LearningPathGeneralSection control={control} {...props} />;
    case FormStep.LearningUnits:
      return (
        <Controller
          name="learningPathItems"
          control={control}
          render={({ field }) => {
            return (
              <LearningPathLearningUnitsCardSection
                {...field}
                errors={errors}
                learningPath={learningPath}
              />
            );
          }}
        />
      );
    case FormStep.Dates:
      return <LearningPathDatesSection {...props} />;
    default:
      return null;
  }
};

export const AdminLearningPathEditFormSideModal = ({
  learningPath = {},
}: {
  learningPath: any;
}) => {
  const [formStep, setFormStep] = useState(FormStep.General);
  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: { ...learningPath, items: learningPath.learningPathItems },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateLearningPathMutation',
    updateHookName: 'useUpdateLearningPathMutation',
    entityId: learningPath.id,
  });
  return (
    <SideModal.Content className="admin-learning-path-create-form-side-modal">
      <SideModal.Header>
        <SideModal.Title>
          {learningPath.id ? 'Update' : 'Create'} Learning Path
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <CurrentSectionComponent
          formStep={formStep}
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          setValue={setValue}
          learningPath={learningPath}
        />
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <CurrentSectionNavigation
          formStep={formStep}
          setFormStep={setFormStep}
        />
        <Button
          label={learningPath.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
