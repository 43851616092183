export const ReportsRouterPrefix = '/reports';
export const StatisticsRouterPrefix = '/statistics';
export const LearningPortalRouterPrefix = '/learning-portal';

export const ReportsScope = {
  list: { resource: 'report', action: 'list' },
  update: { resource: 'report', action: 'update' },
  delete: { resource: 'report', action: 'delete' },
  export: { resource: 'report', action: 'export' },
  download: { resource: 'report', action: 'download' },
  schedule: { resource: 'report', action: 'schedule' },
  listTemplate: { resource: 'report-template', action: 'list' },
  createTemplate: { resource: 'report-template', action: 'create' },
  updateTemplate: { resource: 'report-template', action: 'update' },
  superEditTemplate: { resource: 'report-template', action: 'superedit' },
  deleteTemplate: { resource: 'report-template', action: 'delete' },
} as const;

export const LearnerAnalyticsScope = {
  viewLearnerLearnedTime: { resource: 'learner-learned-time', action: 'view' },
  viewLearnerAttendance: { resource: 'learner-attendance', action: 'view' },
  viewLearnerStatistics: { resource: 'learner-statistics', action: 'view' },
} as const;

export const LiveLearningAnalyticsScope = {
  viewLiveCourseStatistics: {
    resource: 'live-course-statistics',
    action: 'view',
  },
  viewLiveCourseNps: { resource: 'live-course-nps', action: 'view' },
} as const;

export const LearningPortalScope = {
  exportLearnerLearningHistory: {
    resource: 'portal-learning-history',
    action: 'export',
  },
};

export const ReportDomainEventNames = {
  ReportGenerated: 'report.generated',
} as const;
