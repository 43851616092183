// generic
import React from 'react';
import * as z from 'zod';

import { DeleteResourcesBodySchema } from './schemas';

export enum QueryFilterOperatorType {
  Unary = 'Unary',
  Binary = 'Binary',
}

export interface QueryFilterOperator {
  value: QueryOperator;
  label?: string;
  type: QueryFilterOperatorType;
}

export enum QueryFilterCombinator {
  And = 'And',
  Or = 'Or',
}

export enum QueryOperator {
  Equals = 'Equals',
  NEqual = 'NEqual',
  IEquals = 'IEquals',
  INEqual = 'INEqual',
  GreaterThan = 'GreaterThan',
  Includes = 'Includes',
  Excludes = 'Excludes',
  Like = 'Like',
  GreaterThanEqual = 'GreaterThanEqual',
  LowerThan = 'LowerThan',
  LowerThanEqual = 'LowerThanEqual',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  IsTrue = 'IsTrue',
  IsFalse = 'IsFalse',
  IsNull = 'IsNull',
  IsNotNull = 'IsNotNull',
  Overlaps = 'Overlaps',
  DoesNotOverlap = 'DoesNotOverlap',
  JsonArrayIncludes = 'JsonArrayIncludes',
  JsonArrayDoesNotInclude = 'JsonArrayDoesNotInclude',
}

export const OperatorToLabelRecord: Record<QueryOperator, string> = {
  [QueryOperator.Equals]: 'is equal to',
  [QueryOperator.NEqual]: 'is not equal to',
  [QueryOperator.IEquals]: 'is iEqual to',
  [QueryOperator.INEqual]: 'is not iEqual to',
  [QueryOperator.GreaterThan]: 'is greater than',
  [QueryOperator.GreaterThanEqual]: 'is greater or equal than',
  [QueryOperator.LowerThan]: 'is lower than',
  [QueryOperator.LowerThanEqual]: 'is lower or equal than',
  [QueryOperator.Contains]: 'contains',
  [QueryOperator.DoesNotContain]: 'does not contain',
  [QueryOperator.Like]: 'like',
  [QueryOperator.IsTrue]: 'is true',
  [QueryOperator.IsFalse]: 'is false',
  [QueryOperator.IsNull]: 'is null',
  [QueryOperator.IsNotNull]: 'is not null',
  [QueryOperator.Includes]: 'includes',
  [QueryOperator.Excludes]: 'excludes',
  [QueryOperator.Overlaps]: 'includes',
  [QueryOperator.DoesNotOverlap]: 'excludes',
  [QueryOperator.JsonArrayIncludes]: 'includes',
  [QueryOperator.JsonArrayDoesNotInclude]: 'excludes',
};

export enum FieldType {
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  date = 'date',
  datetime = 'datetime',
  select = 'select',
  jsonArraySelect = 'json-array-select',
  arraySelect = 'array-select',
}

export const FieldTypeToFilterComponentType: Record<FieldType, string> = {
  [FieldType.boolean]: 'BooleanFilterComponent',
  [FieldType.number]: 'NumberFilterComponent',
  [FieldType.string]: 'StringFilterComponent',
  [FieldType.date]: 'DateFilterComponent',
  [FieldType.datetime]: 'DatetimeFilterComponent',
  [FieldType.select]: 'SelectFilterComponent',
  [FieldType.jsonArraySelect]: 'SelectFilterComponent',
  [FieldType.arraySelect]: 'SelectFilterComponent',
};

export const FieldTypeToFilterBarActiveFilterType: Record<FieldType, string> = {
  [FieldType.select]: 'SelectField',
  [FieldType.jsonArraySelect]: 'SelectField',
  [FieldType.arraySelect]: 'SelectField',
  [FieldType.number]: 'NumberField',
  [FieldType.string]: 'StringField',
  [FieldType.date]: 'DateField',
  [FieldType.datetime]: 'DatetimeField',
  [FieldType.boolean]: 'BooleanField',
};

export const FilterComponentTypeToActiveFilterType: Record<string, string> = {
  SelectFilterComponent: 'SelectField',
  DateFilterComponent: 'DateField',
  DatetimeFilterComponent: 'DatetimeField',
  StringFilterComponent: 'StringField',
  NumberFilterComponent: 'NumberField',
  BooleanFilterComponent: 'BooleanField',
};

export const FieldTypeToOperatorsRecord: Record<FieldType, QueryOperator[]> = {
  [FieldType.string]: [
    QueryOperator.Contains,
    QueryOperator.DoesNotContain,
    QueryOperator.Equals,
    QueryOperator.NEqual,
  ],
  [FieldType.number]: [
    QueryOperator.Equals,
    QueryOperator.NEqual,
    QueryOperator.GreaterThan,
    QueryOperator.GreaterThanEqual,
    QueryOperator.LowerThanEqual,
    QueryOperator.LowerThan,
  ],
  [FieldType.boolean]: [QueryOperator.IsTrue, QueryOperator.IsFalse],
  [FieldType.date]: [
    QueryOperator.GreaterThan,
    QueryOperator.GreaterThanEqual,
    QueryOperator.LowerThanEqual,
    QueryOperator.LowerThan,
  ],
  [FieldType.datetime]: [
    QueryOperator.GreaterThan,
    QueryOperator.GreaterThanEqual,
    QueryOperator.LowerThanEqual,
    QueryOperator.LowerThan,
  ],
  [FieldType.jsonArraySelect]: [
    QueryOperator.JsonArrayIncludes,
    QueryOperator.JsonArrayDoesNotInclude,
  ],
  [FieldType.select]: [QueryOperator.Includes, QueryOperator.Excludes],
  [FieldType.arraySelect]: [
    QueryOperator.Overlaps,
    QueryOperator.DoesNotOverlap,
  ],
};

export type QueryPagination =
  | {
      limit: number | string;
      offset: number | string;
      disabled?: boolean;
    }
  | {
      limit?: number | string;
      offset?: number | string;
      disabled: boolean;
    };

export type QuerySortingOrder = 'desc' | 'asc';

export type QuerySorting = [string, QuerySortingOrder][];
export type ApiQuerySorting = QuerySorting;

// web app only
export type AppQueryFilterField = {
  field: string;
  label: string;
  type?: FieldType;
};

export type AppQueryFilterValue = {
  label: string;
  value: string | number | string[] | number[] | boolean;
};

export type AppQueryFilterModel = {
  field: AppQueryFilterField;
  operator: QueryFilterOperator;
  value?: AppQueryFilterValue;
};

export const instanceOfAppQueryFilterModel = (
  object: any,
): object is AppQueryFilterModel => {
  return object.field && object.operator && object.value;
};

export type AppQueryFilter = {
  id: string;
  value?: AppQueryFilterModel | Array<AppQueryFilter>;
  combinator?: QueryFilterCombinator | null;
};

export type AppQueryPagination = QueryPagination;

export type AppQuerySorting = QuerySorting;

export interface AppQuery {
  filters?: AppQueryFilter;
  pagination?: AppQueryPagination;
  sorting?: AppQuerySorting;
  search?: string;
}

// backend only
export interface ApiQueryFilterModel {
  field: string;
  operator: string;
  value?: string | number | number[] | string[] | boolean;
}

export interface ApiQueryFilter {
  value?: ApiQueryFilterModel | Array<ApiQueryFilter>;
  combinator?: QueryFilterCombinator;
}

export type ApiQueryPagination = QueryPagination;

export interface ApiQuery {
  filters?: ApiQueryFilter;
  pagination?: ApiQueryPagination;
  sorting?: ApiQuerySorting;
  search?: string;
}

export type FieldMetadataValueOption = {
  label: string;
  value: string | number;
};

interface BaseFieldMetadata {
  name: string;
  label: string;
  ValueComponent?: React.FC<{
    children: React.ReactNode;
    filter: any;
  }>;
}

export interface StringFieldMetadata extends BaseFieldMetadata {
  type: FieldType.string;
}

export interface NumberFieldMetadata extends BaseFieldMetadata {
  type: FieldType.number;
}

export interface DateFieldMetadata extends BaseFieldMetadata {
  type: FieldType.date;
}

export interface DatetimeFieldMetadata extends BaseFieldMetadata {
  type: FieldType.datetime;
}

export interface BooleanFieldMetadata extends BaseFieldMetadata {
  type: FieldType.boolean;
}

export interface SelectFieldMetadata extends BaseFieldMetadata {
  type: FieldType.select;
  loadOptions?: (inputValue: string) => Promise<FieldMetadataValueOption[]>;
}

export interface JsonArraySelectFieldMetadata extends BaseFieldMetadata {
  type: FieldType.jsonArraySelect;
  loadOptions?: (inputValue: string) => Promise<FieldMetadataValueOption[]>;
}

export interface ArraySelectFieldMetadata extends BaseFieldMetadata {
  type: FieldType.arraySelect;
  loadOptions?: (inputValue: string) => Promise<FieldMetadataValueOption[]>;
}

export type FieldMetadata =
  | StringFieldMetadata
  | NumberFieldMetadata
  | DateFieldMetadata
  | DatetimeFieldMetadata
  | BooleanFieldMetadata
  | SelectFieldMetadata
  | JsonArraySelectFieldMetadata
  | ArraySelectFieldMetadata;

export const isGeneralSelectFieldMetadata = (
  fieldMetadata: FieldMetadata,
): fieldMetadata is
  | SelectFieldMetadata
  | JsonArraySelectFieldMetadata
  | ArraySelectFieldMetadata => {
  return [
    FieldType.select,
    FieldType.jsonArraySelect,
    FieldType.arraySelect,
  ].includes(fieldMetadata.type);
};

export type EmailTemplateSeparatorSection = {
  type: 'separator';
  name: string;
};
export type EmailTemplateBodyTextSection = {
  type: 'body_text';
  name: string;
};

export type EmailTemplateTitleSection = {
  type: 'title';
  name: string;
};

export type EmailTemplateFooterSection = {
  type: 'footer_text';
  name: string;
};

type EmailTemplateButton = {
  name: string;
  href: string;
  type: 'default' | 'confirm' | 'decline';
};

export type EmailTemplateButtonsSection = {
  type: 'buttons';
  name: string;
  content: EmailTemplateButton[];
};

export type EmailTemplateTableSection = {
  type: 'table';
  name: string;
  content: [string, string];
};

export type BaseEmailTemplateSection =
  | EmailTemplateSeparatorSection
  | EmailTemplateBodyTextSection
  | EmailTemplateFooterSection
  | EmailTemplateButtonsSection
  | EmailTemplateTableSection
  | EmailTemplateTitleSection;

export type EmailTemplateConditionSection = {
  type: 'condition';
  name: string;
  content: BaseEmailTemplateSection[];
};

export type EmailTemplateRepeaterSection = {
  type: 'repeater';
  name: string;
  content: BaseEmailTemplateSection[];
};

export type EmailTemplateLanguage = 'en' | 'ro';
export type EmailTemplateTranslations = Record<
  EmailTemplateLanguage,
  { string: string }
>;

export type EmailTemplateSection =
  | BaseEmailTemplateSection
  | EmailTemplateRepeaterSection
  | EmailTemplateConditionSection;
export type EmailTemplateSectionType = EmailTemplateSection['type'];

export interface EmailTemplate {
  id: string;
  name: string;
  label: string;
  description: string;
  sections: EmailTemplateSection[];
  translations: EmailTemplateTranslations;
  enabled: boolean;
  reply_to: string;
}

export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;
export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

type DomainEventPayloadField = {
  name: string;
  type: FieldType;
  label: string;
};

export type DomainEventName = `${string}.${string}` | '*.*';
export type DomainEventProperties = Record<
  string,
  DomainEventPayloadField | Record<string, DomainEventPayloadField>
>;
export type DomainEventPayload = Record<string, unknown>;

export type DeleteResourceParam = z.infer<typeof DeleteResourcesBodySchema>;
