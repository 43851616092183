import i18n from 'i18next';

const getError = (err: string) => {
  return i18n.t(`error`, {
    returnObjects: true,
    ns: 'common',
  })[err];
};

export const getTranslatedMessageFromError = (err) => {
  if (!err) return '';

  if (typeof err === 'string') {
    return getError(err) || err;
  }

  // TODO try and get it from locale first when it's implemented
  if (err?.message) {
    const translatedErrorMessageByMessage = getError(err.message);

    if (translatedErrorMessageByMessage) {
      return translatedErrorMessageByMessage;
    }
  }

  if (err?.name) {
    const translatedErrorMessageByName = getError(err.name);

    if (translatedErrorMessageByName) {
      return translatedErrorMessageByName;
    }
  }

  return err?.message || err?.name || 'Unknown error';
};
