import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  ContentLibraryItem,
  ContentLibraryItemType,
} from '@nl-lms/feature/content-library/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  Separator,
  SideModal,
  Textarea,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { ImageUploadInput } from '../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { contentLibraryItemsApi } from '../../../_common/services/api';
import { AdminCompetencySelect } from '../AdminCompetency/AdminCompetencySelect';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import AdminContentLibraryTypeSelect from './AdminContentLibraryItemTypeSelect';

type Props = {
  contentLibraryItem?: ContentLibraryItem | null;
};

const Schema = z.object({
  name: z.string().min(1),
  url: z.string().min(1),
  type: z.nativeEnum(ContentLibraryItemType),
  description: z.string().nullable(),
  thumbnail: z.string().nullable(),
  tagIds: z.array(z.string()).nullable(),
  competencyIds: z.array(z.string()).nullable(),
});

export const AdminContentLibraryEditFormSideModal = ({
  // @ts-ignore
  contentLibraryItem = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      description: contentLibraryItem?.description || '',
      thumbnail: contentLibraryItem?.thumbnail || '',
      competencyIds: contentLibraryItem?.competencyIds || [],
      tagIds: contentLibraryItem?.tagIds || [],
      ...contentLibraryItem,
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateContentLibraryItemMutation',
    createHookName: 'useCreateContentLibraryItemMutation',
    entityId: contentLibraryItem?.id,
    parseEntity: (payload) => ({
      ...payload,
      contentLibraryItemId: contentLibraryItem?.id,
    }),
    api: contentLibraryItemsApi,
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {contentLibraryItem?.id
            ? 'Edit Content Library Item'
            : 'Create Content Library Item'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box>
          <Box>
            <FormField
              label="Name"
              helpText="The name that we will use throughout the entire app in various forms, reports and views"
              helpTextOffset={60}
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Type"
              helpText="The type of content that will be found at the url"
              required
              errorMessage={errors?.type?.message}
            >
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <AdminContentLibraryTypeSelect
                    {...field}
                    // @ts-ignore
                    initialValue={contentLibraryItem.type}
                    hasError={!!errors?.type?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="URL"
              helpText="The URL for the content page"
              helpTextOffset={30}
              errorMessage={errors?.url?.message}
            >
              <Input
                {...register('url')}
                required
                hasError={!!errors?.url?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <FormField label="Description">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Textarea
                  name="description"
                  placeholder="Some details about this content library item"
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || ''}
                />
              )}
            />
          </FormField>
        </Box>

        <Box>
          <Box>
            <FormField
              label="Competencies"
              helpText="Essential for recommending suitable content to learners based on their competencies and/or the competencies of their learning activity items"
            >
              <Controller
                name="competencyIds"
                control={control}
                render={({ field }) => (
                  <AdminCompetencySelect
                    {...field}
                    selectedItems={field.value}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Tags">
              <Controller
                name="tagIds"
                control={control}
                render={({ field }) => (
                  <AdminTagSelect {...field} selectedItems={field.value} />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Thumbnail">
              <Controller
                name="thumbnail"
                control={control}
                render={({ field }) => (
                  // @ts-ignore
                  <ImageUploadInput
                    {...field}
                    initialS3Url={
                      (contentLibraryItem?.thumbnail ||
                        getValues('thumbnail')) as string
                    }
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <Button
          label={contentLibraryItem?.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
