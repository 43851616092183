import React, { useCallback, useEffect, useState } from 'react';

import {
  FieldType,
  FilterOperator,
  QueryFilter,
  QueryFilterCombinator,
} from '@nl-lms/common/shared';
import {
  AsyncMultiSelect,
  Button,
  FormField,
  Input,
  Modal,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  getSelectOptions,
  getTranslatedMessageFromError,
} from '@nl-lms/ui/utils';

import { adminApi, useApi } from '../../../../_common/services/api';
import { fetchAndMapTagsForType } from '../../../_common/utils/fetchEntitiesForSelectMethods';

const { useAddAssessmentItemsMutation } = adminApi;

const buildTagLabel = (tag) => {
  let label = tag.title;
  if (tag.scope) label = `${tag.scope}:${tag.title}`;

  return `${label} (${tag.count})`;
};

const buildFilterFromTagIds = (tagIds) => {
  const tagIdsFilter = tagIds.map((tagId) => {
    return {
      id: '',
      value: {
        field: { field: 'tag_ids', label: '', type: FieldType.string },
        value: { label: '', value: [tagId] },
        operator: FilterOperator.Contains,
      },
      combinator: QueryFilterCombinator.Or,
    };
  });
  tagIdsFilter[0] && delete tagIdsFilter[0].combinator;

  return {
    id: '',
    value: tagIdsFilter,
  };
};

type Props =
  | {
      onSubmit: (result: { count: number; tagIds: string[] }) => void;
    }
  | {
      assessmentId: string;
    };

export const AdminAssessmentEditFormAddCategoryModal = (props: Props) => {
  const onSubmit = 'onSubmit' in props ? props.onSubmit : null;
  const assessmentId = 'assessmentId' in props ? props.assessmentId : null;
  const api = useApi();
  const { hide } = useModal();
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [questionCount, setQuestionCount] = useState(null);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);

  const [addItems, { isLoading, error }] = useAddAssessmentItemsMutation();

  const fetchTotalQuestionsCount = useCallback(async () => {
    const { count } = (await api.assessmentQuestion.list({
      pagination: { offset: 1, limit: 1 },
      filters: buildFilterFromTagIds(selectedTagIds),
    })) as any;
    setTotalQuestionsCount(count);
  }, [selectedTagIds]);

  const onChangeQuestionTags = useCallback(
    (e) => {
      setSelectedTagIds(e);
    },
    [setSelectedTagIds],
  );

  const onChangeQuestionCount = useCallback(
    (e) => {
      const count = parseInt(e.target.value);
      if (!!count && count > 0 && count <= totalQuestionsCount) {
        // @ts-ignore
        setQuestionCount(count);
        if (errorMessage) setErrorMessage(null);
      } else {
        // @ts-ignore
        setErrorMessage('Value not allowed');
      }
    },
    [setQuestionCount, totalQuestionsCount, errorMessage],
  );

  const onSubmitClick = useCallback(async () => {
    const data = {
      // @ts-ignore
      count: questionCount as number,
      tagIds: selectedTagIds as string[],
    };
    if (assessmentId) {
      const questionFilter = new QueryFilter();
      selectedTagIds.forEach((tagId) => {
        questionFilter.add(
          {
            field: 'tag_ids',
            value: [tagId],
            operator: FilterOperator.Contains.value,
          },
          { combinator: QueryFilterCombinator.Or },
        );
      });
      const result = await addItems({
        id: assessmentId,
        items: [
          {
            type: C.I_ASSESSMENT_FORM_QUESTION_ITEM_TYPES.FILTER,
            // @ts-ignore
            questionCount,
            tagIds: selectedTagIds,
            questionFilter: questionFilter.apiQueryFilter,
          },
        ],
      });
      if ('error' in result) return;
      hide();
    } else {
      // @ts-ignore
      onSubmit(data);
      hide();
    }
  }, [assessmentId, selectedTagIds, questionCount]);

  useEffect(() => {
    fetchTotalQuestionsCount();
  }, [selectedTagIds]);

  const isSubmitDisabled =
    errorMessage || questionCount === null || selectedTagIds.length === 0;

  return (
    <Modal.Content scrollable>
      <Modal.Header>
        <Modal.Title>Add Category</Modal.Title>
      </Modal.Header>
      <FormField label="Question Tags">
        <AsyncMultiSelect
          name={'questionTags'}
          onChange={onChangeQuestionTags}
          loadOptions={() => fetchAndMapTagsForType('assessment_question')}
        />
      </FormField>
      <FormField
        label={`Question Count (out of ${totalQuestionsCount})`}
        // @ts-ignore
        errorMessage={errorMessage}
      >
        <Input
          type="number"
          name="questionCount"
          onChange={onChangeQuestionCount}
          placeholder={'Count'}
          // @ts-ignore
          hasError={errorMessage}
        />
      </FormField>
      <Modal.Actions>
        <Modal.Error>{getTranslatedMessageFromError(error)}</Modal.Error>
        <Button
          label="Submit"
          isLoading={isLoading}
          disabled={isSubmitDisabled}
          onClick={onSubmitClick}
        />
      </Modal.Actions>
    </Modal.Content>
  );
};
