import { isObject } from 'lodash';
import { useCallback } from 'react';

import { useConfirmationModal } from '@nl-lms/ui/components';
import { useNotifications } from '@nl-lms/ui/modules';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { getApiErrorMessage } from './useQueryErrorHandler';

export interface UseActionProps<T> {
  showConfirmation?: boolean;
  showNotification?: boolean;
  confirmationTitle?: string;
  confirmationMessage?: string;
  alertMessage?:
    | ((result: { error: { message: string; name: string } }) => string)
    | string;
  alertDetails?:
    | ((result: { error: { message: string; name: string } }) => string)
    | string;
  successMessage?: ((result: unknown) => string) | string;
  successDetails?: ((result: unknown) => string) | string;
}

export const useAction = <
  T extends ((...args: any) => Promise<unknown>) | ((...args: any) => unknown),
>(
  method: T,
  props: UseActionProps<T> = {},
) => {
  const {
    showConfirmation = false,
    showNotification = true,
    confirmationMessage = 'Are you sure you want to perform this action',
    confirmationTitle,
    alertMessage,
    alertDetails,
    successMessage,
    successDetails,
  } = props;

  const { addAlertNotification, addSuccessNotification } = useNotifications();
  const showConfirmationModal = useConfirmationModal({
    title: confirmationTitle,
    message: confirmationMessage,
  });

  return useCallback(
    async (...args: Parameters<T>) => {
      // show confirmation
      if (showConfirmation) {
        const confirmation = await showConfirmationModal();
        if (!confirmation.confirmed) return;
      }

      const res = await method(...args);

      // notify action result
      let success = false;
      if (!res) success = true;
      else if (typeof res === 'boolean') success = res;
      else if (Array.isArray(res)) success = true;
      // @ts-expect-error
      else if ('data' in (res || {})) success = true;
      // @ts-expect-error
      else if ('data' in (res?.body || {})) success = true;
      // @ts-expect-error
      else if (res?.success) success = true;
      // @ts-expect-error
      else if (res?.status == 200) success = true;

      if (success && showNotification) {
        let message: string | undefined;
        let details: string | undefined;

        if (successMessage) {
          message =
            typeof successMessage === 'string'
              ? successMessage
              : successMessage(res);
        }

        if (successDetails) {
          details =
            typeof successDetails === 'string'
              ? successDetails
              : successDetails(res);
        }

        if (message) {
          addSuccessNotification({ message, details });
        }
      }

      if (!success && showNotification) {
        let message: string | undefined;
        let details: string | undefined;

        if (alertMessage) {
          if (typeof alertMessage === 'string') message = alertMessage;
          // @ts-expect-error
          else message = alertMessage(res);
        } else {
          message = getTranslatedMessageFromError(
            getApiErrorMessage(res as any),
          );
        }

        if (alertDetails) {
          details =
            // @ts-expect-error
            typeof alertDetails === 'string' ? alertDetails : alertDetails(res);
        }

        if (message) addAlertNotification({ message, details });
      }

      return res as ReturnType<T>;
    },
    [method, showConfirmation, alertMessage, successMessage],
  );
};
