import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { ElearningRouterContractSdk } from './elearning.router-contract.sdk';

export const getElearningClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'elearningStore';

  const tsRest = buildTsRestInstance<typeof ElearningRouterContractSdk>(
    apiInstance,
    ElearningRouterContractSdk,
    apiOptions,
  );

  const rtk = buildRtkInstance(tsRest, ElearningRouterContractSdk, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
