import FileSaver from 'file-saver';
import React, { useCallback, useState } from 'react';

import { AssessmentQuestion } from '@nl-lms/sdk/backend';
import {
  Button,
  FileInput,
  FormField,
  Separator,
  SideModal,
  SwitchInput,
  Typography,
  useConfirmationModal,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { adminApi, useApi } from '../../../../_common/services/api';

const {
  useImportAssessmentQuestionsMutation,
  useAnalyzeAssessmentQuestionImportMutation,
  useLazyDownloadAssessmentQuestionsImportTemplateQuery,
} = adminApi;

export type AdminAssessmentQuestionImportModalReturnType = {
  success: boolean;
  payload: AssessmentQuestion[];
};
export const AdminAssessmentQuestionImportModal = () => {
  const api = useApi();
  const { hide } = useModal();
  const [csvFile, setCsvFile] = useState(null);
  const [questionType, setQuestionType] = useState<'assessment' | 'survey'>(
    'assessment',
  );
  const [importQuestions, { error: importError, isLoading: isImportLoading }] =
    useImportAssessmentQuestionsMutation();
  const [
    analyzeAssessmentQuestionImport,
    {
      error: analyzeError,
      isLoading: isAnalyzeImportLoading,
      data: analyzedQuestions,
      isSuccess: isAnalyzeCompleted,
    },
  ] = useAnalyzeAssessmentQuestionImportMutation();

  const analyzeAssessmentQuestionImportAction = useAction(
    analyzeAssessmentQuestionImport,
  );

  const [downloadTemplate, { isLoading: isDownloadTemplateLoading }] =
    useLazyDownloadAssessmentQuestionsImportTemplateQuery();
  const downloadTemplateAction = useAction(downloadTemplate, {
    successMessage: 'The import template has been downloaded successfully',
  });

  const showConfirmationModal = useConfirmationModal({
    title: 'Import Questions',
    confirmButtonType: 'default',
    message:
      'Questions that have similar titles will be created. Are you sure that you want to proceed with the import?',
  });

  const onSubmit = useCallback(async () => {
    if (
      analyzedQuestions?.existentQuestions?.length ||
      analyzedQuestions?.duplicateQuestions?.length
    ) {
      const confirmation = await showConfirmationModal();
      if (!confirmation.confirmed) return;
    }
    // @ts-ignore
    const res = await importQuestions({ file: csvFile, questionType });
    if ('data' in res) {
      hide({ success: true, payload: res.data });
    }
  }, [csvFile, questionType, analyzedQuestions]);

  const onDownload = useCallback(async () => {
    const res = await downloadTemplateAction({ questionType });
    if (!res?.data) return;

    const exportedDataBlob = new Blob([res.data], { type: 'text/csv' });
    FileSaver.saveAs(
      exportedDataBlob,
      `${questionType}-questions-import-template.csv`,
    );
  }, [downloadTemplate, questionType]);

  const onChangeFile = useCallback(
    async (e) => {
      const selectedFile = e.target.files[0];
      setCsvFile(selectedFile);
      // @ts-ignore
      await analyzeAssessmentQuestionImportAction({
        file: selectedFile,
        questionType,
      });
    },
    [questionType],
  );

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Import Questions</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Typography.p>
          The CSV file that you will use needs to follow the structure specified
          in the upload template.
        </Typography.p>
        <Separator transparent margin={10} />
        {/*// @ts-ignore */}
        <FormField label="CSV File" errorMessage={analyzeError}>
          <FileInput
            onChange={onChangeFile}
            // @ts-ignore
            value={csvFile?.name}
            isLoading={isAnalyzeImportLoading}
            accept=".csv"
            placeholder="Select a CSV File"
          />
        </FormField>
        <FormField label="Question Type">
          <SwitchInput
            onChange={(value: 'assessment' | 'survey') =>
              setQuestionType(value)
            }
            className="admin-learning-assignment-modal__switch-input"
            name="question-type-switch"
            options={[
              {
                value: 'assessment',
                label: 'Assessment',
              },
              {
                value: 'Survey',
                label: 'survey',
              },
            ]}
            initialActiveOption={questionType}
          />
        </FormField>
        <Separator marginTop={0} marginBottom={10} />
        {isAnalyzeCompleted && !analyzeError ? (
          <>
            <Typography.h3>Duplicate Questions</Typography.h3>
            <Separator marginTop={0} marginBottom={10} transparent />
            {analyzedQuestions.duplicateQuestions.map((q) => (
              <>
                <Typography.p>{q}</Typography.p>
                <Separator transparent margin={5} />
              </>
            ))}
            <Separator marginTop={5} marginBottom={10} />
            <Typography.h3>Existent Questions</Typography.h3>
            <Separator marginTop={0} marginBottom={10} transparent />
            {analyzedQuestions.existentQuestions.map((q) => (
              <>
                <Typography.p>{q}</Typography.p>
                <Separator transparent margin={5} />
              </>
            ))}
          </>
        ) : null}
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(importError)}
        </SideModal.Error>
        <Button
          regular
          label="Download Template"
          onClick={onDownload}
          isLoading={isDownloadTemplateLoading}
        />
        <Button
          disabled={!csvFile || isAnalyzeImportLoading}
          label="Import"
          onClick={onSubmit}
          isLoading={isImportLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
