import { z } from 'zod';

import {
  ApiQueryFilterSchema,
  AppQueryFiltersSchema,
} from '@nl-lms/common/shared';

export const WidgetPositionSchema = z.object({
  x: z.number(),
  y: z.number(),
  w: z.number(),
  h: z.number(),
  minH: z.number().optional(),
  minW: z.number().optional(),
  maxW: z.number().optional(),
  maxH: z.number().optional(),
});

export type WidgetPosition = z.infer<typeof WidgetPositionSchema>;

export const WidgetSchema = z.object({
  label: z.string(),
  description: z.string().optional(),
  position: WidgetPositionSchema,
  component: z.string(),
  baseFilters: AppQueryFiltersSchema.optional().nullable(),
  baseProps: z.record(z.string(), z.unknown()).default({}),
});

export type Widget = z.infer<typeof WidgetSchema>;

export const DashboardSchema = z.object({
  id: z.string(),
  label: z.string(),
  widgets: z.array(WidgetSchema),
  targetLearnerGroupIds: z.array(z.string()),
  baseFilters: ApiQueryFilterSchema.nullable(),
  userId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Dashboard = z.infer<typeof DashboardSchema>;
