import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Controller, useForm } from 'react-hook-form';

import {
  AppQuery,
  QueryFilter,
  QueryFilterCombinator,
  QueryOperator,
  QueryPagination,
} from '@nl-lms/common/shared';
import { LearningAssignment } from '@nl-lms/feature/learning-assignments/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  AsyncSingleSelect,
  Box,
  Button,
  FormField,
  Icon,
  Input,
  NoDataPlaceholder,
  ScheduleDateInput,
  Sensitive,
  StatusTag,
  Table,
  TablePagination,
  Tabs,
  ToggleInput,
  Typography,
} from '@nl-lms/ui/components';
import { useIntersectionObserver } from '@nl-lms/ui/hooks';

import { organizationApi } from '../../../_common/services/api';
import { fetchAndMapAssignments } from '../../_common/utils/fetchEntitiesForSelectMethods';
import { LearningAssignmentRulesAdvancedForm } from './LearningAssignmentRulesAdvancedForm';
import { LearningAssignmentRulesCreatePreview } from './LearningAssignmentRulesCreatePreview';
import './LearningAssignmentRulesForm.scss';
import { LearningAssignmentRulesTemplatesForm } from './LearningAssignmentRulesTemplatesForm';
import { AssignationRuleTemplateTypes as RuleTemplateTypes } from './utils/constants';
import { LearningAssignmentRulePayloadBaseSchema } from './utils/types';

const { useListLearnersQuery } = organizationApi;

type Props = {
  onChange: (payload: any) => void;
  currentRules?: any[];
  currentAssignment?: LearningAssignment | null;
  currentAssignments?: LearningAssignment[];
  showAdvanced?: boolean;
};

export const LearningAssignmentRulesListForm = ({
  onChange,
  currentRules = [],
  currentAssignments = [],
  currentAssignment = null,
  showAdvanced = false,
}: Props) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    formState: { errors },
    setError,
  } = useForm({
    resolver: zodResolver(LearningAssignmentRulePayloadBaseSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      subject: currentAssignment || {},
      template: '',
      startDate: null,
      createPlannedInstances: true,
      triggerTimes: '1',
      conditions: [],
    },
  });

  const [currentTab, setCurrentTab] = useState('basic');
  const addRuleButtonRef = useRef<HTMLButtonElement>(null);

  const name = watch('name');
  const startDate = watch('startDate');
  const createPlannedInstances = watch('createPlannedInstances');
  const conditions = watch('conditions');
  const triggerTimes = watch('triggerTimes');
  const subject = watch('subject');
  const template = watch('template');

  const isAutoAssignSelected = useMemo(
    () =>
      currentTab === 'basic' &&
      !!template?.includes(RuleTemplateTypes.autoAssign),
    [template, currentTab],
  );

  const localAddRuleButtonIntersection = useIntersectionObserver(
    addRuleButtonRef,
    {},
  );
  useEffect(() => {
    if (isAutoAssignSelected) {
      setValue('createPlannedInstances', false);
    }
  }, [isAutoAssignSelected]);

  const onAddNewRule = useCallback(() => {
    if (parseInt(triggerTimes) > 1 && !conditions?.length) {
      setError('triggerTimes', {
        message:
          'The recurrence can only be set if there are some conditions that it will trigger by',
      });
      return;
    }

    const newRule = {
      name,
      subject,
      startDate,
      conditions: [...(conditions || [])],
      settings: [...(conditions || [])]?.length
        ? { triggerNTimes: triggerTimes }
        : {},
      createPlannedInstances,
    };

    handleSubmit(
      () => onChange([...(currentRules ?? []), newRule]),
      (errors) => console.log('onChange Errors', errors),
    )();

    setValue('startDate', null);
    setValue('createPlannedInstances', true);
    setValue('triggerTimes', '1');
    setValue('conditions', []);
    setValue('template', '');
    setValue('name', '');
  }, [
    subject,
    currentRules,
    startDate,
    createPlannedInstances,
    conditions,
    triggerTimes,
    name,
  ]);
  const [pagination, setPagination] = useState<QueryPagination>({
    offset: 0,
    limit: 50,
  });
  const learnersQuery = useMemo<AppQuery>(() => {
    if (!currentAssignment || !createPlannedInstances) {
      return { pagination: { limit: 0, offset: 0 } };
    }
    const queryFilter = new QueryFilter();
    if (currentAssignment.learnerIds.length) {
      queryFilter.add({
        field: 'id',
        operator: QueryOperator.Includes,
        value: currentAssignment.learnerIds,
      });
    }
    if (currentAssignment.learnerGroupIds.length) {
      queryFilter.add(
        {
          field: 'learnerGroupId',
          operator: QueryOperator.Includes,
          value: currentAssignment.learnerGroupIds,
        },
        { combinator: QueryFilterCombinator.Or },
      );
    }
    return {
      pagination,
      filters: queryFilter.appQueryFilter,
      sorting: [['firstName', 'asc']],
    };
  }, [pagination, currentAssignment, createPlannedInstances]);

  const { data, isLoading: isFetchLearnersLoading } = useListLearnersQuery({
    query: { query: transformTsRestQuery(learnersQuery) },
  });
  const tablePagination = useMemo<TablePagination>(
    () => ({
      limit: typeof pagination.limit === 'number' ? pagination.limit : 0,
      offset: typeof pagination.offset === 'number' ? pagination.offset : 0,
      rowCount: data?.count || 0,
    }),
    [data, pagination],
  );
  const learners = data?.rows || [];
  const onDeleteRule = useCallback(
    (index) => {
      currentRules?.splice(index, 1);
      onChange(currentRules);
    },
    [currentRules],
  );

  const isAddRuleButtonDisabled = useMemo(() => {
    return (
      !name ||
      !subject ||
      _.isEmpty(subject) ||
      !conditions ||
      !conditions?.length
    );
  }, [subject, conditions, name]);

  const buttonRoot = document.getElementById('add-rule-button');

  const fetchAndMapAllAssignments = useMemo(
    () => async (inputValue) => {
      return currentAssignments?.length
        ? fetchAndMapAssignments(inputValue, {
            field: 'id',
            operator: QueryOperator.Includes,
            value: currentAssignments?.map((a) => a?.id),
          } as any)
        : fetchAndMapAssignments(inputValue);
    },
    [currentAssignments],
  );

  return (
    <Box padding={{ top: 'm' }}>
      <FormField
        label="Learning Assignment"
        description="New assignment instances will be created for the following assignment."
      >
        <Controller
          name="subject"
          control={control}
          render={({ field }) => (
            <AsyncSingleSelect
              {...field}
              // @ts-ignore
              selectedItem={
                !_.isEmpty(subject)
                  ? // @ts-ignore
                    { value: subject?.id, label: subject?.name }
                  : {}
              }
              placeholder="Select assignment"
              // TODO: add extra filters when there are assignments (program view edit rules)
              loadOptions={fetchAndMapAllAssignments}
              returnEntireItemOnChange
              disabled={!!currentAssignment}
              onChange={(item: { label: string; value: string }) =>
                field?.onChange({ id: item?.value, name: item?.label })
              }
            />
          )}
        />
      </FormField>
      {showAdvanced ? (
        <Tabs name="assignationRulesMode" onChangeTab={setCurrentTab}>
          <Tabs.Tab name="basic" label="Basic Mode">
            <LearningAssignmentRulesTemplatesForm
              control={control}
              setValue={setValue}
              errors={errors}
              register={register}
              watch={watch}
              fetchAndMapAllAssignments={fetchAndMapAllAssignments}
            />
          </Tabs.Tab>
          <Tabs.Tab name="advanced" label="Advanced Mode">
            <LearningAssignmentRulesAdvancedForm
              control={control}
              register={register}
            />
          </Tabs.Tab>
        </Tabs>
      ) : (
        <LearningAssignmentRulesTemplatesForm
          control={control}
          setValue={setValue}
          errors={errors}
          register={register}
          watch={watch}
          fetchAndMapAllAssignments={fetchAndMapAllAssignments}
        />
      )}

      {!isAutoAssignSelected ? (
        <FormField style={{ paddingBottom: 0 }}>
          <Controller
            name="createPlannedInstances"
            control={control}
            render={({ field }) => (
              <Box
                flex={{
                  flexDirection: 'column',
                }}
              >
                <Box
                  flex={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormField style={{ maxWidth: '90%' }}>
                    <FormField.Label>
                      Create planned instances for current audience
                    </FormField.Label>
                    <FormField.Description>
                      If active, planned instances will automatically be created
                      from the current audience. Otherwise, planned instances
                      will be created only for future audience joiners.
                    </FormField.Description>
                  </FormField>
                  <ToggleInput
                    {...field}
                    checked={field?.value}
                    defaultChecked={field?.value}
                  />
                </Box>
              </Box>
            )}
          />
        </FormField>
      ) : null}
      {createPlannedInstances && !!template ? (
        <FormField.Root>
          <FormField.Label>
            {tablePagination.rowCount} planned instances will be created
          </FormField.Label>
          <FormField>
            <Box padding={{ top: 's' }}>
              <Table.Provider
                data={learners}
                isLoading={isFetchLearnersLoading}
                columns={[
                  {
                    Header: 'Name',
                    accessor: 'firstName',
                    Cell: ({ row }) => (
                      <Sensitive>
                        {row.original.firstName} {row.original.lastName}
                      </Sensitive>
                    ),
                  },
                  {
                    Header: 'Email',
                    accessor: 'email',
                    Cell: ({ row }) => (
                      <Sensitive>{row.original.email}</Sensitive>
                    ),
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: () => {
                      return <StatusTag status={'planned'} />;
                    },
                  },
                ]}
                pagination={tablePagination}
                onChangePagination={setPagination}
              >
                <Table.Container>
                  <Table.Header />
                  <Table.Body>
                    <Table.Rows>
                      <Box padding={{ top: 'm', bottom: 'm' }}>
                        <NoDataPlaceholder.Container>
                          <NoDataPlaceholder.Icon
                            name="UserIcon"
                            size="large"
                            color="success"
                          />
                          <NoDataPlaceholder.Title>
                            The assignation instances that should be created or
                            scheduled will appear in this table
                          </NoDataPlaceholder.Title>
                        </NoDataPlaceholder.Container>
                      </Box>
                    </Table.Rows>
                  </Table.Body>
                </Table.Container>
                <Table.PaginationRow sticky={false} />
              </Table.Provider>
            </Box>
          </FormField>
        </FormField.Root>
      ) : null}
      <FormField.Root>
        <Box
          flex={{
            alignItems: 'center',
            justifyContent: _.isEmpty(errors) ? 'flex-end' : 'space-between',
          }}
        >
          {!_.isEmpty(errors) ? (
            <Box>
              {Object.keys(errors)?.map((err) => (
                <Typography.h4 style={{ color: 'red', fontSize: '13px' }}>
                  {err}: {errors?.[err]?.message}
                </Typography.h4>
              ))}
            </Box>
          ) : null}
          <Button
            disabled={isAddRuleButtonDisabled}
            regular
            ref={addRuleButtonRef}
            label="add rule"
            icon={<Icon.AddIcon />}
            onClick={onAddNewRule}
          />
        </Box>
      </FormField.Root>

      {!localAddRuleButtonIntersection?.isIntersecting && buttonRoot
        ? createPortal(
            <Button
              disabled={isAddRuleButtonDisabled}
              regular
              label="add rule"
              icon={<Icon.AddIcon />}
              onClick={onAddNewRule}
            />,
            buttonRoot,
          )
        : null}
      <LearningAssignmentRulesCreatePreview
        rules={currentRules}
        onDeleteRule={onDeleteRule}
      />
    </Box>
  );
};

export const AssignationRulesStartDateSelect = ({ control }) => (
  <FormField
    label="Asignation Offset"
    helpText="If the planned instance gets matched the assignment instance can be created right away or with a delay."
  >
    <Controller
      name="startDate"
      control={control}
      render={({ field }) => {
        return (
          <ScheduleDateInput value={field.value} onChange={field.onChange} />
        );
      }}
    />
  </FormField>
);

export const AssignationRulesTriggerTimesInput = ({
  control,
  register,
  template = '',
}) => {
  const fieldLabel = 'Trigger Count';
  return (
    <FormField
      label={fieldLabel}
      description={
        'This confgures the number of times the rule can be triggered for a learner.'
      }
    >
      <Controller
        name="triggerTimes"
        control={control}
        render={({ field }) => (
          // @ts-ignore
          <Input
            {...field}
            {...register('triggerTimes')}
            type="number"
            min="1"
            defaultValue={field?.value}
            value={field?.value}
          />
        )}
      />
    </FormField>
  );
};
