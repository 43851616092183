import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Responsive as GridLayout } from 'react-grid-layout';
import { useMatches } from 'react-router-dom';

import { Tab, TabProps } from '@nl-lms/ui/components';
import { useElementSize } from '@nl-lms/ui/hooks';
import { NotificationsContainer } from '@nl-lms/ui/modules';
import { _ } from '@nl-lms/vendor';

import '../../../../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../../../../node_modules/react-resizable/css/styles.css';
import './MainLayout.scss';
import { NavigationMenu } from './NavigationMenu';

export type MenuSubItem = {
  name: string;
  label: string;
  path: string;
  visible?: boolean;
};

export type MenuItem = {
  name: string;
  label: string;
  icon: React.FunctionComponent;
  path?: string;
  subItems?: MenuSubItem[];
  visible?: boolean | ((menuItem: MenuItem) => boolean);
};

export type UserMenuItem = {
  name: string;
  handler: () => any;
};

const MainLayout = ({
  children,
  className = '',
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={`nifty-app-v3 main-layout ${className}`}>
      {children}
      <div className="notifications-container">
        <NotificationsContainer />
      </div>
    </div>
  );
};

const MainLayoutMainContent = ({
  children,
  className = '',
}: React.PropsWithChildren<{ className?: string }>) => (
  <div className={`main-layout__content ${className}`}>{children}</div>
);

const MainLayoutHeader = ({ children }: React.PropsWithChildren) => {
  if (!children) {
    return (
      <div className="main-layout__header">
        <NavigationMenu.ToggleVisibilityButton />
        <div id="main-layout__header-breadcrumbs" />
      </div>
    );
  }
  return (
    <div className="main-layout__header">
      <NavigationMenu.ToggleVisibilityButton />
      <div id="main-layout__header-breadcrumbs" />
      {children}
    </div>
  );
};

const MainLayoutBreadcrumbs = ({ children }) => {
  const [breadcrumbsParentEl, setBreadcrumbsParentEl] = useState(() =>
    document.getElementById('main-layout__header-breadcrumbs'),
  );
  useEffect(() => {
    setBreadcrumbsParentEl(
      document.getElementById('main-layout__header-breadcrumbs'),
    );
  }, []);

  if (!breadcrumbsParentEl) return null;
  return createPortal(children, breadcrumbsParentEl);
};

const MainLayoutContent = ({ children, className = '' }) => (
  <div className={className}>{children}</div>
);
const MainLayoutContentHeader = ({ children, className = '' }) => (
  <div className={`main-layout__content-header ${className}`}>{children}</div>
);

const MainLayoutContentHeaderTitle = ({ children }) => (
  <div className="main-layout__content-header-title">{children}</div>
);

const MainLayoutContentHeaderActions = ({ children }) => (
  <div className="main-layout__content-header-actions">{children}</div>
);

const MainLayoutContentBody = ({
  children,
  className = '',
}: React.PropsWithChildren<{ className?: string }>) => (
  <div className={`main-layout__content-body ${className}`}>{children}</div>
);

const MainLayoutContentTab = ({ children, ...rest }: TabProps) => (
  <Tab {...rest} className="main-layout__content-tab">
    {children}
  </Tab>
);

type DataGrid = {
  x: number;
  y: number;
  w: number;
  h: number;
  minY?: number;
};

type LayoutDataGrid = DataGrid & {
  i: string;
};

const MainLayoutNavigationTabs = ({ children }) => (
  <div className="main-layout__navigation-tabs">{children}</div>
);

const MainLayoutContentGrid = ({
  children,
  layout = [],
  onChangeLayout = undefined,
  isDraggable = false,
  isResizable = false,
  className = '',
}: {
  children: React.ReactNode;
  layout?: LayoutDataGrid[];
  onChangeLayout?: (value: any) => void;
  isDraggable?: boolean;
  isResizable?: boolean;
  className?: string;
}) => {
  const gridRef = useRef();
  // @ts-ignore
  const { width } = useElementSize(gridRef, { width: 1280, height: 0 });

  return (
    // @ts-ignore
    <div ref={gridRef} className={className}>
      <GridLayout
        className="main-layout__content-grid"
        rowHeight={30}
        width={width}
        compactType="vertical"
        verticalCompact
        onLayoutChange={onChangeLayout}
        isDraggable={isDraggable}
        isResizable={isResizable}
        margin={[20, 20]}
        layout={layout}
        containerPadding={[0, 0]}
        breakpoints={{ xl: 1280, l: 601, sm: 600 }}
        cols={{ xl: 12, l: 12, sm: 1 }}
      >
        {children}
      </GridLayout>
    </div>
  );
};

type GridItemProps = {
  name: string;
  dataGrid: DataGrid;
  children: React.ReactNode;
};

const MainLayoutContentGridItem = (props: GridItemProps) => (
  <div key={props.name} data-grid={props.dataGrid}>
    {props.children}
  </div>
);

const MainLayoutSearchBar = ({ children }) => (
  <div className="main-layout__header-searchbar">{children}</div>
);

MainLayout.Content = MainLayoutContent;
MainLayout.ContentHeader = MainLayoutContentHeader;
MainLayout.ContentHeaderActions = MainLayoutContentHeaderActions;
MainLayout.ContentHeaderTitle = MainLayoutContentHeaderTitle;
MainLayout.ContentBody = MainLayoutContentBody;
MainLayout.ContentTab = MainLayoutContentTab;
MainLayout.ContentGrid = MainLayoutContentGrid;
MainLayout.ContentGridItem = MainLayoutContentGridItem;
MainLayout.SearchBar = MainLayoutSearchBar;
MainLayout.NavigationTabs = MainLayoutNavigationTabs;
MainLayout.Breadcrumbs = MainLayoutBreadcrumbs;
MainLayout.Header = MainLayoutHeader;
MainLayout.MainContent = MainLayoutMainContent;

export default MainLayout;
