import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  AppQuery,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { LiveSession } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  DateTime,
  FormField,
  Icon,
  Input,
  Modal,
  NoDataPlaceholder,
  Select,
  Sensitive,
  Separator,
  SideModal,
  StatusTag,
  Table,
  Tooltip,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { getSessionLearnerStatusOptions } from '@nl-lms/ui/utils';
import { _, dateFns } from '@nl-lms/vendor';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { Can } from '../../../../Can';
import { useAction } from '../../../../_common/hooks/useAction';
import { adminApi } from '../../../../_common/services/api';
import { getCleanDate } from '../../../../_common/utils';
import { AdminDailyScheduleTemplateInput } from '../../../_common/AdminDailyScheduleTemplateInput/AdminDailyScheduleTemplateInput';
import { LocationSelect } from '../../../_common/LocationSelect';
import AdminLiveCourseLearningTypeSelect from '../../AdminLiveCourse/AdminLiveCourseLearningTypeSelect';
import { AdminLiveSessionLearnerSelect } from '../AdminLiveSessionLearner/AdminLiveSessionLearnerAddModal';
import { AdminLiveSessionTrainersSelect } from '../AdminLiveSessionTrainer/AdminLiveSessionTrainerSelect';
import { AdminLiveSessionVenueSelect } from '../AdminLiveSessionVenue/AdminLiveSessionVenueUpsert/AdminLiveSessionVenueSelect';
import { isVirtualSession } from '../adminLiveSessionUtils';
import './AdminLiveSessionCloneModal.scss';

const {
  useCreateLiveSessionMutation,
  useGetLiveSessionQuery,
  useListAutomatedActionsQuery,
  useListLiveSessionLearnersQuery,
} = adminApi;

export const AdminLiveSessionCloneButton = ({ liveSessionId }) => {
  return (
    <Modal.Provider>
      <Can do="create" on="live_session">
        <Modal.Trigger>
          <Tooltip.top title="Clone" offsetToChildren={20}>
            <Icon.CopyIcon />
          </Tooltip.top>
        </Modal.Trigger>
      </Can>
      <SideModal.Content>
        <AdminLiveSessionCloneModalForm liveSessionId={liveSessionId} />
      </SideModal.Content>
    </Modal.Provider>
  );
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  startDate: yup.string().nullable(),
  learners: yup.array().required(),
  learnerStatus: yup
    .number()
    .oneOf(Object.values(C.I_LEARNER_STATUS))
    .required(),
  type: yup.number().oneOf(Object.values(C.I_LEARNING_TYPES)).required(),
  location: yup.object().nullable(),
  virtualLocation: yup.string().nullable(),
  venueId: yup.string(),
  trainerIds: yup.array(yup.string()),
  anonymizeFeedbackResponses: yup.boolean(),
  scheduleTemplate: yup
    .array(
      yup.object({
        startDate: yup.date().nullable(),
        endDate: yup.date().when('startDate', ([startDate], schema) => {
          if (!startDate) return schema.nullable();

          const maxEndDate = dateFns.endOfDay(startDate);
          return schema
            .min(startDate)
            .max(
              maxEndDate,
              `End date must not be greater than ${maxEndDate.toLocaleString()}`,
            )
            .nullable();
        }),
        schedule: yup
          .array(
            yup.object({
              type: yup
                .number()
                .oneOf([
                  C.I_SCHEDULE_BLOCK.CONTENT_DELIVERY,
                  C.I_SCHEDULE_BLOCK.BREAK,
                ])
                .required(),
              duration: yup
                .number()
                .max(60 * 24)
                .required(),
            }),
          )
          .min(1),
      }),
    )
    .required()
    .min(1),
});

export const AdminLiveSessionCloneModalForm = ({ liveSessionId }) => {
  const { hide } = useModal();

  const { data: liveSession, error } = useGetLiveSessionQuery(liveSessionId);
  useQueryErrorHandler({ error });

  const fetchArg = useMemo(() => {
    const query: AppQuery = { pagination: { disabled: true } };
    return {
      // @ts-ignore
      sessionId: liveSession.id,
      query,
    };
  }, [liveSessionId]);
  const { data: automatedActions, error: automatedActionsError } =
    useListAutomatedActionsQuery(fetchArg);
  useQueryErrorHandler({ error: automatedActionsError });

  const {
    data: initialLearners,
    isLoading: isLearnersLoading,
    error: listError,
  } = useListLiveSessionLearnersQuery({
    filters: {
      id: '',
      value: [
        {
          id: '',
          value: {
            field: { field: 'trainingSessionId', label: 'trainingSessionId' },
            operator: {
              value: QueryOperator.Equals,
              type: QueryFilterOperatorType.Binary,
            },
            value: { value: liveSessionId, label: liveSessionId },
          },
        },
      ],
    },
    pagination: { disabled: true },
  });
  useQueryErrorHandler({ error: listError });

  const enabledActions = useMemo(
    () =>
      automatedActions
        ?.filter((action) => !action.disabled)
        ?.map((a) => a.name),
    [automatedActions],
  );

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ..._.omit(liveSession, ['id', 'startDate']),
      startDate: null,
      anonymizeFeedbackResponses: false,
      // @ts-ignore
      virtualLocation: !liveSession.id ? 'TBA' : liveSession.virtualLocation,
      // @ts-ignore
      scheduleTemplate: (liveSession.scheduleTemplate || []).map((v) => {
        const startDate = getCleanDate(v.startDate);
        const endDate = getCleanDate(v.endDate);

        return { schedule: v.schedule, startDate, endDate };
      }),
      // @ts-ignore
      location: liveSession.city
        ? {
            // @ts-ignore
            city: liveSession.city,
            // @ts-ignore
            region: liveSession.region,
            // @ts-ignore
            country: liveSession.country,
          }
        : null,
      // @ts-ignore
      trainerIds: liveSession.trainers.map((t) => t.resourceTrainer.id),
      // @ts-ignore
      venueId: liveSession.venue?.resourceVenue?.id,
      // @ts-ignore
      learners: (initialLearners?.rows || []).map((l) => ({
        ...l,
        ...l.learner,
        id: l.learnerId,
      })),
      learnerStatus: C.I_LEARNER_STATUS.NOT_INVITED,
    },
  });

  useEffect(() => {
    setValue(
      'learners',
      (initialLearners?.rows || []).map((l) => ({
        ...l,
        ...l.learner,
        id: l.learnerId,
      })),
    );
  }, [initialLearners]);

  const [createLiveSession, { isLoading, error: createError }] =
    useCreateLiveSessionMutation();
  const createLiveSessionAction = useAction(createLiveSession, {
    successMessage: 'The live session has been successfully cloned',
  });

  const onSubmit = useCallback(
    async (entity) => {
      const parsedTemplate = entity.scheduleTemplate.map((s) => ({
        ...s,
        startDate:
          s.startDate instanceof Date ? s.startDate.toISOString() : null,
        endDate: s.endDate instanceof Date ? s.endDate.toISOString() : null,
      }));

      const location = isVirtualSession(type) ? null : entity.location;
      const virtualLocation = isVirtualSession(type)
        ? entity.virtualLocation || 'TBA'
        : null;
      const result = await createLiveSessionAction({
        ..._.omit(liveSession, ['id', 'status', 'course', 'venue']),
        ...entity,
        location,
        venueId: isVirtualSession(type) ? null : entity.venueId,
        virtualLocation,
        learners: {
          ids: entity.learners.map((l) => l.id),
          status: entity.learnerStatus,
        },
        enabledActions,
        scheduleTemplate: parsedTemplate,
      });

      if (result?.data) hide();

      return result;
    },
    [liveSession, enabledActions],
  );

  const statusOptions = useMemo(() => getSessionLearnerStatusOptions(), []);
  const learners = watch('learners');
  const type = watch('type');
  const learnerStatus = watch('learnerStatus') as number;
  const onRemoveLearner = useCallback(
    (row) => {
      setValue(
        'learners',
        learners.filter((l) => l.id !== row.id),
      );
    },
    [learners],
  );

  const location = watch('location');

  return (
    <>
      <SideModal.Header>
        <SideModal.Title>Clone a Live Session</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Typography.p>
          Create a new session using the current one as a template. The new
          training session will inherit properties from the current one. The
          learners list below has been automatically filled with the current
          waiting list learners.
        </Typography.p>
        <Box margin={{ top: 'm', bottom: 'm' }}>
          <Typography.h2>Session Details</Typography.h2>
        </Box>
        <Box flex={{ flexDirection: 'row', gap: 's' }}>
          <FormField label="Name" required errorMessage={errors?.name?.message}>
            <Input
              {...register('name')}
              required
              hasError={!!errors?.name?.message}
            />
          </FormField>
          <FormField
            label="Start Date"
            errorMessage={errors?.startDate?.message as string | undefined}
          >
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DateTime
                  date={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </FormField>
        </Box>
        <FormField label="Trainers">
          <Controller
            name="trainerIds"
            control={control}
            render={({ field }) => (
              <AdminLiveSessionTrainersSelect
                {...field}
                // @ts-ignore
                initialSelectedItems={liveSession.trainers.map((t) => ({
                  value: t.resourceTrainer.id,
                  label: `${t.resourceTrainer.firstName} ${t.resourceTrainer.lastName}`,
                }))}
                {...location}
                // @ts-ignore
                trainingSessionId={liveSession.id}
                // @ts-ignore
                courseId={liveSession.courseId}
              />
            )}
          />
        </FormField>
        <FormField
          label="Schedule"
          required
          errorMessage={errors?.scheduleTemplate ? 'Some errors found' : null}
        >
          <Controller
            name="scheduleTemplate"
            control={control}
            render={({ field }) => {
              return (
                <AdminDailyScheduleTemplateInput
                  {...field}
                  errors={errors?.scheduleTemplate as any}
                  // @ts-ignore
                  startDate={getCleanDate(watch('startDate'))}
                  withDates
                />
              );
            }}
          />
        </FormField>
        <FormField>
          <Checkbox
            {...register('anonymizeFeedbackResponses')}
            label="Anonymize Feedback Responses"
          />
        </FormField>
        <Separator margin={0} />
        <Box margin={{ bottom: 'm', top: 'm' }}>
          <Typography.h2>Location</Typography.h2>
        </Box>
        <Box flex={{ flexDirection: 'row', gap: 's' }}>
          <FormField label="Type" required errorMessage={errors?.type?.message}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <AdminLiveCourseLearningTypeSelect
                  {...field}
                  // @ts-ignore
                  initialValue={field.value}
                  hasError={!!errors?.type?.message}
                />
              )}
            />
          </FormField>
          {isVirtualSession(type) ? (
            <FormField label="Virtual Location">
              <Input {...register('virtualLocation')} />
            </FormField>
          ) : (
            <FormField label="City">
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <LocationSelect
                    {...field}
                    // @ts-ignore
                    initialLocation={getInitialLocation(liveSession)}
                  />
                )}
              />
            </FormField>
          )}
        </Box>
        {isVirtualSession(type) ? null : (
          <FormField label="Venue">
            <Controller
              name="venueId"
              control={control}
              render={({ field }) => (
                <AdminLiveSessionVenueSelect
                  {...field}
                  {...location}
                  // @ts-ignore
                  trainingSessionId={liveSession.id}
                  // @ts-ignore
                  maxPax={liveSession.maxPax}
                  // @ts-ignore
                  requirements={liveSession.requirements}
                  initialSelectedItem={{
                    // @ts-ignore
                    value: liveSession.venue?.resourceVenue?.id,
                    // @ts-ignore
                    label: liveSession.venue?.resourceVenue?.name,
                  }}
                />
              )}
            />
          </FormField>
        )}
        <Separator margin={0} />
        <Box margin={{ bottom: 'm', top: 'm' }}>
          <Typography.h2>Attendants</Typography.h2>
        </Box>
        <Box flex={{ flexDirection: 'row', gap: 's' }}>
          <FormField label="Learners">
            <Controller
              name="learners"
              control={control}
              render={({ field }) => (
                <AdminLiveSessionLearnerSelect
                  {...field}
                  selectedLearners={field.value}
                  liveSessionId={liveSessionId}
                  onChange={(learners) => field.onChange(learners)}
                />
              )}
            />
          </FormField>
          <FormField label="Learner Status">
            <Controller
              name="learnerStatus"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={statusOptions}
                  // @ts-ignore
                  onChange={(option) => field.onChange(option.value)}
                  initialValue={C.I_LEARNER_STATUS.NOT_INVITED}
                />
              )}
            />
          </FormField>
        </Box>
        <Table
          data={learners}
          isLoading={isLearnersLoading}
          columns={[
            {
              Header: 'Name',
              accessor: 'learner.firstName',
              Cell: ({ row }) => (
                <Sensitive>
                  {row.original.firstName} {row.original.lastName}
                </Sensitive>
              ),
            },
            {
              Header: 'Email',
              accessor: 'learner.email',
              Cell: ({ row }) => <Sensitive>{row.original.email}</Sensitive>,
            },
            {
              Header: 'Current Status',
              accessor: 'status',
              Cell: ({ row }) =>
                row.original.status ? (
                  <StatusTag status={C.LEARNER_STATUS[row.original.status]} />
                ) : (
                  <StatusTag status="none" />
                ),
            },
            {
              Header: 'New Status',
              accessor: 'id',
              Cell: () => (
                <StatusTag status={C.LEARNER_STATUS[learnerStatus]} />
              ),
            },
          ]}
          rowActions={[
            {
              name: 'Remove',
              handler: onRemoveLearner,
              Icon: Icon.DeleteIcon,
            },
          ]}
        >
          <NoDataPlaceholder.Container>
            <NoDataPlaceholder.Icon
              name="UserIcon"
              size="small"
              color="default"
            />
            <NoDataPlaceholder.Title>
              No learners added yet
            </NoDataPlaceholder.Title>
            <NoDataPlaceholder.Subtitle>
              You can add learners by using the main select input
            </NoDataPlaceholder.Subtitle>
          </NoDataPlaceholder.Container>
        </Table>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(createError)}
        </SideModal.Error>
        <Button
          label="Submit"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </>
  );
};

const getInitialLocation = (liveSession: Partial<LiveSession>) => {
  if (liveSession.city) {
    return {
      country: liveSession.country,
      city: liveSession.city,
      region: liveSession.region,
    };
  }
};
