import { _ } from '@nl-lms/vendor';

const getPartial = (currentRouteObjPath) => {
  // arguments need to be in the nested paths calling order
  return (startFromRouteObjPath = '', ...args) => {
    const currentRouteRouteObj = _.get(routes, currentRouteObjPath);
    const startFromRouteObj = _.get(routes, startFromRouteObjPath);

    const currentRouteObjPathParts = currentRouteObjPath.split(
      startFromRouteObjPath + '.',
    );
    if (!currentRouteObjPathParts.length)
      throw new Error("Can't get partial path");

    // diff object
    const remainingRouteObjPath = currentRouteObjPathParts[1];

    if (!remainingRouteObjPath) {
      return currentRouteRouteObj.path.relative.apply(null, args);
    }

    const remainingRouteObjPathKeys = remainingRouteObjPath.split('.');

    let partialPathPaths = [];
    let argIndex = 0;
    let remainingRouteObj = startFromRouteObj;

    // add extra value to handle last step
    [...remainingRouteObjPathKeys, ''].forEach((routeObjKey, index) => {
      partialPathPaths.push(
        // call only with the correct arguments
        // @ts-ignore
        remainingRouteObj.path.relative.apply(
          null,
          args.slice(argIndex, remainingRouteObj.path.relative.length),
        ),
      );

      argIndex += remainingRouteObj.path.relative.length;
      remainingRouteObj = remainingRouteObj[routeObjKey];
    });

    return partialPathPaths.join('/');
  };
};

export const routes = {
  path: {
    full: () => '',
    relative: () => '',
    partial: (startFrom) => '',
  },
  auth: {
    path: {
      relative: () => 'auth',
      full: () => [routes.path.full(), routes.auth.path.relative()].join('/'),
      partial: getPartial('auth'),
    },
    login: {
      path: {
        relative: () => 'login',
        full: () =>
          [routes.auth.path.full(), routes.auth.login.path.relative()].join(
            '/',
          ),
        partial: getPartial('auth.login'),
      },
      basic: {
        path: {
          relative: () => 'basic',
          full: () =>
            [
              routes.auth.login.path.full(),
              routes.auth.login.basic.path.relative(),
            ].join('/'),
          partial: getPartial('auth.login.basic'),
        },
      },
    },
    register: {
      path: {
        relative: () => 'register',
        full: () =>
          [routes.auth.path.full(), routes.auth.register.path.relative()].join(
            '/',
          ),
        partial: getPartial('auth.register'),
      },
      confirm: {
        path: {
          relative: () => 'confirm',
          full: () =>
            [
              routes.auth.register.path.full(),
              routes.auth.register.confirm.path.relative(),
            ].join('/'),
          partial: getPartial('auth.register.confirm'),
        },
      },
    },
    password: {
      path: {
        relative: () => 'password',
        full: () =>
          [routes.auth.path.full(), routes.auth.password.path.relative()].join(
            '/',
          ),
        partial: getPartial('auth.password'),
      },
      change: {
        path: {
          relative: () => 'change',
          full: () =>
            [
              routes.auth.password.path.full(),
              routes.auth.password.change.path.relative(),
            ].join('/'),
          partial: getPartial('auth.password.change'),
        },
      },
      forgot: {
        path: {
          relative: () => 'forgot',
          full: () =>
            [
              routes.auth.password.path.full(),
              routes.auth.password.forgot.path.relative(),
            ].join('/'),
          partial: getPartial('auth.password.forgot'),
        },
      },
    },
    external: {
      path: {
        relative: () => 'external',
        full: () =>
          [routes.auth.path.full(), routes.auth.external.path.relative()].join(
            '/',
          ),
        partial: getPartial('auth.external'),
      },
    },
  },
  admin: {
    path: {
      relative: () => 'admin',
      full: () => [routes.path.full(), routes.admin.path.relative()].join('/'),
      partial: getPartial('admin'),
    },
    dashboard: {
      path: {
        relative: () => '',
        full: () =>
          [
            routes.admin.path.full(),
            routes.admin.dashboard.path.relative(),
          ].join('/'),
        partial: getPartial('admin.dashboard'),
      },
    },
    analytics: {
      path: {
        relative: () => 'analytics',
        full: () =>
          [
            routes.admin.path.full(),
            routes.admin.analytics.path.relative(),
          ].join('/'),
        partial: getPartial('admin.analytics'),
      },
      reports: {
        path: {
          relative: () => 'reports',
          full: () =>
            [
              routes.admin.analytics.path.full(),
              routes.admin.analytics.reports.path.relative(),
            ].join('/'),
          partial: getPartial('admin.analytics.reports'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id) =>
              [
                routes.admin.analytics.reports.path.full(),
                routes.admin.analytics.reports.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.analytics.reports.item'),
          },
        },
      },
      // charts: {
      //   path: {
      //     relative: () => 'charts',
      //     full: () =>
      //       [
      //         routes.admin.analytics.path.full(),
      //         routes.admin.analytics.charts.path.relative(),
      //       ].join('/'),
      //     partial: getPartial('admin.analytics.charts'),
      //   },
      // },
      reportTemplates: {
        path: {
          relative: () => 'report-templates',
          full: () =>
            [
              routes.admin.analytics.path.full(),
              routes.admin.analytics.reportTemplates.path.relative(),
            ].join('/'),
          partial: getPartial('admin.analytics.reportTemplates'),
        },
      },
      liveLearning: {
        path: {
          relative: () => 'live-learning',
          full: () =>
            [
              routes.admin.analytics.path.full(),
              routes.admin.analytics.liveLearning.path.relative(),
            ].join('/'),
          partial: getPartial('admin.analytics.liveLearning'),
        },
      },
      learners: {
        path: {
          relative: () => 'learners',
          full: () =>
            [
              routes.admin.analytics.path.full(),
              routes.admin.analytics.learners.path.relative(),
            ].join('/'),
          partial: getPartial('admin.analytics.learners'),
        },
      },
      planning: {
        path: {
          relative: () => 'planning',
          full: () =>
            [
              routes.admin.analytics.path.full(),
              routes.admin.analytics.planning.path.relative(),
            ].join('/'),
          partial: getPartial('admin.analytics.planning'),
        },
      },
      trainers: {
        path: {
          relative: () => 'trainers',
          full: () =>
            [
              routes.admin.analytics.path.full(),
              routes.admin.analytics.trainers.path.relative(),
            ].join('/'),
          partial: getPartial('admin.analytics.trainers'),
        },
      },
      emails: {
        path: {
          relative: () => 'emails',
          full: () =>
            [
              routes.admin.analytics.path.full(),
              routes.admin.analytics.emails.path.relative(),
            ].join('/'),
          partial: getPartial('admin.analytics.emails'),
        },
      },
      notifications: {
        path: {
          relative: () => 'notifications',
          full: () =>
            [
              routes.admin.analytics.path.full(),
              routes.admin.analytics.notifications.path.relative(),
            ].join('/'),
          partial: getPartial('admin.analytics.notifications'),
        },
      },
    },
    manage: {
      path: {
        relative: () => 'manage',
        full: () =>
          [routes.admin.path.full(), routes.admin.manage.path.relative()].join(
            '/',
          ),
        partial: getPartial('admin.manage'),
      },
      liveSessions: {
        path: {
          relative: () => 'live-sessions',
          full: () =>
            [
              routes.admin.manage.path.full(),
              routes.admin.manage.liveSessions.path.relative(),
            ].join('/'),
          partial: getPartial('admin.manage.liveSessions'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id) =>
              [
                routes.admin.manage.liveSessions.path.full(),
                routes.admin.manage.liveSessions.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.manage.liveSessions.item'),
          },
        },
      },
      assignments: {
        path: {
          relative: () => 'assignments',
          full: () =>
            [
              routes.admin.manage.path.full(),
              routes.admin.manage.assignments.path.relative(),
            ].join('/'),
          partial: getPartial('admin.manage.assignments'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id) =>
              [
                routes.admin.manage.assignments.path.full(),
                routes.admin.manage.assignments.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.manage.assignments.item'),
          },
        },
      },
      programs: {
        path: {
          relative: () => 'programs',
          full: () =>
            [
              routes.admin.manage.path.full(),
              routes.admin.manage.programs.path.relative(),
            ].join('/'),
          partial: getPartial('admin.manage.programs'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id) =>
              [
                routes.admin.manage.programs.path.full(),
                routes.admin.manage.programs.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.manage.programs.item'),
          },
        },
      },
      assignmentRules: {
        path: {
          relative: () => 'assignment-rules',
          full: () =>
            [
              routes.admin.manage.path.full(),
              routes.admin.manage.assignmentRules.path.relative(),
            ].join('/'),
          partial: getPartial('admin.manage.assignmentRules'),
        },
      },
    },
    activity: {
      path: {
        relative: () => 'activity',
        full: () =>
          [
            routes.admin.path.full(),
            routes.admin.activity.path.relative(),
          ].join('/'),
        partial: getPartial('admin.activity'),
      },
      liveSessionLearners: {
        path: {
          relative: () => 'live-session-learners',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.liveSessionLearners.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.liveSessionLearners'),
        },
      },
      elearningSessions: {
        path: {
          relative: () => 'elearning-sessions',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.elearningSessions.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.elearningSessions'),
        },
      },
      assessments: {
        path: {
          relative: () => 'assessments',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.assessments.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.assessments'),
        },
      },
      surveys: {
        path: {
          relative: () => 'surveys',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.surveys.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.surveys'),
        },
      },
      assignmentInstances: {
        path: {
          relative: () => 'assignment-instances',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.assignmentInstances.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.assignmentInstances'),
        },
      },
      individual: {
        path: {
          relative: () => 'individual',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.individual.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.individual'),
        },
      },
      checklists: {
        path: {
          relative: () => 'checklists',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.checklists.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.checklists'),
        },
      },
      subscriptions: {
        path: {
          relative: () => 'subscriptions',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.subscriptions.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.subscriptions'),
        },
      },
      registrations: {
        path: {
          relative: () => 'registrations',
          full: () =>
            [
              routes.admin.activity.path.full(),
              routes.admin.activity.registrations.path.relative(),
            ].join('/'),
          partial: getPartial('admin.activity.registrations'),
        },
      },
    },
    catalogue: {
      path: {
        relative: () => 'learning-catalogue',
        full: () =>
          [
            routes.admin.path.full(),
            routes.admin.catalogue.path.relative(),
          ].join('/'),
        partial: getPartial('admin.catalogue'),
      },
      assessments: {
        path: {
          relative: () => 'assessments',
          full: () =>
            [
              routes.admin.catalogue.path.full(),
              routes.admin.catalogue.assessments.path.relative(),
            ].join('/'),
          partial: getPartial('admin.catalogue.assessments'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id?) =>
              [
                routes.admin.catalogue.assessments.path.full(),
                routes.admin.catalogue.assessments.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.catalogue.assessments.item'),
          },
        },
      },
      liveCourses: {
        path: {
          relative: () => 'live-courses',
          full: () =>
            [
              routes.admin.catalogue.path.full(),
              routes.admin.catalogue.liveCourses.path.relative(),
            ].join('/'),
          partial: getPartial('admin.catalogue.liveCourses'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id?) =>
              [
                routes.admin.catalogue.liveCourses.path.full(),
                routes.admin.catalogue.liveCourses.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.catalogue.liveCourses.item'),
          },
        },
      },
      elearningCourses: {
        path: {
          relative: () => 'elearning-courses',
          full: () =>
            [
              routes.admin.catalogue.path.full(),
              routes.admin.catalogue.elearningCourses.path.relative(),
            ].join('/'),
          partial: getPartial('admin.catalogue.elearningCourses'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id?) =>
              [
                routes.admin.catalogue.elearningCourses.path.full(),
                routes.admin.catalogue.elearningCourses.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.catalogue.elearningCourses.item'),
          },
        },
      },
      learningPaths: {
        path: {
          relative: () => 'learning-paths',
          full: () =>
            [
              routes.admin.catalogue.path.full(),
              routes.admin.catalogue.learningPaths.path.relative(),
            ].join('/'),
          partial: getPartial('admin.catalogue.learningPaths'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id?) =>
              [
                routes.admin.catalogue.learningPaths.path.full(),
                routes.admin.catalogue.learningPaths.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.catalogue.learningPaths.item'),
          },
        },
      },
      surveys: {
        path: {
          relative: () => 'surveys',
          full: () =>
            [
              routes.admin.catalogue.path.full(),
              routes.admin.catalogue.surveys.path.relative(),
            ].join('/'),
          partial: getPartial('admin.catalogue.surveys'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id?) =>
              [
                routes.admin.catalogue.surveys.path.full(),
                routes.admin.catalogue.surveys.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.catalogue.surveys.item'),
          },
        },
      },
      individual: {
        path: {
          relative: () => 'individual',
          full: () =>
            [
              routes.admin.catalogue.path.full(),
              routes.admin.catalogue.individual.path.relative(),
            ].join('/'),
          partial: getPartial('admin.catalogue.individual'),
        },
      },
      checklists: {
        path: {
          relative: () => 'checklists',
          full: () =>
            [
              routes.admin.catalogue.path.full(),
              routes.admin.catalogue.checklists.path.relative(),
            ].join('/'),
          partial: getPartial('admin.catalogue.checklists'),
        },
        item: {
          path: {
            relative: (id) => id || ':id',
            full: (id?) =>
              [
                routes.admin.catalogue.checklists.path.full(),
                routes.admin.catalogue.checklists.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.catalogue.checklists.item'),
          },
        },
      },
      contentLibraryItems: {
        path: {
          relative: () => 'content-library-items',
          full: () =>
            [
              routes.admin.catalogue.path.full(),
              routes.admin.catalogue.contentLibraryItems.path.relative(),
            ].join('/'),
          partial: getPartial('admin.catalogue.contentLibraryItems'),
        },
      },
    },
    learners: {
      path: {
        relative: () => 'learners',
        full: () =>
          [
            routes.admin.path.full(),
            routes.admin.learners.path.relative(),
          ].join('/'),
        partial: getPartial('admin.learners'),
      },
      list: {
        path: {
          relative: () => 'list',
          full: () =>
            [
              routes.admin.learners.path.full(),
              routes.admin.learners.list.path.relative(),
            ].join('/'),
          partial: getPartial('admin.learners.list'),
        },
      },
      groups: {
        path: {
          relative: () => 'groups',
          full: () =>
            [
              routes.admin.learners.path.full(),
              routes.admin.learners.groups.path.relative(),
            ].join('/'),
          partial: getPartial('admin.learners.groups'),
        },
      },
      item: {
        path: {
          relative: (id?) => (id ? `learner/${id}` : 'learner/:id'),
          full: (id?) =>
            [
              routes.admin.learners.path.full(),
              routes.admin.learners.item.path.relative(id),
            ].join('/'),
          partial: getPartial('admin.learners.item'),
        },
      },
    },

    resources: {
      path: {
        relative: () => 'resources',
        full: () =>
          [
            routes.admin.path.full(),
            routes.admin.resources.path.relative(),
          ].join('/'),
        partial: getPartial('admin.resources'),
      },

      trainers: {
        path: {
          relative: () => 'trainers',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.trainers.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.trainers'),
        },
        item: {
          path: {
            relative: (id?) => id || ':id',
            full: (id?) =>
              [
                routes.admin.resources.trainers.path.full(),
                routes.admin.resources.trainers.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.resources.trainers.item'),
          },
        },
      },
      venues: {
        path: {
          relative: () => 'venues',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.venues.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.venues'),
        },
      },
      vendors: {
        path: {
          relative: () => 'vendors',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.vendors.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.vendors'),
        },
      },
      subscriptions: {
        path: {
          relative: () => 'subscriptions',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.subscriptions.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.subscriptions'),
        },
        item: {
          path: {
            relative: (id?) => id || ':id',
            full: (id?) =>
              [
                routes.admin.resources.subscriptions.path.full(),
                routes.admin.resources.subscriptions.item.path.relative(id),
              ].join('/'),
            partial: getPartial('admin.resources.subscriptions.item'),
          },
        },
      },
      costTypes: {
        path: {
          relative: () => 'cost-types',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.costTypes.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.costTypes'),
        },
      },
      tags: {
        path: {
          relative: () => 'tags',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.tags.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.tags'),
        },
      },
      certifications: {
        path: {
          relative: () => 'certifications',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.certifications.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.certifcations'),
        },
      },
      competencies: {
        path: {
          relative: () => 'competencies',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.competencies.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.competencies'),
        },
      },
      contentFiles: {
        path: {
          relative: () => 'content-files',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.contentFiles.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.contentFiles'),
        },
      },
      questions: {
        path: {
          relative: () => 'questions',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.questions.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.questions'),
        },
      },
      checklistItems: {
        path: {
          relative: () => 'checklist-items',
          full: () =>
            [
              routes.admin.resources.path.full(),
              routes.admin.resources.checklistItems.path.relative(),
            ].join('/'),
          partial: getPartial('admin.resources.checklistItems'),
        },
      },
    },

    configure: {
      path: {
        relative: () => 'configure',
        full: () =>
          [
            routes.admin.path.full(),
            routes.admin.configure.path.relative(),
          ].join('/'),
        partial: getPartial('admin.configure'),
      },
      emailTemplates: {
        path: {
          relative: () => 'email-templates',
          full: () =>
            [
              routes.admin.configure.path.full(),
              routes.admin.configure.emailTemplates.path.relative(),
            ].join('/'),
          partial: getPartial('admin.configure.emailTemplates'),
        },
      },
    },
  },
  portal: {
    path: {
      relative: () => 'portal',
      full: () => [routes.path.full(), routes.portal.path.relative()].join('/'),
      partial: getPartial('portal'),
    },
    dashboard: {
      path: {
        relative: () => '',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.dashboard.path.relative(),
          ].join('/'),
        partial: getPartial('portal.dashboard'),
      },
    },
    profile: {
      path: {
        relative: () => 'profile',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.profile.path.relative(),
          ].join('/'),
        partial: getPartial('portal.profile'),
      },
    },
    external: {
      path: {
        relative: () => 'external',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.external.path.relative(),
          ].join('/'),
        partial: getPartial('portal.external'),
      },

      elearning: {
        path: {
          relative: () => 'elearning',
          full: () =>
            [
              routes.portal.external.path.full(),
              routes.portal.external.elearning.path.relative(),
            ].join('/'),
          partial: getPartial('portal.external.elearning'),
        },
        scorm: {
          path: {
            relative: () => 'scorm',
            full: () =>
              [
                routes.portal.external.elearning.path.full(),
                routes.portal.external.elearning.scorm.path.relative(),
              ].join('/'),
            partial: getPartial('portal.external.elearning.scorm'),
          },
        },
      },
    },
    catalogue: {
      path: {
        relative: () => 'opportunities',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.catalogue.path.relative(),
          ].join('/'),
        partial: getPartial('portal.catalogue'),
      },
      elearningCourses: {
        path: {
          relative: () => 'elearning-courses',
          full: () =>
            [
              routes.portal.catalogue.path.full(),
              routes.portal.catalogue.elearningCourses.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.elearningCourses'),
        },
      },
      learningPaths: {
        path: {
          relative: () => 'learning-paths',
          full: () =>
            [
              routes.portal.catalogue.path.full(),
              routes.portal.catalogue.learningPaths.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.learningPaths'),
        },
      },
      liveCourses: {
        path: {
          relative: () => 'live-courses',
          full: () =>
            [
              routes.portal.catalogue.path.full(),
              routes.portal.catalogue.liveCourses.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.liveCourses'),
        },
      },
    },

    activity: {
      path: {
        relative: () => 'activity',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.activity.path.relative(),
          ].join('/'),
        partial: getPartial('portal.activity'),
      },
      elearning: {
        path: {
          relative: () => 'elearning',
          full: () =>
            [
              routes.portal.activity.path.full(),
              routes.portal.activity.elearning.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.elearningCourses'),
        },
      },
      liveSession: {
        path: {
          relative: () => 'live-training',
          full: () =>
            [
              routes.portal.activity.path.full(),
              routes.portal.activity.liveSession.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.liveSession'),
        },
      },
      learningPath: {
        path: {
          relative: () => 'learning-paths',
          full: () =>
            [
              routes.portal.activity.path.full(),
              routes.portal.activity.learningPath.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.learningPath'),
        },
      },
      assessment: {
        path: {
          relative: () => 'assessments',
          full: () =>
            [
              routes.portal.activity.path.full(),
              routes.portal.activity.assessment.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.assessment'),
        },
      },
      individual: {
        path: {
          relative: () => 'individual',
          full: () =>
            [
              routes.portal.activity.path.full(),
              routes.portal.activity.individual.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.individual'),
        },
      },
      survey: {
        path: {
          relative: () => 'survey',
          full: () =>
            [
              routes.portal.activity.path.full(),
              routes.portal.activity.survey.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.survey'),
        },
      },
      checklist: {
        path: {
          relative: () => 'checklist',
          full: () =>
            [
              routes.portal.activity.path.full(),
              routes.portal.activity.checklist.path.relative(),
            ].join('/'),
          partial: getPartial('portal.catalogue.checklist'),
        },
      },
    },

    competency: {
      path: {
        relative: () => 'competency',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.competency.path.relative(),
          ].join('/'),
        partial: getPartial('portal.competency'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.competency.path.full(),
              routes.portal.competency.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.competency.item'),
        },
      },
    },
    elearningCourse: {
      path: {
        relative: () => 'elearning-course',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.elearningCourse.path.relative(),
          ].join('/'),
        partial: getPartial('portal.elearningCourse'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.elearningCourse.path.full(),
              routes.portal.elearningCourse.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.elearningCourse.item'),
        },
      },
    },
    assessmentForm: {
      path: {
        relative: () => 'assessment-form',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.assessmentForm.path.relative(),
          ].join('/'),
        partial: getPartial('portal.assessmentForm'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.assessmentForm.path.full(),
              routes.portal.assessmentForm.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.assessmentForm.item'),
        },
      },
    },
    learningPath: {
      path: {
        relative: () => 'learning-path',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.learningPath.path.relative(),
          ].join('/'),
        partial: getPartial('portal.learningPath'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.learningPath.path.full(),
              routes.portal.learningPath.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.learningPath.item'),
        },
      },
    },
    learningProgram: {
      path: {
        relative: () => 'learning-program',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.learningProgram.path.relative(),
          ].join('/'),
        partial: getPartial('portal.learningProgram'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.learningProgram.path.full(),
              routes.portal.learningProgram.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.learningProgram.item'),
        },
      },
    },
    liveCourse: {
      path: {
        relative: () => 'live-course',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.liveCourse.path.relative(),
          ].join('/'),
        partial: getPartial('portal.liveCourse'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.liveCourse.path.full(),
              routes.portal.liveCourse.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.liveCourse.item'),
        },
      },
    },
    liveSession: {
      path: {
        relative: () => 'live-session',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.liveSession.path.relative(),
          ].join('/'),
        partial: getPartial('portal.liveSession'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.liveSession.path.full(),
              routes.portal.liveSession.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.liveSession.item'),
        },
      },
    },
    surveyForm: {
      path: {
        relative: () => 'survey-form',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.surveyForm.path.relative(),
          ].join('/'),
        partial: getPartial('portal.surveyForm'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.surveyForm.path.full(),
              routes.portal.surveyForm.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.surveyForm.item'),
        },
      },
    },

    assessment: {
      path: {
        relative: () => 'assessment',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.assessment.path.relative(),
          ].join('/'),
        partial: getPartial('portal.assessment'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.assessment.path.full(),
              routes.portal.assessment.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.assessment.item'),
        },
      },
    },

    survey: {
      path: {
        relative: () => 'survey',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.survey.path.relative(),
          ].join('/'),
        partial: getPartial('portal.survey'),
      },

      item: {
        // for assignment instnace survey player
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.survey.path.full(),
              routes.portal.survey.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.survey.item'),
        },
        liveSession: {
          path: {
            relative: (liveSessionId?) =>
              liveSessionId
                ? `liveSession/${liveSessionId}`
                : 'liveSession/:liveSessionId',
            full: (id?, liveSessionId?) =>
              [
                routes.portal.survey.item.path.full(id),
                routes.portal.survey.item.liveSession.path.relative(
                  liveSessionId,
                ),
              ].join('/'),
            partial: getPartial('portal.survey.item.liveSession'),
          },
        },
      },
    },

    checklistForm: {
      path: {
        relative: () => 'checklist-form',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.checklistForm.path.relative(),
          ].join('/'),
        partial: getPartial('portal.checklistForm'),
      },
      item: {
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.checklistForm.path.full(),
              routes.portal.checklistForm.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.checklistForm.item'),
        },
      },
    },
    checklist: {
      path: {
        relative: () => 'checklist',
        full: () =>
          [
            routes.portal.path.full(),
            routes.portal.checklist.path.relative(),
          ].join('/'),
        partial: getPartial('portal.checklist'),
      },
      item: {
        // for assignment instance checklist player
        path: {
          relative: (id?) => id || ':id',
          full: (id?) =>
            [
              routes.portal.checklist.path.full(),
              routes.portal.checklist.item.path.relative(id),
            ].join('/'),
          partial: getPartial('portal.checklist.item'),
        },
      },
    },
  },

  public: {
    path: {
      relative: () => 'public',
      full: () => [routes.path.full(), routes.public.path.relative()].join('/'),
      partial: getPartial('public'),
    },

    register: {
      path: {
        relative: () => 'register',
        full: () =>
          [
            routes.public.path.full(),
            routes.public.register.path.relative(),
          ].join('/'),
        partial: getPartial('public.register'),
      },

      confirm: {
        path: {
          relative: () => 'confirm',
          full: () =>
            [
              routes.public.register.path.full(),
              routes.public.register.confirm.path.relative(),
            ].join('/'),
          partial: getPartial('public.register.confirm'),
        },
      },

      item: {
        path: {
          relative: (surveyFormLearningUnitId?) =>
            surveyFormLearningUnitId || ':surveyFormLearningUnitId',
          full: (surveyFormLearningUnitId?) =>
            [
              routes.public.register.path.full(),
              routes.public.register.item.path.relative(
                surveyFormLearningUnitId,
              ),
            ].join('/'),
          partial: getPartial('public.register.item'),
        },
      },
    },

    session: {
      path: {
        relative: () => 'session',
        full: () =>
          [
            routes.public.path.full(),
            routes.public.session.path.relative(),
          ].join('/'),
        partial: getPartial('public.session'),
      },

      dashboard: {
        path: {
          relative: () => 'dashboard',
          full: () =>
            [
              routes.public.session.path.full(),
              routes.public.session.dashboard.path.relative(),
            ].join('/'),
          partial: getPartial('public.session.dashboard'),
        },
      },

      trainer: {
        path: {
          relative: () => 'trainer',
          full: () =>
            [
              routes.public.session.path.full(),
              routes.public.session.trainer.path.relative(),
            ].join('/'),
          partial: getPartial('public.session.trainer'),
        },
        confirm: {
          path: {
            relative: () => 'confirm',
            full: () =>
              [
                routes.public.session.trainer.path.full(),
                routes.public.session.trainer.confirm.path.relative(),
              ].join('/'),
            partial: getPartial('public.session.trainer.confirm'),
          },
        },
        decline: {
          path: {
            relative: () => 'decline',
            full: () =>
              [
                routes.public.session.trainer.path.full(),
                routes.public.session.trainer.decline.path.relative(),
              ].join('/'),
            partial: getPartial('public.session.trainer.decline'),
          },
        },
      },

      venue: {
        path: {
          relative: () => 'venue',
          full: () =>
            [
              routes.public.session.path.full(),
              routes.public.session.venue.path.relative(),
            ].join('/'),
          partial: getPartial('public.session.venue'),
        },
        confirm: {
          path: {
            relative: () => 'confirm',
            full: () =>
              [
                routes.public.session.venue.path.full(),
                routes.public.session.venue.confirm.path.relative(),
              ].join('/'),
            partial: getPartial('public.session.venue.confirm'),
          },
        },
        decline: {
          path: {
            relative: () => 'decline',
            full: () =>
              [
                routes.public.session.venue.path.full(),
                routes.public.session.venue.decline.path.relative(),
              ].join('/'),
            partial: getPartial('public.session.venue.decline'),
          },
        },
      },

      feedback: {
        path: {
          relative: () => 'feedback',
          full: () =>
            [
              routes.public.session.path.full(),
              routes.public.session.feedback.path.relative(),
            ].join('/'),
          partial: getPartial('public.session.feedback'),
        },
      },

      approval: {
        path: {
          relative: () => 'approval',
          full: () =>
            [
              routes.public.session.path.full(),
              routes.public.session.approval.path.relative(),
            ].join('/'),
          partial: getPartial('public.session.approval'),
        },
      },

      attendance: {
        path: {
          relative: () => 'attendance',
          full: () =>
            [
              routes.public.session.path.full(),
              routes.public.session.attendance.path.relative(),
            ].join('/'),
          partial: getPartial('public.session.attendance'),
        },
      },

      register: {
        path: {
          relative: () => 'register',
          full: () =>
            [
              routes.public.session.path.full(),
              routes.public.session.register.path.relative(),
            ].join('/'),
          partial: getPartial('public.session.register'),
        },
      },
    },
  },

  common: {
    path: {
      relative: () => 'common',
      full: () => [routes.path.full(), routes.common.path.relative()].join('/'),
      partial: getPartial('common'),
    },
    report: {
      path: {
        relative: () => 'report',
        full: () =>
          [
            routes.common.path.full(),
            routes.common.report.path.relative(),
          ].join('/'),
        partial: getPartial('common.report'),
      },
      download: {
        path: {
          relative: (id) => `download/${id}` || 'download/:id',
          full: (id = ':id') =>
            [
              routes.common.report.path.full(),
              routes.common.report.download.path.relative(id),
            ].join('/'),
          partial: getPartial('common.report.download'),
        },
      },
    },

    certificate: {
      path: {
        relative: () => 'certificate',
        full: () =>
          [
            routes.common.path.full(),
            routes.common.certificate.path.relative(),
          ].join('/'),
        partial: getPartial('common.certificate'),
      },
      download: {
        path: {
          relative: (id) => `download/${id}` || 'download/:id',
          full: (id = ':id') =>
            [
              routes.common.certificate.path.full(),
              routes.common.certificate.download.path.relative(id),
            ].join('/'),
          partial: getPartial('common.certificate.download'),
        },
      },
    },
  },
};
