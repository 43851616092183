import store from 'store';

import { getClients } from '@nl-lms/feature/dashboard/sdk';

import { apiBaseUrl } from './api';

const { rtk: dashboardRtkClient } = getClients({
  baseUrl: `${apiBaseUrl('v2')}`,
  tokenGetter: () => store.get('accessToken'),
});

export const dashboardApi = dashboardRtkClient;
dashboardApi.enhanceEndpoints<'Dashboard'>({
  endpoints: {
    listDashboards: {
      providesTags: [{ type: 'Dashboard', id: 'LIST' }],
    },
    createDashboard: {
      invalidatesTags: [{ type: 'Dashboard', id: 'LIST' }],
    },
    updateDashboard: {
      invalidatesTags: [{ type: 'Dashboard', id: 'LIST' }],
    },
    deleteDashboard: {
      invalidatesTags: [{ type: 'Dashboard', id: 'LIST' }],
    },
  },
});
