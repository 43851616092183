import { useAbility } from '@casl/react';
import React, { useCallback, useContext, useMemo } from 'react';

import {
  LearningAssignmentInstanceStatus,
  LearningAssignmentLearningContentType,
} from '@nl-lms/common/feature/types';
import {
  ApiQueryFilter,
  AppQueryFilter,
  FieldType,
  QueryFilter,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { AssessmentScope } from '@nl-lms/feature/assessment/sdk';
import { ElearningScope } from '@nl-lms/feature/elearning/sdk';
import {
  LearningAssignmentInitialNotificationTemplateName,
  LearningAssignmentInstanceNotificationAudienceName,
} from '@nl-lms/feature/learning-assignments/sdk';
import { SampleReceiversQuery } from '@nl-lms/feature/notifications/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Card,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  StatusTag,
  TableColumn,
  TableWithFullPagination,
} from '@nl-lms/ui/components';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import {
  FilterBar,
  ListViewProvider,
  useListViewContext,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { formatConstantString } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';
import { AbilityContext } from '@nl-lms/web/Can';
import { useTableAction } from '@nl-lms/web/_common/hooks';
import { authStore } from '@nl-lms/web/_common/modules/Auth/auth';
import {
  fetchAndMapAssignments,
  fetchAndMapLearners,
  mapAndLoadEnumsForSelect,
} from '@nl-lms/web/adminApp/_common/utils/fetchEntitiesForSelectMethods';
import { routes } from '@nl-lms/web/lib/routes';

import { learningAssignmentsApi } from '../../../../../_common/services/api';
import { useTriggerNotificationSendSideModal } from '../../../notifications/NotificationSendModal';
import { NotificationTemplateContext } from '../../../notifications/NotificationTemplateProvider';
import { IWidgetComponent } from '../widget.types';
import './AssignmentInstances.scss';

const { useListLearningAssignmentInstancesQuery } = learningAssignmentsApi;

export const AssignmentInstances: IWidgetComponent = ({
  label,
  baseFilters,
  description,
}) => {
  const _baseFilters = useMemo<AppQueryFilter>(() => {
    const baseQuery = new QueryFilter({ filters: baseFilters });

    return baseQuery.appQueryFilter;
  }, [baseFilters]);

  return (
    <Card className="assignment-instances">
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>{label || 'Assignment Instances'}</Card.HeaderTitle>
        <Card.HeaderTooltip title={description} />
      </Card.Header>

      <Card.Content className="assignment-instances__table">
        <ListViewProvider
          key="list-assignment-instances"
          name="list-assignment-instances"
          paginationLimit={10}
          initialSorting={[['created_at', 'desc']]}
          baseFilters={_baseFilters}
          useUrlQuery={false}
          persistToLocalStorage={false}
        >
          <div className="assignment-instances__filters">
            <MandatoryAssignmentsCardFilters />
          </div>

          <MandatoryAssignmentsCardContent />
        </ListViewProvider>
      </Card.Content>
    </Card>
  );
};

const MandatoryAssignmentsCardContent = () => {
  const {
    sorting,
    query,
    onChangePagination,
    onChangeSorting,
    filters,
    onChangeColumns,
  } = useListViewContext();
  const { data, isLoading, isFetching } =
    useListLearningAssignmentInstancesQuery({
      query: { query: transformTsRestQuery(query) },
    });
  const [rows, pagination] = useListViewTableData(data);

  console.log('query', query);

  const { templatesByName } = useContext(NotificationTemplateContext);
  const showSendNotificationModal = useTriggerNotificationSendSideModal();
  const onSendNotificationModal = useCallback(
    async ({ list }: { list: string[] | AppQueryFilter }) => {
      let receiverFilters: string[] | ApiQueryFilter;
      if (!Array.isArray(list)) {
        const queryFilter = new QueryFilter({ filters: list });
        receiverFilters = queryFilter.apiQueryFilter;
      } else {
        receiverFilters = list;
      }
      const receivers: SampleReceiversQuery = {
        name: LearningAssignmentInstanceNotificationAudienceName,
        filters: receiverFilters,
      };

      await showSendNotificationModal(
        receivers,
        _.cloneDeep(
          templatesByName[LearningAssignmentInitialNotificationTemplateName]
            .content,
        ),
        MentionOptions,
      );
    },
    [],
  );
  const onClickSendNotification = useTableAction(onSendNotificationModal, {
    withRowCountProp: true,
    baseFilters: query.filters,
    showConfirmation: false,
    showNotification: false,
  });

  return (
    <TableWithFullPagination
      // @ts-ignore
      data={rows}
      isLoading={isLoading || isFetching}
      columns={MandatoryAssignmentsCardTableColumns}
      sorting={sorting}
      tableActions={[
        {
          name: 'Notify',
          handler: (rows) =>
            onClickSendNotification(
              rows?.map((row) => ({ ...row, id: row?.learnerId })),
            ),
        },
      ]}
      pagination={pagination}
      selectionMode="checkbox"
      onChangePagination={onChangePagination}
      onChangeSorting={onChangeSorting}
      onChangeColumns={onChangeColumns}
    >
      <NoDataPlaceholder.Container>
        <NoDataPlaceholder.Icon
          name={filters ? 'FileIcon' : 'FileTextIcon'}
          size="large"
          color={
            filters
              ? NoDataPlaceholderColor.warning
              : NoDataPlaceholderColor.default
          }
        />
        <NoDataPlaceholder.Title>
          {filters
            ? 'There are no learning assignments that match your search'
            : 'There are no learning assignments created'}
        </NoDataPlaceholder.Title>
        <NoDataPlaceholder.Subtitle>
          {filters
            ? 'You can try changing the active filters'
            : 'You can start creating assignments if your current role allows it by pressing the button below. '}
        </NoDataPlaceholder.Subtitle>
      </NoDataPlaceholder.Container>
    </TableWithFullPagination>
  );
};

const MandatoryAssignmentsCardFilters = () => {
  const { filters, onChangeFilters } = useListViewContext();

  return (
    <FilterBar
      id="mandatory-learning-filters"
      fields={MandatoryAssignmentsCardFiltersFields}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

const MandatoryAssignmentsCardTableColumns: TableColumn[] = [
  {
    Header: 'Learner',
    accessor: 'lastName',
    sortField: 'learner_name',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      return (
        <Sensitive>
          {ability.can('view', 'learner_page') ? (
            <Link
              to={routes.admin.learners.item.path.full(row.original.learnerId)}
            >
              {row.original.learner.firstName} {row.original.learner.lastName}
            </Link>
          ) : (
            <span>
              {row.original.learner.firstName} {row.original.learner.lastName}
            </span>
          )}
        </Sensitive>
      );
    },
  },
  {
    Header: 'Content',
    accessor: 'learningItemName',
    sortField: 'learningItemName',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      let contentUrl = '';
      switch (row.original.type) {
        case LearningAssignmentLearningContentType.elearning:
          contentUrl = ability.can(
            ElearningScope.viewElearningCourse.action,
            ElearningScope.viewElearningCourse.resource,
          )
            ? routes.admin.catalogue.elearningCourses.item.path.full(
                row.original.learningItemId,
              )
            : '';
          break;
        case LearningAssignmentLearningContentType.assessment:
          contentUrl = ability.can('view', 'assessment_instance_page')
            ? routes.admin.catalogue.assessments.item.path.full(
                row.original.learningItemId,
              )
            : '';
          break;
        case LearningAssignmentLearningContentType['live-learning']:
          contentUrl = ability.can('view', 'live_course_page')
            ? routes.admin.catalogue.liveCourses.item.path.full(
                row.original.learningItemId,
              )
            : '';
          break;
        case LearningAssignmentLearningContentType.checklist:
          contentUrl = ability.can('view', 'checklist_page')
            ? routes.admin.catalogue.checklists.item.path.full(
                row.original.learningItemId,
              )
            : '';
          break;
        case LearningAssignmentLearningContentType.survey:
          contentUrl = ability.can('view', 'survey_page')
            ? routes.admin.catalogue.surveys.item.path.full(
                row.original.learningItemId,
              )
            : '';
          break;
        default:
          break;
      }

      if (!contentUrl) {
        return row.original.learningItemName;
      }
      return <Link to={contentUrl}>{row.original.learningItemName}</Link>;
    },
  },
  {
    Header: 'Status',
    accessor: 'learningItemInstanceStatus',
    sortField: 'learningItemInstanceStatus',
    Cell: ({ row }) => {
      return (
        <StatusTag
          status={
            LearningAssignmentInstanceStatus[
              row.original.learningItemInstanceStatus
            ]
          }
        />
      );
    },
  },
  {
    Header: 'Mandatory',
    accessor: 'mandatory',
    sortField: 'mandatory',
    Cell: ({ row }) => (row.original.mandatory ? 'Yes' : 'No'),
  },
  {
    Header: 'Assigned On',
    accessor: 'createdAt',
    sortField: 'createdAt',
    Cell: ({ row }) => <PrettyDate value={row.original.createdAt} withTime />,
  },
  {
    Header: 'Due Date',
    accessor: 'dueDate',
    sortField: 'dueDate',
    Cell: ({ row }) => <PrettyDate value={row.original.dueDate} withTime />,
  },
];

const MentionOptions = [
  {
    id: '{{learner.firstName}}',
    label: '{{learner.firstName}}',
  },
  { id: '{{learner.lastName}}', label: '{{learner.lastName}}' },
  { id: '{{learner.email}}', label: '{{learner.email}}' },
  {
    id: '{{learningAssignment.name}}',
    label: '{{learningAssignment.name}}',
  },
  {
    id: '{{learningAssignment.contentName}}',
    label: '{{learningAssignment.contentName}}',
  },
  {
    id: '{{learningAssignment.dueDate}}',
    label: '{{learningAssignment.dueDate}}',
  },
  {
    id: '{{portalUrl}}',
    label: '{{portalUrl}}',
  },
];

const MandatoryAssignmentsCardFiltersFields = [
  {
    name: 'learner_id',
    label: 'Learner',
    type: FieldType.select,
    loadOptions: fetchAndMapLearners,
    ValueComponent: ({ children }) => {
      return <Sensitive>{children}</Sensitive>;
    },
  },
  {
    name: 'learning_assignment_id',
    label: 'Assignment',
    type: FieldType.select,
    loadOptions: fetchAndMapAssignments,
  },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.select,
    loadOptions: mapAndLoadEnumsForSelect(LearningAssignmentInstanceStatus),
  },
  {
    name: 'type',
    label: 'Type',
    type: FieldType.select,
    loadOptions: mapAndLoadEnumsForSelect(
      LearningAssignmentLearningContentType,
    ),
  },
];
