import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  CreateLearnerGroupSchema,
  LearnerGroupSchema,
  LearnerSchema,
  UpdateLearnerGroupSchema,
} from '@nl-lms/common/feature/types';
import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
  SdkEndpointType,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import {
  LearnerGroupsRouterPrefix,
  LearnersRouterPrefix,
} from './organization.constants.sdk';
import {
  CreateLearnerPayloadSchema,
  ExtendedLearnerSchema,
  LearnerWithDynamicDetailsSchema,
  UpdateLearnerPayloadSchema,
} from './organization.schema.sdk';

const contract = initContract();

export const OrganizationRouterContract = contract.router({
  getLearner: {
    method: 'GET',
    path: `${LearnersRouterPrefix}/:id`,
    responses: ContractStandardResponse(ExtendedLearnerSchema),
    pathParams: z.object({ id: z.string() }),
  },
  listLearners: {
    method: 'POST',
    path: `${LearnersRouterPrefix}/list`,
    responses: ContractPaginatedResponse(LearnerWithDynamicDetailsSchema),
    body: z.object({ query: z.object({ query: ApiQuerySchema }) }),
    description: SdkEndpointType.Query,
  },
  createLearner: {
    method: 'POST',
    path: `${LearnersRouterPrefix}`,
    responses: ContractStandardResponse(LearnerSchema),
    body: CreateLearnerPayloadSchema,
  },
  updateLearner: {
    method: 'PATCH',
    path: `${LearnersRouterPrefix}/:id`,
    responses: ContractStandardResponse(z.boolean()),
    body: UpdateLearnerPayloadSchema,
    pathParams: z.object({ id: z.string() }),
  },
  deactivateLearners: {
    method: 'PATCH',
    path: `${LearnersRouterPrefix}/deactivate`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  activateLearners: {
    method: 'PATCH',
    path: `${LearnersRouterPrefix}/activate`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  listLearnerGroups: {
    method: 'GET',
    path: `${LearnerGroupsRouterPrefix}/`,
    responses: ContractPaginatedResponse(LearnerGroupSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  getLearnerGroup: {
    method: 'GET',
    path: `${LearnerGroupsRouterPrefix}/:learnerGroupId`,
    responses: ContractStandardResponse(LearnerGroupSchema),
    pathParams: z.object({ learnerGroupId: z.string() }),
  },
  importLearnerGroups: {
    method: 'POST',
    contentType: 'multipart/form-data',
    path: `${LearnerGroupsRouterPrefix}/import`,
    responses: ContractStandardResponse(z.boolean()),
    body: contract.type<{ file: File; identifier: string }>(),
  },
  exportLearnerGroups: {
    method: 'GET',
    path: `${LearnerGroupsRouterPrefix}/export`,
    responses: ContractStandardResponse(z.object({ signedUrl: z.string() })),
    query: z.object({ learnerGroupIds: z.array(z.string()) }),
  },
  createLearnerGroup: {
    method: 'POST',
    path: `${LearnerGroupsRouterPrefix}`,
    responses: ContractStandardResponse(LearnerGroupSchema),
    query: z.object({ testing: z.boolean().optional() }),
    body: CreateLearnerGroupSchema,
  },
  updateLearnerGroup: {
    method: 'PATCH',
    path: `${LearnerGroupsRouterPrefix}/:id`,
    responses: ContractStandardResponse(z.boolean()),
    body: UpdateLearnerGroupSchema,
    pathParams: z.object({ id: z.string() }),
  },
  deleteLearnerGroups: {
    method: 'DELETE',
    path: `${LearnerGroupsRouterPrefix}`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
});
