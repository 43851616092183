import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { IndividualLearning } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  FormField,
  Input,
  Separator,
  SideModal,
  Textarea,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  formatConstantString,
  getTranslatedMessageFromError,
} from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { IndividualLearningTypeSelect } from '../../../_common/modules/IndividualLearningTypeSelect';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';
import { AdminIndividualLearningDeliveryTypeSelect } from './AdminIndividualLearningDeliveryTypeSelect';

type Props = {
  individualLearning?: Partial<IndividualLearning>;
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.number().required(),
  deliveryType: yup.number().required(),
  vendorId: yup.string(),
  adminOnly: yup.boolean(),
  details: yup.string(),
  tagIds: yup.array(),
});

export const AdminIndividualLearningEditFormSideModal = ({
  individualLearning = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...individualLearning,
    },
  });
  const { hide, show } = useModal();
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateIndividualLearningMutation',
    createHookName: 'useCreateIndividualLearningMutation',
    entityId: individualLearning.id,
    parseEntity: (entity) => ({ ...entity }),
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {individualLearning.id
            ? 'Edit Individual Learning'
            : 'Create New Individual Learning'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Vendor"
              errorMessage={errors?.vendorId?.message}
              required
            >
              <Controller
                control={control}
                name="vendorId"
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    hasError={!!errors?.vendorId?.message}
                    isClearable
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Type"
              errorMessage={errors?.type?.message}
              required
            >
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <IndividualLearningTypeSelect
                    {...field}
                    // @ts-ignore
                    initialValue={{
                      value: individualLearning.type,
                      label: formatConstantString(
                        // @ts-ignore
                        C.INDIVIDUAL_LEARNING_TYPES[individualLearning.type],
                      ),
                    }}
                    hasError={!!errors?.vendorId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Delivery Type"
              required
              errorMessage={errors?.deliveryType?.message}
            >
              <Controller
                name="deliveryType"
                control={control}
                render={({ field }) => (
                  <AdminIndividualLearningDeliveryTypeSelect
                    {...field}
                    // @ts-ignore
                    initialValue={individualLearning.deliveryType}
                    hasError={!!errors?.deliveryType?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <FormField label="Tags" errorMessage={errors?.tagIds?.message}>
            <Controller
              name="tagIds"
              control={control}
              render={({ field }) => (
                <AdminTagSelect {...field} selectedItems={field.value} />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Details"
              // @ts-ignore
              errorMessage={errors?.details?.message}
            >
              <Controller
                name="details"
                control={control}
                render={({ field }) => (
                  <Textarea
                    name="details"
                    placeholder="Some details about the individual learning"
                    onChange={(e) => field.onChange(e.target.value)}
                    value={field.value}
                    // @ts-ignore
                    hasError={errors?.details?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Admin Only"
              errorMessage={errors?.adminOnly?.message}
            >
              <Controller
                name="adminOnly"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    name="adminOnly"
                    label="Admin Only"
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <Button
          label={individualLearning.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
