import {
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { OrganizationRouterContract } from './organization.router-contract.sdk';

export const getOrganizationClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'organizationStore';

  const tsRest = buildTsRestInstance<typeof OrganizationRouterContract>(
    apiInstance,
    OrganizationRouterContract,
    apiOptions,
  );

  const rtk = buildRtkInstance(tsRest, OrganizationRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
