import { AppRouter, InitClientReturn, initClient } from '@ts-rest/core';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';

import { ApiClientOptions } from '../../types';

export function build<T extends AppRouter>(
  instance,
  router: T,
  options: ApiClientOptions,
): InitClientReturn<T, any> {
  const api = async ({
    path,
    method,
    headers,
    body,
    contentType,
  }): Promise<{ status: unknown; body: unknown; headers: Headers }> => {
    const contentTypeHeaders = {};
    if (contentType) contentTypeHeaders['Content-Type'] = contentType;

    try {
      const result = (await instance.request({
        method,
        url: path,
        headers: {
          ...contentTypeHeaders,
          ...headers,
        },
        data: body,
      })) as AxiosResponse;

      // @ts-ignore
      return { status: 200, body: result, headers: result.headers };
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError;
        const response = error.response as AxiosResponse;
        return {
          status: response.status as number,
          body: e.toJSON(),
          // @ts-ignore
          headers: response.headers,
        };
      }
      throw e;
    }
  };

  return initClient(router, {
    baseUrl: options.baseUrl,
    baseHeaders: {},
    // @ts-ignore
    api,
    jsonQuery: true,
  });
}
