import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  AppQuery,
  AppQueryFilter,
  FieldType,
  QueryFilter,
  QueryOperator,
} from '@nl-lms/common/shared';
import { Learner, LearnerGroup } from '@nl-lms/feature/organization/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Icon,
  Input,
  NoDataPlaceholder,
  Sensitive,
  Separator,
  SideModal,
  Table,
  Typography,
  useModal,
  useShowModal,
  useTableRowSelection,
} from '@nl-lms/ui/components';
import { FilterBar } from '@nl-lms/ui/modules';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { organizationApi, useApi } from '../../../_common/services/api';
import { buildFiltersFromRowSelection } from '../../../_common/utils/buildFiltersFromRowSelection';
import { fetchAndMapLearners } from '../../_common/utils/fetchEntitiesForSelectMethods';
import { getLearnerPropertiesFilterFields } from '../../_common/utils/getLearnerPropertiesFilterFields';

const {
  useUpdateLearnerGroupMutation,
  useCreateLearnerGroupMutation,
  useListLearnersQuery,
  useLazyListLearnersQuery,
} = organizationApi;

type Props = {
  learnerGroup?: Partial<LearnerGroup>;
};

const Schema = yup.object().shape({
  title: yup.string().required(),
  learnerIds: yup
    .array()
    .of(yup.string())
    .min(1, 'Must contain at least one learner')
    .required(),
  isDynamic: yup.boolean(),
  filters: yup.object().nullable(),
});

export const AdminLearnerGroupStaticEditFormSideModal = ({
  learnerGroup = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      title: learnerGroup.title,
      isDynamic: false,
      learnerIds: [],
    },
  });

  const [
    updateLearnerGroup,
    { isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateLearnerGroupMutation();
  const updateLearnerGroupAction = useAction(updateLearnerGroup, {
    successDetails: 'The learner group has been updated successfully',
  });

  const [
    createLearnerGroup,
    { isLoading: isCreateLoading, error: createError },
  ] = useCreateLearnerGroupMutation();
  const createLearnerGroupAction = useAction(createLearnerGroup, {
    successDetails: 'The learner group has been created successfully',
  });

  const learnerIds = watch('learnerIds');
  const fetchLearnersQueryParams = useMemo(() => {
    const filters = new QueryFilter();
    if (learnerGroup?.id) {
      filters.add({
        field: 'learner_group_id',
        operator: QueryOperator.Includes,
        value: [learnerGroup.id],
      });
    }
    const query: AppQuery = {
      pagination: { offset: 0, limit: 5000 },
      filters: filters.appQueryFilter,
    };
    return query;
  }, [learnerGroup]);
  const { data: fetchedLearners, isLoading: isFetchLearnersLoading } =
    useListLearnersQuery(
      { query: { query: transformTsRestQuery(fetchLearnersQueryParams) } },
      { skip: !learnerGroup?.id },
    );

  const [learners, setLearners] = useState<Learner[]>([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    rowCount: learners.length,
  });

  useEffect(() => {
    if (fetchedLearners) {
      // @ts-ignore
      setLearners(fetchedLearners.rows);
      setValue(
        'learnerIds',
        // @ts-ignore
        fetchedLearners.rows.map((r) => r.id),
      );
    }
  }, [fetchedLearners]);

  const onChangeLearners = useCallback((learners: Learner[]) => {
    const uniqueLearners = _.uniqBy(learners, (l) => l.id);
    const newLearnerIds = uniqueLearners.map((l) => l.id);
    setLearners(uniqueLearners);
    // @ts-ignore
    setValue('learnerIds', newLearnerIds);
  }, []);
  const paginatedLearners = useMemo(
    () =>
      learners.slice(pagination.offset, pagination.offset + pagination.limit),
    [learners, pagination],
  );
  const { hide } = useModal();

  const showAddLearnersModal = useShowModal<{
    success: boolean;
    payload: { learners: Learner[] };
  }>(AdminLearnerGroupAddLearnersSideModal);

  const onSubmit = useCallback(
    async (params) => {
      let res;
      if (learnerGroup.id) {
        res = await updateLearnerGroupAction({
          ...params,
          id: learnerGroup.id,
        });
      } else {
        res = await createLearnerGroupAction(params);
      }

      if (res?.data) hide();
    },
    [learnerGroup],
  );

  const onClickAddLearners = useCallback(async () => {
    console.log('showing');
    const result = await showAddLearnersModal({});
    console.log(result);
    if (result?.success) {
      onChangeLearners([...result.payload.learners, ...learners]);
    }
  }, [learners]);

  const onClickRemoveRows = useCallback(
    async (selectedRows, allRowsAreSelected, excludedRows) => {
      if (!allRowsAreSelected) {
        const selectedRowsIds = selectedRows.map((r) => r.id);
        onChangeLearners(
          learners.filter((l) => !selectedRowsIds.includes(l.id)),
        );
      } else {
        onChangeLearners(excludedRows);
      }
      return Promise.resolve(true);
    },
    [learners],
  );

  const onClickRemoveRow = useCallback(
    (row) => {
      onClickRemoveRows([row], false, []);
    },
    [onClickRemoveRows],
  );

  useEffect(() => {
    register('title');
    register('isDynamic');
    register('learnerIds');
  }, []);

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {learnerGroup.id ? 'Edit Learner Group' : 'Create New Learner Group'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.title?.message}
            >
              <Input
                required
                {...register('title')}
                hasError={!!errors?.title?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography.h3>{learnerIds.length} learner(s)</Typography.h3>
          </Box>
          <Box>
            {learners.length ? (
              <Button
                regular
                style={{ float: 'right' }}
                label="Add"
                onClick={onClickAddLearners}
              />
            ) : null}
          </Box>
        </Box>
        <Separator marginTop={10} backgroundColor="transparent" />
        <Box>
          <Box>
            <Table
              columns={[
                {
                  Header: 'First Name',
                  accessor: 'firstName',
                  sortField: 'firstName',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.firstName}</Sensitive>
                  ),
                },
                {
                  Header: 'Last Name',
                  accessor: 'lastName',
                  sortField: 'lastName',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.lastName}</Sensitive>
                  ),
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  sortField: 'email',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.email}</Sensitive>
                  ),
                },
              ]}
              pagination={{
                ...pagination,
                rowCount: learners.length,
              }}
              selectionMode="checkbox"
              tableActions={[
                {
                  name: 'Remove',
                  handler: onClickRemoveRows,
                },
              ]}
              isLoading={isFetchLearnersLoading}
              rowActions={[
                {
                  name: 'Remove',
                  handler: onClickRemoveRow,
                  Icon: Icon.DeleteIcon,
                },
              ]}
              data={paginatedLearners}
              onChangePagination={setPagination}
            >
              <NoDataPlaceholder
                title="You have not added any learners"
                subtitle={
                  'To add learners to a learner group right away by using the button below.'
                }
                actionName="Add"
                actionHandler={onClickAddLearners}
              />
            </Table>
            <Separator marginTop={10} backgroundColor="transparent" />
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(createError || updateError)}
        </SideModal.Error>
        <Button
          onClick={handleSubmit(onSubmit)}
          label={learnerGroup.id ? 'Save' : 'Create'}
          isLoading={isUpdateLoading || isCreateLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};

export const AdminLearnerGroupAddLearnersSideModal = () => {
  const api = useApi();
  const { hide, show } = useModal();
  const [filters, setFilters] = useState<AppQueryFilter | undefined>(undefined);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    rowCount: 0,
  });
  const query = useMemo(
    () => ({ query: { query: transformTsRestQuery({ filters, pagination }) } }),
    [filters, pagination],
  );
  const { data, isLoading: isFetchLearnersLoading } =
    useListLearnersQuery(query);

  const [listLearners, { isLoading: isSubmitLoading, error }] =
    useLazyListLearnersQuery();

  const learners = useMemo(() => {
    if (!data) return { rows: [], count: 0 };
    return data;
  }, [data]);
  const { selectedRows, onSelectRow, excludedRows, allRowsAreSelected } =
    useTableRowSelection({
      data: learners.rows,
      initialSelectedRows: [],
    });

  const selectedLearnersCount = useMemo(() => {
    if (!allRowsAreSelected) return selectedRows.length;
    return learners.count - excludedRows.length;
  }, [learners, allRowsAreSelected, excludedRows, selectedRows]);

  const onSubmit = useCallback(async () => {
    if (allRowsAreSelected) {
      const updatedFilters = buildFiltersFromRowSelection({
        selectedRows,
        allRowsAreSelected,
        excludedRows,
        // @ts-ignore
        filters,
      });
      // @ts-ignore
      const result = await listLearners({
        filters: updatedFilters,
        pagination: { disabled: true },
      });
      if (result.data) {
        hide({
          success: true,
          payload: { learners: result.data.rows },
        });
      }
    } else {
      hide({
        success: true,
        payload: { learners: selectedRows },
      });
    }
  }, [excludedRows, selectedRows, filters, allRowsAreSelected]);

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Add Learners to Static Group</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box>
          <Typography.p>
            Select the learners that you want to add to the group by using the
            table below.
          </Typography.p>
        </Box>
        <Separator />
        <Box>
          <FilterBar
            id={''}
            // @ts-ignore
            onChangeFilters={setFilters}
            fields={[
              {
                name: 'id',
                label: 'Learner',
                type: FieldType.select,
                loadOptions: fetchAndMapLearners,
                ValueComponent: ({ children }) => {
                  return <Sensitive>{children}</Sensitive>;
                },
              },
              {
                name: 'first_name',
                label: 'First Name',
                type: FieldType.string,
                ValueComponent: ({ children }) => {
                  return <Sensitive>{children}</Sensitive>;
                },
              },
              {
                name: 'last_name',
                label: 'Last Name',
                type: FieldType.string,
                ValueComponent: ({ children }) => {
                  return <Sensitive>{children}</Sensitive>;
                },
              },
              {
                name: 'email',
                label: 'Email',
                type: FieldType.string,
                ValueComponent: ({ children }) => {
                  return <Sensitive>{children}</Sensitive>;
                },
              },
              {
                name: 'is_manager',
                label: 'Is Manger',
                type: FieldType.boolean,
              },
              ...getLearnerPropertiesFilterFields(),
            ]}
          />
        </Box>
        <Separator marginTop={10} backgroundColor="transparent" />
        <Box>
          <Box>
            <Table
              columns={[
                {
                  Header: 'First Name',
                  accessor: 'firstName',
                  sortField: 'firstName',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.firstName}</Sensitive>
                  ),
                },
                {
                  Header: 'Last Name',
                  accessor: 'lastName',
                  sortField: 'lastName',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.lastName}</Sensitive>
                  ),
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  sortField: 'email',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.email}</Sensitive>
                  ),
                },
              ]}
              selectionMode="checkbox"
              pagination={{
                ...pagination,
                rowCount: learners.count,
              }}
              isLoading={isFetchLearnersLoading}
              data={learners.rows}
              onChangePagination={setPagination}
              onSelectRow={onSelectRow}
              selectedRows={selectedRows}
              excludedRows={excludedRows}
              allRowsAreSelected={allRowsAreSelected}
            />
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <Button label="Cancel" onClick={hide} regular />
        <Button
          label={`Add ${selectedLearnersCount} Learner(s)`}
          onClick={onSubmit}
          isLoading={isSubmitLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
