import * as z from 'zod';

import { DashboardSchema } from '@nl-lms/common/feature/types';

export const CreateDashboardSchema = DashboardSchema.pick({
  label: true,
  widgets: true,
  targetLearnerGroupIds: true,
});

export const ListDashboardsSchema = z.array(
  DashboardSchema.extend({
    creator: z.object({
      id: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      email: z.string(),
    }),
  })
);
