import { useAbility } from '@casl/react';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AppQuery,
  QueryFilter,
  QueryFilterCombinator,
  QueryOperator,
  QueryPagination,
} from '@nl-lms/common/shared';
import { ScheduleDateSchema } from '@nl-lms/feature/automation/sdk';
import {
  CreateLearningAssignmentPayload,
  LearningAssignment,
  LearningAssignmentInitialNotificationTemplateName,
  LearningAssignmentNotificationMentionOptions,
  LearningAssignmentReminderNotificationTemplateName,
} from '@nl-lms/feature/learning-assignments/sdk';
import {
  LearningAssignmentModelType,
  transformTsRestQuery,
} from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  DateTime,
  FormField,
  FormWizard,
  Input,
  NoDataPlaceholder,
  PrettyDate,
  ScheduleDateInput,
  Sensitive,
  SideModal,
  SingleSelect,
  StatusTag,
  SwitchInput,
  Table,
  TablePagination,
  ToggleInput,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { AbilityContext } from '../../../Can';
import {
  learningAssignmentsApi,
  organizationApi,
} from '../../../_common/services/api';
import { getActionAbility } from '../../../_common/utils/getActionAbility';
import { AdminAssessmentSingleSelect } from '../AdminAssessment/AdminAssessmentSelect';
import { AdminChecklistSingleSelect } from '../AdminChecklist/AdminChecklistSelect';
import { AdminElearningCourseSingleSelect } from '../AdminElearningCourse/AdminElearningCourseSelect';
import { AdminLearnerGroupMultiSelect } from '../AdminLearner/AdminLearnerGroupSelect';
import { AdminLearnerMultiSelect } from '../AdminLearner/AdminLearnerSelect';
import { AdminLearningPathSingleSelect } from '../AdminLearningPath/AdminLearningPathSelect';
import { AdminLiveCourseSingleSelect } from '../AdminLiveCourse/AdminLiveCourseSelect';
import { AdminSurveySingleSelect } from '../AdminSurvey/AdminSurveySelect';
import { useEditNotificationTemplateSideModal } from '../notifications/NotificationTemplateEditSideModal';
import { NotificationTemplateContext } from '../notifications/NotificationTemplateProvider';

const { useListLearnersQuery } = organizationApi;
const { useCreateLearningAssignmentMutation } = learningAssignmentsApi;

type ContentType = LearningAssignmentModelType;
type ContentProps = {
  contentType: ContentType;
  contentId: string;
  contentName: string;
};

// @ts-ignore
type Props = ContentProps | {};

const FormSchema = z
  .object({
    name: z.string().min(1, { message: 'Assignment Name is required' }),
    mandatory: z.boolean(),
    showAfterDueDate: z.boolean(),
    contentId: z.string().optional(),
    contentType: z
      .union([
        z.literal('elearning'),
        z.literal('learningPath'),
        z.literal('assessment'),
        z.literal('liveCourse'),
        z.literal('survey'),
        z.literal('checklist'),
      ])
      .optional(),
    dueDate: ScheduleDateSchema.optional(),
    learnerGroupIds: z.array(z.string()),
    learnerIds: z.array(z.string()),
    createAssignmentInstances: z.boolean(),
    scheduleAssignmentInstances: z.boolean(),
    scheduleOn: z.string().nullish(),
    isNotifyLearnersEnabled: z.boolean(),
    isRemindLearnersEnabled: z.boolean(),
    notificationTemplateData: z.object({}),
    reminderTemplateData: z.object({}),
    reminderOffset: z.union([z.number(), z.string()]).nullish(),
  })
  .superRefine((payload, context) => {
    if (!payload.contentId) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Learning Unit is required',
        path: ['contentId'],
      });
    }
    if (!payload.contentType) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Content Type is required',
        path: ['contentType'],
      });
    }
    if (!payload.learnerIds?.length && !payload.learnerGroupIds?.length) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Specify who should be targeted by this assignment',
        path: ['learnerIds'],
      });
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Specify who should be targeted by this assignment',
        path: ['learnerGroupIds'],
      });
    }
    if (payload.scheduleAssignmentInstances && !payload.scheduleOn) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Schedule On is required',
        path: ['scheduleOn'],
      });
    }
    if (payload.isRemindLearnersEnabled && !payload.dueDate) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You cannot set a reminder without a due date.',
        path: ['dueDate'],
      });
    }
  });

const ContentTypeToSelectRecord: Record<ContentType, React.FunctionComponent> =
  {
    elearning: AdminElearningCourseSingleSelect,
    learningPath: AdminLearningPathSingleSelect,
    assessment: AdminAssessmentSingleSelect,
    liveCourse: AdminLiveCourseSingleSelect,
    survey: AdminSurveySingleSelect,
    checklist: AdminChecklistSingleSelect,
  };

export const LearningAssignmentCreateSideModal = (props: Props) => {
  const [contentName, contentId, contentType] = useMemo(() => {
    if ((props as ContentProps).contentName) {
      return [
        (props as ContentProps).contentName,
        (props as ContentProps).contentId,
        (props as ContentProps).contentType,
      ];
    }
    return ['', '', ''];
  }, [props]);
  const { templatesByName } = useContext(NotificationTemplateContext);
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
    mode: 'onSubmit',
    defaultValues: {
      contentType,
      contentId,
      name: '',
      dueDate: undefined,
      mandatory: false,
      showAfterDueDate: false,
      learnerGroupIds: [],
      learnerIds: [],
      createAssignmentInstances: true,
      scheduleAssignmentInstances: false,
      scheduleOn: undefined,
      isNotifyLearnersEnabled: false,
      isRemindLearnersEnabled: false,
      reminderOffset: 1,
      notificationTemplateData:
        templatesByName[LearningAssignmentInitialNotificationTemplateName],
      reminderTemplateData:
        templatesByName[LearningAssignmentReminderNotificationTemplateName],
    },
  });
  const { hide } = useModal();
  const [createLearningAssignment, { error, isLoading }] =
    useCreateLearningAssignmentMutation();
  const onSubmit = useCallback(async () => {
    const entity = getValues() as z.infer<typeof FormSchema>;
    const contentType = entity.contentType;
    const contentId = entity.contentId as string | null;
    const notifications = [
      {
        name: 'notification',
        enabled: entity.isNotifyLearnersEnabled,
        payload: {
          template: entity.notificationTemplateData,
        },
      },
      {
        name: 'reminder',
        payload: {
          delta: {
            days:
              typeof entity.reminderOffset === 'string'
                ? parseInt(entity.reminderOffset)
                : (entity.reminderOffset ?? 1),
          },
          template: entity.reminderTemplateData,
        },
        enabled: entity.isRemindLearnersEnabled,
      },
    ] as LearningAssignment['notifications'];
    const parsedEntity: CreateLearningAssignmentPayload = {
      name: entity.name,
      mandatory: entity.mandatory,
      showAfterDueDate: entity.showAfterDueDate,
      audience: {
        learnerGroupIds: entity.learnerGroupIds,
        learnerIds: entity.learnerIds,
        createInstances:
          entity.createAssignmentInstances ||
          entity.scheduleAssignmentInstances,
        scheduleOn: entity.scheduleOn ? entity.scheduleOn : undefined,
      },
      dueDate: entity.dueDate || null,
      assessmentFormId: contentType === 'assessment' ? contentId : null,
      learningPathId: contentType === 'learningPath' ? contentId : null,
      surveyFormId: contentType === 'survey' ? contentId : null,
      checklistId: contentType === 'checklist' ? contentId : null,
      elearningCourseId: contentType === 'elearning' ? contentId : null,
      courseId: contentType === 'liveCourse' ? contentId : null,
      notifications,
    };
    const result = await createLearningAssignment(parsedEntity);
    if ('data' in result) {
      hide();
    }
  }, []);

  // If the user is on a section, clicks
  // submit and has an error in a different section
  // they need to see that error
  // Show only the first error to prevent cluttering the UI
  const globalErrorMessage = useMemo(() => {
    return Object.keys(errors).map((field) => `${errors[field].message}`)[0];
  }, [errors]);

  return (
    <SideModal.Content>
      <FormWizard.Provider
        steps={['content', 'assignation-process', 'notifications']}
        withContainer={false}
      >
        <SideModal.Header>
          <SideModal.Title>Create Learning Assignment</SideModal.Title>
        </SideModal.Header>
        <SideModal.Body>
          <FormWizard.Step name="content">
            <AdminLearningAssignmentContentForm
              register={register}
              watch={watch}
              errors={errors}
              setValue={setValue}
              control={control}
              contentId={contentId}
              contentName={contentName}
              contentType={contentType}
            />
          </FormWizard.Step>
          <FormWizard.Step
            activeStyle={{ display: 'flex', flex: 1, flexDirection: 'column' }}
            name="assignation-process"
          >
            <AdminLearningAssignmentAssignationProcessForm
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              control={control}
            />
          </FormWizard.Step>
          <FormWizard.Step name="notifications">
            <AdminLearningAssignmentNotificationsForm
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              control={control}
            />
          </FormWizard.Step>
        </SideModal.Body>
        <SideModal.Actions>
          <SideModal.Error>
            {getTranslatedMessageFromError(error) || globalErrorMessage}
          </SideModal.Error>
          <FormWizard.Navigation />
          <Button
            label="Create"
            // @ts-ignore
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />
        </SideModal.Actions>
      </FormWizard.Provider>
    </SideModal.Content>
  );
};

const AdminLearningAssignmentContentForm = ({
  register,
  errors,
  watch,
  control,
  setValue,
  contentId,
  contentType,
  contentName,
}) => {
  const dueDateType = watch('dueDateType');
  const onChangeDueDateType = useCallback((value) => {
    setValue('dueDateType', value);
    setValue('dueDate', null);
    setValue('dueDateDelta', null);
  }, []);
  const actualContentType = watch('contentType');
  return (
    <>
      <Box margin={{ bottom: 's' }}>
        <Typography.h2>Learning Content</Typography.h2>
      </Box>
      <Box margin={{ bottom: 's' }}>
        <Typography.p type="muted">
          Specify the actual learning content that a learner should go through
          in order to complete the assignment. The status of each assignment
          instance will reflect the status of the actual learning session.
        </Typography.p>
      </Box>
      <Box margin={{ bottom: 'm' }}>
        <Typography.p type="muted">
          Besides the actual content you can also set a couple of fields that
          will influence how the learner will see the assignment in the learning
          portal. Keep in mind that if you plan on enabling auto assign or
          recurrence the due date needs to be relative in order to be calculated
          for future assignment instances.
        </Typography.p>
      </Box>
      <Box>
        <FormField
          label="Assignment Name"
          required
          errorMessage={errors.name?.message}
        >
          <Input
            hasError={!!errors.name}
            {...register('name')}
            placeholder="Assignment Name"
          />
        </FormField>
      </Box>
      <Box flex={{ flexDirection: 'row', gap: 'm' }}>
        <FormField
          label="Content Type"
          required
          errorMessage={errors.contentType?.message ? 'Field is required' : ''}
        >
          <Controller
            name="contentType"
            control={control}
            render={({ field }) => (
              <SingleSelect
                {...field}
                initialSelectedItem={contentType}
                hasError={!!errors.contentType}
                returnEntireItemOnChange={false}
                disabled={!!contentId}
                options={[
                  ...getActionAbility('assign', 'elearning_course', {
                    label: 'Elearning',
                    value: 'elearning',
                  }),
                  ...getActionAbility('assign', 'assessment_form', {
                    label: 'Assessment',
                    value: 'assessment',
                  }),
                  ...getActionAbility('assign', 'learning_path', {
                    label: 'Learning Path',
                    value: 'learningPath',
                  }),
                  ...getActionAbility('assign', 'live_course', {
                    label: 'Live Course',
                    value: 'liveCourse',
                  }),
                  ...getActionAbility('assign', 'survey', {
                    label: 'Survey',
                    value: 'survey',
                  }),
                  ...getActionAbility('assign', 'checklist', {
                    label: 'Checklist',
                    value: 'checklist',
                  }),
                ]}
              />
            )}
          />
        </FormField>
        <LearningUnitSelect
          contentType={actualContentType}
          contentName={contentName}
          contentId={contentId}
          error={errors.contentId}
          disabled={!!contentId}
          control={control}
        />
      </Box>
      <Box flex={{ flexDirection: 'row', gap: 'm' }}>
        <FormField.Root>
          <FormField.Label>Due Date</FormField.Label>
          <FormField.Input>
            <Controller
              name="dueDate"
              control={control}
              render={({ field }) => (
                <ScheduleDateInput
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FormField.Input>
          <FormField.Error>
            {errors.dueDate?.message || errors.dueDateType?.message}
          </FormField.Error>
        </FormField.Root>
        <FormField.Root>
          <FormField.Label>Content Settings</FormField.Label>
          <FormField.Input>
            <Checkbox
              {...register('mandatory')}
              className="admin-learning-assignment-modal__checkbox"
              label="Mandatory"
            />
          </FormField.Input>
          <FormField.Input>
            <Checkbox
              className="admin-learning-assignment-modal__checkbox"
              {...register('showAfterDueDate')}
              label="After the due date passes the assignment can still be accessed by learners"
            />
          </FormField.Input>
        </FormField.Root>
      </Box>
    </>
  );
};

const AdminLearningAssignmentAssignationProcessForm = ({
  register,
  errors,
  setValue,
  watch,
  control,
}) => {
  const [audienceType, setAudienceType] = useState<
    'learners' | 'learner-groups'
  >('learner-groups');
  const [pagination, setPagination] = useState<QueryPagination>({
    offset: 0,
    limit: 50,
  });
  const learnerIds = watch('learnerIds');
  const learnerGroupIds = watch('learnerGroupIds');
  const query = useMemo<AppQuery>(() => {
    if (!learnerIds.length && !learnerGroupIds.length) {
      return {
        pagination: { limit: 0, offset: 0 },
      };
    }
    const queryFilter = new QueryFilter();
    if (learnerIds.length) {
      queryFilter.add({
        field: 'id',
        operator: QueryOperator.Includes,
        value: learnerIds,
      });
    }
    if (learnerGroupIds.length) {
      queryFilter.add(
        {
          field: 'learnerGroupId',
          operator: QueryOperator.Includes,
          value: learnerGroupIds,
        },
        {
          combinator: QueryFilterCombinator.Or,
        },
      );
    }
    return {
      pagination,
      filters: queryFilter.appQueryFilter,
      sorting: [['firstName', 'asc']],
    };
  }, [learnerIds, learnerGroupIds, pagination]);
  const { data, isLoading: isFetchLearnersLoading } = useListLearnersQuery({
    query: { query: transformTsRestQuery(query) },
  });
  const tablePagination = useMemo<TablePagination>(
    () => ({
      limit: typeof pagination.limit === 'number' ? pagination.limit : 0,
      offset: typeof pagination.offset === 'number' ? pagination.offset : 0,
      // @ts-ignore
      rowCount: !query.filters ? 0 : data?.count,
    }),
    [data, pagination],
  );
  const learners = data?.rows || [];
  const scheduleAssignmentInstances = watch('scheduleAssignmentInstances');
  const createAssignmentInstances = watch('createAssignmentInstances');
  const scheduleOn = watch('scheduleOn');
  useEffect(() => {
    if (createAssignmentInstances) {
      setValue('scheduleAssignmentInstances', false);
      setValue('scheduleOn', null);
    }
  }, [createAssignmentInstances]);
  useEffect(() => {
    if (scheduleAssignmentInstances) {
      setValue('createAssignmentInstances', false);
    }
  }, [scheduleAssignmentInstances]);

  const minDate = useMemo(() => new Date(), []);
  const ability = useAbility(AbilityContext);
  const audienceError =
    // @ts-ignore
    errors.learnerGroupIds?.message || errors.learnerIds?.message;
  return (
    <>
      <Box margin={{ bottom: 's' }}>
        <Typography.h2>Assignation Process</Typography.h2>
      </Box>
      <Box margin={{ bottom: 's' }}>
        <Typography.p type="muted">
          Through these fields you are specifying which assignment instances
          should be created or scheduled. Setting both toggle inputs to a
          disabled state will not create or schedule any assignation instances.
        </Typography.p>
      </Box>
      <Box margin={{ bottom: 'm' }}>
        <Typography.p type="muted">
          Keep in mind that you can select either learner groups or learners but
          not both at the same time. You can create more instances after the
          assignment has been created.
        </Typography.p>
      </Box>
      <Box>
        <FormField.Root>
          <FormField.Label
            disabled={ability.cannot('assign', 'learner_group')}
            required
          >
            Audience
          </FormField.Label>
          <FormField.Label>
            <SwitchInput
              name="learners"
              options={[
                { label: 'Learners', value: 'learners' },
                { label: 'Learner Groups', value: 'learner-groups' },
              ]}
              initialActiveOption={audienceType}
              onChange={(audience) => setAudienceType(audience)}
            />
          </FormField.Label>
          {audienceType === 'learner-groups' ? (
            <Controller
              name="learnerGroupIds"
              control={control}
              render={({ field }) => (
                <AdminLearnerGroupMultiSelect
                  {...field}
                  selectedItems={learnerGroupIds}
                  disabled={ability.cannot('assign', 'learner_group')}
                />
              )}
            />
          ) : (
            <Controller
              name="learnerIds"
              control={control}
              render={({ field }) => (
                <AdminLearnerMultiSelect
                  {...field}
                  returnEntireItemOnChange={false}
                  initialSelectedItems={learnerIds}
                />
              )}
            />
          )}
          <FormField.Error>{audienceError}</FormField.Error>
        </FormField.Root>
      </Box>
      <Box>
        <FormField.Root>
          <FormField.Label spacing="xs">
            Assign to current audience
            <FormField.Input>
              <Controller
                render={({ field }) => (
                  <ToggleInput
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange({
                        target: { value: e, name: field.name },
                      });
                    }}
                    value={field.value}
                    defaultChecked={field.value}
                    mode="toggle"
                  />
                )}
                control={control}
                name={'createAssignmentInstances'}
              />
            </FormField.Input>
          </FormField.Label>
          <FormField.Description>
            Learning assignment instances will be created for each learner
            included in the audience section.
          </FormField.Description>{' '}
        </FormField.Root>
      </Box>
      <Box>
        <FormField.Root>
          <FormField.Label spacing="xs">
            Schedule assignation for current audience
            <FormField.Input>
              <Controller
                render={({ field }) => (
                  <ToggleInput
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange({
                        target: { value: e, name: field.name },
                      });
                    }}
                    value={field.value}
                    defaultChecked={field.value}
                    mode="toggle"
                  />
                )}
                control={control}
                name={'scheduleAssignmentInstances'}
              />
            </FormField.Input>
          </FormField.Label>
          <FormField.Description>
            Learning assignment instances will be scheduled to be created at a
            later date for each learner in the audience fields.
          </FormField.Description>{' '}
          {scheduleAssignmentInstances ? (
            <Box
              margin={{ top: 's' }}
              flex={{ flexDirection: 'row', gap: 'm' }}
            >
              <FormField label="Schedule On" noPadding>
                <Controller
                  name="scheduleOn"
                  control={control}
                  render={({ field }) => (
                    <DateTime
                      date={field.value}
                      minDate={minDate}
                      onChange={(e) => {
                        // setValue('startDate', e.target.value);
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                />
              </FormField>
            </Box>
          ) : null}
        </FormField.Root>
      </Box>
      <Box margin={{ bottom: 's' }}>
        <Typography.h3>
          {scheduleAssignmentInstances || createAssignmentInstances
            ? `${tablePagination.rowCount} assignment instances will be ${
                scheduleAssignmentInstances ? 'scheduled' : 'created'
              }`
            : 'No assignment instances will be created'}
        </Typography.h3>
      </Box>
      {scheduleAssignmentInstances || createAssignmentInstances ? (
        <Table.Provider
          data={learners}
          isLoading={isFetchLearnersLoading}
          columns={[
            {
              Header: 'Name',
              accessor: 'firstName',
              Cell: ({ row }) => (
                <Sensitive>
                  {row.original.firstName} {row.original.lastName}
                </Sensitive>
              ),
            },
            {
              Header: 'Email',
              accessor: 'email',
              Cell: ({ row }) => <Sensitive>{row.original.email}</Sensitive>,
            },
            {
              Header: 'Status',
              accessor: 'status',
              Cell: () => {
                return (
                  <StatusTag
                    status={
                      scheduleAssignmentInstances ? 'scheduled' : 'not_started'
                    }
                  />
                );
              },
            },
            {
              Header: 'Creation Date',
              accessor: 'createdAt',
              Cell: () => {
                if (scheduleAssignmentInstances && !!scheduleOn) {
                  return <PrettyDate value={scheduleOn} withTime />;
                }
                if (scheduleAssignmentInstances && !scheduleOn) {
                  return <>No Schedule Date Was Set</>;
                }
                return <>After the assignment is created</>;
              },
            },
          ]}
          pagination={tablePagination}
          onChangePagination={setPagination}
        >
          <Table.Container>
            <Table.Header />
            <Table.Body>
              {/*// @ts-ignore */}
              <Table.Rows>
                {/*// @ts-ignore */}
                <NoDataPlaceholder.Container>
                  <NoDataPlaceholder.Icon
                    name="UserIcon"
                    size="large"
                    color="success"
                  />
                  <NoDataPlaceholder.Title>
                    The assignation instances that should be created or
                    scheduled will appear in this table
                  </NoDataPlaceholder.Title>
                </NoDataPlaceholder.Container>
              </Table.Rows>
            </Table.Body>
          </Table.Container>
          <Table.PaginationRow sticky={false} />
        </Table.Provider>
      ) : null}
    </>
  );
};

const AdminLearningAssignmentNotificationsForm = ({
  register,
  errors,
  watch,
  setValue,
  control,
}) => {
  const show = useEditNotificationTemplateSideModal();

  useEffect(() => {
    register('notificationTemplateData');
    register('reminderTemplateData');
  });

  const onClickEditNotification = useCallback(async () => {
    const templateData = watch('notificationTemplateData');
    const result = await show({
      message: templateData.content,
      mentionsOptions: LearningAssignmentNotificationMentionOptions,
    });
    if (result) {
      setValue('notificationTemplateData', {
        ...templateData,
        content: result.message,
      });
    }
  }, []);
  const onClickEditReminder = useCallback(async () => {
    const templateData = watch('reminderTemplateData');
    const result = await show({
      message: templateData.content,
      mentionsOptions: LearningAssignmentNotificationMentionOptions,
    });
    if (result) {
      setValue('reminderTemplateData', {
        ...templateData,
        content: result.message,
      });
    }
  }, []);
  return (
    <>
      <Box margin={{ bottom: 's' }}>
        <Typography.h2>Notifications</Typography.h2>
      </Box>
      <Box margin={{ bottom: 'm' }}>
        <Typography.p type="muted">
          Learners will can receive different types of notifications throughout
          the lifetime of an assignment. You can enable or disable them and
          customize the message that will be sent.
        </Typography.p>
      </Box>
      <Box>
        <FormField.Root>
          <FormField.Label spacing="xs">
            Notify learners about new assignments
            <FormField.Input>
              <Controller
                render={({ field }) => (
                  <ToggleInput
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange({
                        target: { value: e, name: field.name },
                      });
                    }}
                    value={field.value}
                    defaultChecked={field.value}
                    mode="toggle"
                  />
                )}
                control={control}
                name={'isNotifyLearnersEnabled'}
              />
            </FormField.Input>
          </FormField.Label>
          <FormField.Description>
            Each time a learning session is created for a learner an email will
            be sent to them
          </FormField.Description>
          {watch('isNotifyLearnersEnabled') ? (
            <Box margin={{ top: 's' }}>
              <Button
                onClick={onClickEditNotification}
                regular
                small
                label="Edit Template"
              />
            </Box>
          ) : null}
        </FormField.Root>
      </Box>
      <Box>
        <FormField.Root>
          <FormField.Label spacing="xs">
            Remind learners of pending assignments
            <FormField.Input>
              <Controller
                render={({ field }) => (
                  <ToggleInput
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange({
                        target: { value: e, name: field.name },
                      });
                    }}
                    value={field.value}
                    defaultChecked={field.value}
                    mode="toggle"
                  />
                )}
                control={control}
                name={'isRemindLearnersEnabled'}
              />
            </FormField.Input>
          </FormField.Label>
          <FormField.Description>
            Learners that did not finish an assignment will receive a
            notification at a specific amount of days before the due date
          </FormField.Description>
          {watch('isRemindLearnersEnabled') ? (
            <>
              <Box margin={{ top: 's' }}>
                <Button
                  onClick={onClickEditReminder}
                  regular
                  small
                  label="Edit Template"
                />
              </Box>
              <Box margin={{ top: 's' }}>
                <FormField required label="Reminder Offset">
                  <Input
                    type="number"
                    {...register('reminderOffset')}
                    placeholder="Number of days before the due date when the reminder should be sent"
                  />
                </FormField>
              </Box>
            </>
          ) : null}
        </FormField.Root>
      </Box>
    </>
  );
};

const LearningUnitSelect = ({
  contentType,
  contentName,
  contentId,
  control,
  error,
  disabled,
}) => {
  if (!contentType) {
    return (
      <FormField
        label="Learning Item"
        required
        disabled
        errorMessage={error?.message}
      >
        <Controller
          name="contentId"
          control={control}
          render={({ field }) => (
            <SingleSelect
              {...field}
              name="model-select"
              onChange={() => {
                return;
              }}
              options={[]}
              placeholder="Search for a specific learning unit"
              disabled
            />
          )}
        />
      </FormField>
    );
  }

  const SelectComponent = ContentTypeToSelectRecord[contentType];
  if (SelectComponent) {
    return (
      <FormField label="Learning Unit" required errorMessage={error?.message}>
        <Controller
          name="contentId"
          control={control}
          render={({ field }) => (
            <SelectComponent
              {...field}
              returnEntireItemOnChange={false}
              initialSelectedItem={{ value: contentId, label: contentName }}
              disabled={disabled}
            />
          )}
        />
      </FormField>
    );
  }
  return null;
};
