import {
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { SurveyRouterContract } from './survey.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'surveyStore';

  const tsRest = buildTsRestInstance<typeof SurveyRouterContract>(
    apiInstance,
    SurveyRouterContract,
    apiOptions,
  );

  const rtk = buildRtkInstance(tsRest, SurveyRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
