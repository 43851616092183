import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Draggable,
  Icon,
  IconButton,
  Select,
  ToggleButtonGroup,
  Typography,
} from '@nl-lms/ui/components';

import './AdminAnalyticsReportSortInput.scss';

export const AdminAnalyticsReportSortInput = ({
  value,
  onChange,
  columns,
}: {
  columns: { name: string; label: string }[];
  value: [string, 'asc' | 'desc'][];
  onChange: (sorting: [string, 'asc' | 'desc'][]) => void;
}) => {
  const onChangeSortDirection = useCallback(
    (direction: 'asc' | 'desc', name: string) => {
      const newValue = value.map((sorting) => {
        if (sorting[0] === name) {
          return [name, direction] as [string, 'asc' | 'desc'];
        }

        return sorting;
      });

      onChange(newValue);
    },
    [value, onChange],
  );

  const onMoveColumn = useCallback(
    (from: number, to: number) => {
      const copiedSorting = [...value];
      const [movedSorting] = copiedSorting.splice(from, 1);
      copiedSorting.splice(to, 0, movedSorting);
      onChange(copiedSorting);
    },
    [value, onChange],
  );

  const onRemoveColumn = useCallback(
    (name: string) => {
      onChange(value.filter((v) => v[0] !== name));
    },
    [value, onChange],
  );

  const availableColumnsForSorting = useMemo(() => {
    return columns.filter(
      (col) => col && !value.find((v) => v[0] === col.name),
    );
  }, [columns, value]);

  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const onSelectAddColumn = useCallback(
    (e) => {
      setSelectedColumn(e.value);
    },
    [availableColumnsForSorting],
  );

  const onAddColumn = useCallback(() => {
    if (!selectedColumn) return;

    onChange([...value, [selectedColumn, 'asc']]);

    setSelectedColumn(null);
  }, [onChange, selectedColumn, value]);

  if (!availableColumnsForSorting.length) {
    return (
      <Typography.p>
        You cannot sort by any column this type of report
      </Typography.p>
    );
  }

  return (
    <Box flex={{ flexDirection: 'column' }} className="sort-input">
      {value.map((sorting, index) => (
        <SortInput
          key={`sort-input-${sorting[0]}-${index}`}
          column={columns.find((c) => c.name === sorting[0])!}
          index={index}
          value={sorting}
          onMoveColumn={onMoveColumn}
          onChangeSortDirection={onChangeSortDirection}
          onRemoveColumn={onRemoveColumn}
        />
      ))}

      {!availableColumnsForSorting.length ? null : (
        <Box flex={{ flexDirection: 'row', gap: 's' }}>
          <Select
            name="add-sort-column"
            options={availableColumnsForSorting.map((col) => ({
              label: col.label,
              value: col.name,
            }))}
            shouldClearInput={!selectedColumn}
            onChange={onSelectAddColumn}
          />
          <Button
            label="Add Sorting"
            regular
            onClick={onAddColumn}
            icon={<Icon.AddIcon />}
          />
        </Box>
      )}
    </Box>
  );
};

const SortInput = ({
  column,
  value,
  index,
  onRemoveColumn,
  onMoveColumn,
  onChangeSortDirection,
}: {
  column: { name: string; label: string };
  value: [string, 'asc' | 'desc'];
  index: number;
  onRemoveColumn: (name: string) => void;
  onMoveColumn: (from: number, to: number) => void;
  onChangeSortDirection: (direction: 'asc' | 'desc', name: string) => void;
}) => {
  const name = useMemo(() => `${column.name}#${index}`, [column, index]);

  return (
    <Draggable type="sort-column" index={index} onDrop={onMoveColumn}>
      <Box
        flex={{
          flexDirection: 'row',
          gap: 's',
          alignItems: 'center',
        }}
        padding={{ top: 's', bottom: 's' }}
      >
        <Box
          flex={{
            flexDirection: 'row',
            gap: 's',
            justifyContent: 'space-between',
            flex: 1,
          }}
          className="sort-input-column-row"
        >
          <Typography.p className="sort-input-column-label">
            {column.label}
          </Typography.p>
          <ToggleButtonGroup
            className="sort-input-toggle-button-group"
            options={[
              { label: 'Ascending', value: 'asc' },
              { label: 'Descending', value: 'desc' },
            ]}
            onChange={onChangeSortDirection}
            initialSelectedOption={value[1]}
            name={name}
          />
        </Box>

        <Draggable.HoverActions>
          <Draggable.DragButton />
          <IconButton label={'Remove'} onClick={() => onRemoveColumn(name)}>
            <Icon.DeleteIcon />
          </IconButton>
        </Draggable.HoverActions>
      </Box>
    </Draggable>
  );
};
