import { AxiosError } from 'axios';

import { ApiClientOptions, ApiError, SdkError } from '../types';

export const errorHandlerInterceptor =
  (options: ApiClientOptions) =>
  (error: AxiosError): Promise<SdkError> => {
    console.error(error);

    let message =
      'An unknown error occurred. Please contact your administrator.';
    let name = 'UNKNOWN_ERROR';
    let code = '10004';

    const responseData = (error.response?.data as { error: ApiError })?.error;
    if (responseData) {
      message = responseData.message;
      name = responseData.name;
      code = responseData.code + '';
    }

    const sdkError = new SdkError(name, message, code, error);

    if (options && options.errorHandler) options.errorHandler(sdkError);

    return Promise.reject(sdkError);
  };
