export {
  LiveSessionStatus,
  LiveCourseType,
  LiveSessionLearnerStatus,
} from '@nl-lms/common/feature/types';

export const PortalLiveLearningRouterPrefix = '/portal/live-learning';
export const LiveLearningRouterPrefix = '/live-learning';

export const LiveLearningScope = {
  portalAccess: { resource: 'learner', action: 'app' },
  portal: {
    viewLiveCourse: {
      resource: 'portal_live_course',
      action: 'view',
    },
    viewLiveSessionLearner: {
      resource: 'portal_live_session_learner',
      action: 'view',
    },
  },
  viewLiveSessionLearner: {
    resource: 'live_session_learner',
    action: 'view',
  },
} as const;

export const LiveSessionLearnerNotificationAudienceName =
  'live-session-learner-audience';
export const LiveSessionFeedbackFormNotificationTemplateName =
  'LiveSessionFeedbackFormNotificationTemplate';
