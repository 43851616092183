import React, { useCallback, useState } from 'react';

import { ReportTemplate } from '@nl-lms/feature/reports/sdk';
import {
  Button,
  FormField,
  Icon,
  JsonEditor,
  Modal,
  SideModal,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { reportsApi } from '../../../../_common/services/api';

const { useUpdateReportTemplateColumnsMutation } = reportsApi;

export const AdminAnalyticsEditReportTemplateColumnsSideModal = ({
  reportTemplate,
  children,
}) => {
  return (
    <Modal.Provider>
      <Modal.Trigger>{children}</Modal.Trigger>
      <SideModal.Content>
        <AdminAnalyticsUpsertReportTemplateSideModalForm
          reportTemplate={reportTemplate}
        />
      </SideModal.Content>
    </Modal.Provider>
  );
};

type AdminAnalyticsUpsertReportTemplateColumnsSideModalFormProps = {
  reportTemplate: ReportTemplate;
};

export const AdminAnalyticsUpsertReportTemplateSideModalForm = (
  props: AdminAnalyticsUpsertReportTemplateColumnsSideModalFormProps,
) => {
  const { hide } = useModal();
  const reportTemplateId =
    'reportTemplate' in props ? props.reportTemplate.id : null;

  const [columns, setColumns] = useState(
    'reportTemplate' in props ? props.reportTemplate.columns : [],
  );
  const [
    updateReportTemplateColumns,
    { isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateReportTemplateColumnsMutation();
  const updateReportTemplateColumnsAction = useAction(
    updateReportTemplateColumns,
  );

  const onSubmit = useCallback(async () => {
    const response = await updateReportTemplateColumnsAction({
      // @ts-ignore
      reportTemplateId,
      columns,
    });

    if (response && 'data' in response) hide();
  }, [columns]);

  return (
    <>
      <SideModal.Header>
        <SideModal.Title>Edit Report Template Columns</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <FormField label="Columns">
          <JsonEditor
            initialValue={columns}
            // @ts-ignore
            onChange={(val) => setColumns(val)}
          />
        </FormField>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(updateError)}
        </SideModal.Error>
        <Button
          label="Update"
          onClick={onSubmit}
          isLoading={isUpdateLoading}
          icon={<Icon.FileIcon />}
        />
      </SideModal.Actions>
    </>
  );
};
