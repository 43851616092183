import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import store from 'store';

import { TimeDelta } from '@nl-lms/common/shared';
import { Dashboard, Widget } from '@nl-lms/feature/dashboard/sdk';
import { dateFns } from '@nl-lms/vendor';

import { dashboardApi } from '../../../_common/services/api';

const {
  useListDashboardsQuery,
  useCreateDashboardMutation,
  useDeleteDashboardMutation,
  useUpdateDashboardMutation,
} = dashboardApi;

type CreateDashboardArg = {
  label: string;
  targetLearnerGroupIds: string[];
};

export type IntervalScheduleDate =
  | {
      type: 'relative';
      value: TimeDelta;
    }
  | {
      type: 'fixed';
      value: { start: Date; end: Date };
    };

type DashboardContextType = {
  activeDashboard: Dashboard | null;
  availableDashboards: Dashboard[];
  isEditingLayout: boolean;
  isFetchingDashboards: boolean;
  // TODO: This should be Api/AppQueryFilter in order to make it more generic
  // and to allow for other types of filters. To do this we will have to update
  // the query functions to accept relative date expressions
  dateFilter: IntervalScheduleDate | null;
  onChangeDateFilter: (dateFilter: IntervalScheduleDate | null) => void;
  onSelectDashboard: (dashboardId: string | null) => void;
  onCreateDashboard: (arg: CreateDashboardArg) => Promise<void>;
  onUpdateDashboard: (arg?: CreateDashboardArg) => void;
  onChangeWidgetPositions: (widgetPositions: Widget['position'][]) => void;
  onUpdateWidgets: (widgets: Widget[]) => void;
  onRemoveWidget: (widgetIndex: number) => void;
  onAddWidget: (widget: Widget) => void;
  onCancelEditing: () => void;
  onUpdateWidget: (widgetLabel: string, widget: Widget) => void;
  onRemoveDashboard: () => void;
  onClickEditLayout: () => void;
};

export const DashboardContext = createContext<DashboardContextType>(
  {} as DashboardContextType,
);

type DashboardContextState = {
  activeDashboard: Dashboard | null;
  draftDashboard: Dashboard | null;
  isEditingLayout: boolean;
  dateFilter: IntervalScheduleDate | null;
  // Flag used to signal if an update should be made or not
  // This way we don't have to always have to have the latest
  // state in callbacks and just rely on the reducer state
  // keeping things cleaner
  performUpdate: boolean;
};

type DashboardContextAction =
  | { type: 'change-active-dashboard'; payload: Dashboard | null }
  | { type: 'add-draft'; payload: Dashboard }
  | { type: 'submit-draft' }
  | { type: 'submit-draft'; payload: CreateDashboardArg }
  | { type: 'save-draft' }
  | { type: 'discard-draft' }
  | { type: 'cancel-editing' }
  | { type: 'start-edit' }
  | { type: 'change-widget-positions'; payload: Widget['position'][] }
  | { type: 'add-widget'; payload: Widget }
  | { type: 'update-widgets'; payload: Widget[] }
  | { type: 'update-widget'; payload: { widget: Widget; widgetLabel: string } }
  | { type: 'remove-widget'; payload: number }
  | { type: 'update-filters'; payload: IntervalScheduleDate | null };

const LOCAL_STORAGE_KEY = 'admin-dashboard';

export const DashboardContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer<
    React.Reducer<DashboardContextState, DashboardContextAction>
  >(dashboardContextReducer, {
    activeDashboard: store.get(LOCAL_STORAGE_KEY) || null,
    draftDashboard: null,
    dateFilter: {
      type: 'fixed',
      value: {
        start: dateFns.startOfYear(new Date()),
        end: dateFns.endOfYear(new Date()),
      },
    },
    isEditingLayout: false,
    performUpdate: false,
  });

  const {
    isEditingLayout,
    dateFilter,
    performUpdate,
    activeDashboard,
    draftDashboard,
  } = state;

  const updatedActiveDashboardFromServerData = useRef(false);
  const { data, isLoading } = useListDashboardsQuery({});

  const availableDashboards = useMemo(() => data || [], [data]);

  const dashboard = useMemo(() => {
    if (draftDashboard) return draftDashboard;
    return activeDashboard;
  }, [availableDashboards, draftDashboard, activeDashboard]);

  const [updateDashboard] = useUpdateDashboardMutation();
  const [createDashboard] = useCreateDashboardMutation();
  const [removeDashboard] = useDeleteDashboardMutation();

  const remoteActiveDashboard = useMemo(() => {
    if (!data) return null;
    if (!activeDashboard && data.length) return data[0];

    return data.find((d) => d.id === activeDashboard?.id) || null;
  }, [data, activeDashboard]);

  useEffect(() => {
    if (!remoteActiveDashboard) return;

    if (!activeDashboard) {
      dispatch({
        type: 'change-active-dashboard',
        payload: remoteActiveDashboard,
      });
    } else if (
      new Date(activeDashboard.updatedAt).getTime() <
      new Date(remoteActiveDashboard.createdAt).getTime()
    ) {
      dispatch({
        type: 'change-active-dashboard',
        payload: remoteActiveDashboard,
      });
    }
  }, [remoteActiveDashboard, activeDashboard]);

  useEffect(() => {
    store.set(LOCAL_STORAGE_KEY, activeDashboard);
  }, [activeDashboard]);

  useEffect(() => {
    // the db is the final source of truth here
    // if something changed there a page refresh should reflect the new state
    if (!activeDashboard) return;
    if (!data?.length) return;
    if (updatedActiveDashboardFromServerData.current) return;

    const latestActiveDashboard =
      data.find((w) => w.id === activeDashboard.id) || null;
    dispatch({
      type: 'change-active-dashboard',
      payload: latestActiveDashboard,
    });

    updatedActiveDashboardFromServerData.current = true;
  }, [data]);

  const saveDraft = useCallback(async () => {
    if (!draftDashboard) return;

    const result = await updateDashboard({
      ...draftDashboard,
      dashboardId: draftDashboard.id,
    });
    if ('error' in result) {
      dispatch({ type: 'discard-draft' });
    }

    dispatch({ type: 'save-draft' });
  }, [draftDashboard]);

  useEffect(() => {
    if (!performUpdate) return;

    saveDraft();
  }, [performUpdate]);

  const onRemoveWidget = useCallback(async (widgetIndex) => {
    dispatch({ type: 'remove-widget', payload: widgetIndex });
  }, []);

  const onSelectDashboard = useCallback(
    (dashboardId: string | null) => {
      const selectedDashboard =
        availableDashboards.find((d) => d.id === dashboardId) || null;
      dispatch({ type: 'change-active-dashboard', payload: selectedDashboard });
    },
    [availableDashboards],
  );

  const onClickEditLayout = useCallback(() => {
    dispatch({ type: 'start-edit' });
  }, []);

  const onCreateDashboard = useCallback(async (dashboard) => {
    const result = await createDashboard({
      label: dashboard.label,
      targetLearnerGroupIds: dashboard.targetLearnerGroupIds,
      widgets: [],
    });
    if ('data' in result) {
      dispatch({
        type: 'change-active-dashboard',
        payload: result.data as Dashboard,
      });
    }
  }, []);

  const onUpdateDashboard = useCallback(
    async (updatePayload?: CreateDashboardArg) => {
      dispatch({ type: 'submit-draft', payload: updatePayload });
    },
    [],
  );

  const onChangeWidgetPositions = useCallback(
    (widgetPositions: Widget['position'][]) => {
      dispatch({ type: 'change-widget-positions', payload: widgetPositions });
    },
    [],
  );

  const onAddWidget = useCallback((widget: Widget) => {
    dispatch({ type: 'add-widget', payload: widget });
  }, []);

  const onUpdateWidgets = useCallback((widgets: Widget[]) => {
    dispatch({ type: 'update-widgets', payload: widgets });
  }, []);

  const onUpdateWidget = useCallback((oldName: string, widget: Widget) => {
    dispatch({
      type: 'update-widget',
      payload: { widget, widgetLabel: oldName },
    });
  }, []);

  const onRemoveDashboard = useCallback(async () => {
    if (!activeDashboard) return;
    const result = await removeDashboard({ dashboardId: activeDashboard.id });
    if ('data' in result) {
      dispatch({ type: 'change-active-dashboard', payload: null });
    }
  }, [activeDashboard]);

  const onCancelEditing = useCallback(async () => {
    dispatch({ type: 'cancel-editing' });
  }, []);

  const onChangeDateFilter = useCallback(
    (dateFilter: IntervalScheduleDate | null) => {
      dispatch({ type: 'update-filters', payload: dateFilter });
    },
    [],
  );

  return (
    <DashboardContext.Provider
      value={{
        activeDashboard: dashboard,
        availableDashboards,
        isEditingLayout,
        isFetchingDashboards: isLoading,
        dateFilter,
        onChangeDateFilter,
        onSelectDashboard,
        onCreateDashboard,
        onCancelEditing,
        onUpdateDashboard,
        onChangeWidgetPositions,
        onRemoveWidget,
        onAddWidget,
        onUpdateWidgets,
        onRemoveDashboard,
        onUpdateWidget,
        onClickEditLayout,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

const dashboardContextReducer = (
  state: DashboardContextState,
  action: DashboardContextAction,
) => {
  const currentDraft = state.draftDashboard || state.activeDashboard;
  switch (action.type) {
    case 'change-active-dashboard':
      return {
        ...state,
        activeDashboard: action.payload,
      };
    case 'add-draft':
      return {
        ...state,
        draftDashboard: action.payload,
      };
    case 'submit-draft':
      // TODO: Validate draft here
      if (!currentDraft) return state;
      else {
        const newDraft = { ...currentDraft };
        if ('payload' in action && action.payload) {
          newDraft.label = action.payload.label;
          newDraft.targetLearnerGroupIds = action.payload.targetLearnerGroupIds;
        }
        return {
          ...state,
          draftDashboard: newDraft,
          isEditingLayout: false,
          performUpdate: true,
        };
      }
    case 'save-draft':
      return {
        ...state,
        activeDashboard: state.draftDashboard,
        draftDashboard: null,
        performUpdate: false,
      };
    case 'cancel-editing':
      if (!state.isEditingLayout) return state;
      return {
        ...state,
        draftDashboard: null,
        performUpdate: false,
        isEditingLayout: false,
      };
    case 'discard-draft':
      return {
        ...state,
        draftDashboard: null,
        performUpdate: false,
      };
    case 'start-edit':
      return {
        ...state,
        draftDashboard: state.activeDashboard,
        isEditingLayout: true,
      };
    case 'change-widget-positions':
      if (!currentDraft || !state.isEditingLayout) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: currentDraft.widgets.map((widget, index) => ({
            ...widget,
            position: action.payload[index],
          })),
        },
      };
    case 'add-widget':
      if (!currentDraft) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: [...currentDraft.widgets, action.payload],
        },
        performUpdate: true,
      };
    case 'update-widgets':
      if (!currentDraft) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: action.payload,
        },
        performUpdate: true,
      };
    case 'update-widget':
      if (!currentDraft) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: currentDraft.widgets.map((widget) => {
            if (widget.label === action.payload.widgetLabel) {
              return action.payload.widget;
            }
            return widget;
          }),
        },
        performUpdate: true,
      };
    case 'remove-widget':
      if (!currentDraft) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: currentDraft.widgets.filter(
            (widget, index) => action.payload !== index,
          ),
        },
        performUpdate: true,
      };
    case 'update-filters':
      return {
        ...state,
        dateFilter: action.payload,
      };
  }
};
