import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Subscription } from '@nl-lms/feature/subscriptions/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { subscriptionsApi } from '../../../_common/services/api';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';

type Props = {
  subscription?: Partial<
    Subscription & {
      vendor: { name: string; id: string };
      tags: { title: string; scope: string; id: string };
    }
  >;
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  url: yup.string().required(),
  tagIds: yup.array().of(yup.string()),
  vendorId: yup.string().required(),
});

export const AdminSubscriptionEditFormSideModal = ({
  subscription = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...subscription,
    },
  });
  const { hide, show } = useModal();
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateSubscriptionMutation',
    createHookName: 'useCreateSubscriptionMutation',
    entityId: subscription.id,
    parseEntity: (payload) => ({
      ..._.omit(payload, ['vendor', 'tags']),
      subscriptionId: subscription.id,
    }),
    api: subscriptionsApi,
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {subscription.id ? 'Edit Subscription' : 'Create New Subscription'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Subscription Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Name"
              helpText="The name that we will use throughout the entire app in various forms, reports and views"
              helpTextOffset={60}
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="URL"
              helpText="The URL for the subscription page"
              helpTextOffset={30}
              errorMessage={errors?.url?.message}
            >
              <Input
                {...register('url')}
                required
                hasError={!!errors?.url?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Vendor">
              <Controller
                name="vendorId"
                control={control}
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    isClearable
                    hasError={!!errors?.vendorId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Tags">
              <Controller
                name="tagIds"
                control={control}
                render={({ field }) => (
                  <AdminTagSelect {...field} selectedItems={field.value} />
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <Button
          label={subscription.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
