import { snakeCase } from 'lodash';

import {
  ApiQuery,
  ApiQueryFilter,
  AppQuery,
  AppQueryFilter,
  QuerySorting,
} from '../constants';

export const transformFilter = (_filter: AppQueryFilter): ApiQueryFilter => {
  const walkFilter = (filter) => {
    if (!filter) return;
    if (Array.isArray(filter.value)) {
      const value = filter.value.map(walkFilter).filter((v) => v);

      const result: any = { value };
      if (filter.combinator) result.combinator = filter.combinator;

      return result;
    } else {
      const field = snakeCase(filter.value.field.field);
      const operator = filter.value.operator.value;
      const value = filter.value.value ? filter.value.value.value : undefined;
      const result: any = { value: { field, operator, value } };
      if (filter.combinator) result.combinator = filter.combinator;

      return result;
    }
  };

  return walkFilter(_filter);
};

export const transformSort = (sorting: QuerySorting): QuerySorting => {
  if (!Array.isArray(sorting)) return [];
  return sorting.map(([field, order]) => [snakeCase(field), order]);
};

export const transformQuery = ({
  filters,
  sorting,
  pagination,
}: AppQuery): ApiQuery => {
  const query: ApiQuery = {};

  if (filters) query.filters = transformFilter(filters);
  if (sorting) query.sorting = transformSort(sorting);
  if (pagination) query.pagination = pagination;

  return query;
};
