import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { DashboardSchema } from '@nl-lms/common/feature/types';
import {
  BooleanResponseSchema,
  ContractStandardResponse,
  EmptyObjectSchema,
} from '@nl-lms/common/sdk';

import { DashboardRouterPrefix } from './dashboard.constants.sdk';
import {
  CreateDashboardSchema,
  ListDashboardsSchema,
} from './dashboard.schema.sdk';

const contract = initContract();

export const DashboardRouterContract = contract.router({
  listDashboards: {
    method: 'GET',
    path: `${DashboardRouterPrefix}/`,
    responses: ContractStandardResponse(ListDashboardsSchema),
  },
  createDashboard: {
    method: 'POST',
    path: `${DashboardRouterPrefix}/`,
    responses: ContractStandardResponse(DashboardSchema),
    body: CreateDashboardSchema,
  },
  updateDashboard: {
    method: 'PATCH',
    path: `${DashboardRouterPrefix}/:dashboardId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: CreateDashboardSchema,
    pathParams: z.object({ dashboardId: z.string() }),
  },
  deleteDashboard: {
    method: 'DELETE',
    path: `${DashboardRouterPrefix}/:dashboardId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: EmptyObjectSchema,
    pathParams: z.object({ dashboardId: z.string() }),
  },
});
