import { AppQuery, transformQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  ApiServicePathF0,
  ApiServicePathF1,
  ApiServicePathF2,
  ApiServicePaths,
  FetchListResponse,
  IApiService,
  LearnerRegistrationResult,
  ListLiveSessionsResponse,
  LiveSession,
  LiveSessionLearner,
  LiveSessionResourceListArg,
  Trainer,
  Venue,
} from './types';

export class Session extends ApiService implements IApiService<'session'> {
  public paths: ApiServicePaths<{
    get: ApiServicePathF1;
    list: ApiServicePathF0;
    listLearners: ApiServicePathF0;
    listSessionLearners: ApiServicePathF1;
    create: ApiServicePathF0;
    update: ApiServicePathF1;
    updateStatus: ApiServicePathF1;
    createCosts: ApiServicePathF1;
    removeCosts: ApiServicePathF1;
    addLearners: ApiServicePathF1;
    copyLearners: ApiServicePathF0;
    removeLearners: ApiServicePathF0;
    listForSelect: ApiServicePathF0;
    updateLearnerStatus: ApiServicePathF0;
    remove: ApiServicePathF1;
    addableLearners: ApiServicePathF1;
    listCourses: ApiServicePathF0;
    listVenues: ApiServicePathF0;
    listTrainers: ApiServicePathF0;
    listCostTypes: ApiServicePathF0;
    addTask: ApiServicePathF1;
    removeTask: ApiServicePathF2;
    confirmTask: ApiServicePathF2;
    confirmTrainer: ApiServicePathF0;

    exportSessionAttendanceForTrainer: ApiServicePathF0;
    declineTrainer: ApiServicePathF0;
    confirmVenue: ApiServicePathF0;
    declineVenue: ApiServicePathF0;
    declineWaitingLearner: ApiServicePathF0;
    confirmWaitingLearner: ApiServicePathF0;
    validateExternalLearner: ApiServicePathF1;
    registerExternalLearners: ApiServicePathF1;
    registerExternalLearnerWithGDPR: ApiServicePathF1;
    listSessionTasks: ApiServicePathF1;
    listSessionCosts: ApiServicePathF1;
    listSessionGeneralStats: ApiServicePathF1;
    listSessionLearnerStats: ApiServicePathF1;
    setSessionFeedbackForm: ApiServicePathF1;
    deleteTrainer: ApiServicePathF2;
    updateTrainerStatus: ApiServicePathF2;
    changeTrainer: ApiServicePathF2;
    addTrainer: ApiServicePathF1;
    confirmApproval: ApiServicePathF1;
    declineApproval: ApiServicePathF1;
    getSessionMarkAttendanceData: ApiServicePathF0;
    markLearnersAttendance: ApiServicePathF0;
    addGoShows: ApiServicePathF0;
    deleteVenue: ApiServicePathF1;
    updateVenueStatus: ApiServicePathF1;
    getVenue: ApiServicePathF1;
    upsertVenue: ApiServicePathF1;
    fetchAutomatedActions: ApiServicePathF1;
    enableAutomatedAction: ApiServicePathF2;
    disableAutomatedAction: ApiServicePathF2;
    enableAutomation: ApiServicePathF1;
    disableAutomation: ApiServicePathF1;

    confirmLearnerApprovals: ApiServicePathF0;
    declineLearnerApprovals: ApiServicePathF0;
    downloadExportedSessionLearners: ApiServicePathF0;
    downloadSessionAttendanceSheet: ApiServicePathF1;
    getSessionEmails: ApiServicePathF1;
    upsertSurveyFormAnswers: ApiServicePathF2;
  }>;
  public serviceBase = '/session';

  constructor(props) {
    super(props);

    this.paths = {
      get: (id) => `${this.serviceBase}/${id}`,
      list: () => `${this.serviceBase}/list`,
      listLearners: () => `${this.serviceBase}/learner`,
      listSessionLearners: (id) => `${this.serviceBase}/${id}/learner`,
      create: () => `${this.serviceBase}/create`,
      update: (id) => `${this.serviceBase}/${id}`,
      updateStatus: (id) => `${this.serviceBase}/${id}/status`,
      createCosts: (id) => `${this.serviceBase}/${id}/cost`,
      removeCosts: (id) => `${this.serviceBase}/${id}/cost`,
      addLearners: (id) => `${this.serviceBase}/${id}/learner`,
      copyLearners: () => `${this.serviceBase}/learner/copy`,
      removeLearners: () => `${this.serviceBase}/learner`,
      listForSelect: () => `/list/session`,
      updateLearnerStatus: () => `${this.serviceBase}/learner`,
      confirmLearnerApprovals: () => `${this.serviceBase}/learner/confirm`,
      declineLearnerApprovals: () => `${this.serviceBase}/learner/decline`,
      remove: (id) => `${this.serviceBase}/${id}`,
      addableLearners: (id) => `${this.serviceBase}/${id}/learner/addable`,
      listCourses: () => `/list/session/courses`,
      listVenues: () => `/list/session/venues`,
      listTrainers: () => `/list/session/trainers`,
      listCostTypes: () => `/list/session/cost_types`,
      addTask: (id) => `${this.serviceBase}/${id}/task`,
      removeTask: (id, taskId) => `${this.serviceBase}/${id}/task/${taskId}`,
      confirmTask: (id, taskId) =>
        `${this.serviceBase}/${id}/task/${taskId}/confirm`,
      confirmTrainer: () => `/external/trainer/confirm`,
      declineTrainer: () => `/external/trainer/decline`,
      confirmVenue: () => `/external/room/confirm`,
      declineVenue: () => `/external/room/decline`,
      declineWaitingLearner: () => `/external/waiting_learner/decline`,
      confirmWaitingLearner: () => `/external/waiting_learner/confirm`,
      validateExternalLearner: (id) => `/external/session/${id}/register`,
      registerExternalLearners: (id) =>
        `/external/session/${id}/consent_register`,
      registerExternalLearnerWithGDPR: (id) =>
        `/external/session/${id}/register`,
      listSessionTasks: (id) => `${this.serviceBase}/${id}/task`,
      listSessionCosts: (id) => `${this.serviceBase}/${id}/costs`,
      listSessionGeneralStats: (id) =>
        `${this.serviceBase}/${id}/stats/general`,
      listSessionLearnerStats: (id) =>
        `${this.serviceBase}/${id}/stats/learner`,
      exportSessionAttendanceForTrainer: () =>
        `/external/session/attendance/export`,
      setSessionFeedbackForm: (id) => `${this.serviceBase}/${id}`,
      deleteTrainer: (id, sessionTrainerId) =>
        `${this.serviceBase}/${id}/trainer/${sessionTrainerId}`,
      addTrainer: (id) => `${this.serviceBase}/${id}/trainer`,
      updateTrainerStatus: (id, sessionTrainerId) =>
        `${this.serviceBase}/${id}/trainer/${sessionTrainerId}/status`,
      changeTrainer: (id, sessionTrainerId) =>
        `${this.serviceBase}/${id}/trainer/${sessionTrainerId}`,
      getSessionMarkAttendanceData: () => `/external/session/attendance`,
      markLearnersAttendance: () => `/external/session/attendance`,
      addGoShows: () => `/external/session/attendance/go_show`,
      deleteVenue: (id) => `${this.serviceBase}/${id}/venue`,
      updateVenueStatus: (id) => `${this.serviceBase}/${id}/venue/status`,
      getVenue: (id) => `${this.serviceBase}/${id}/venue`,
      upsertVenue: (id) => `${this.serviceBase}/${id}/venue`,
      fetchAutomatedActions: (id) =>
        `${this.serviceBase}/${id}/automation/actions`,
      enableAutomatedAction: (id, actionId) =>
        `${this.serviceBase}/${id}/automation/actions/${actionId}/enable`,
      disableAutomatedAction: (id, actionId) =>
        `${this.serviceBase}/${id}/automation/actions/${actionId}/disable`,
      enableAutomation: (id) => `${this.serviceBase}/${id}/automation/enable`,
      disableAutomation: (id) => `${this.serviceBase}/${id}/automation/disable`,
      confirmApproval: (token) => `/external/approval/confirm?tk=${token}`,
      declineApproval: (token) => `/external/approval/decline?tk=${token}`,
      downloadExportedSessionLearners: () =>
        `${this.serviceBase}/export/learners`,
      downloadSessionAttendanceSheet: (id) =>
        `${this.serviceBase}/${id}/attendance_sheet`,
      getSessionEmails: (id) => `${this.serviceBase}/${id}/emails`,
      upsertSurveyFormAnswers: (id, surveyFormId) =>
        `${this.serviceBase}/${id}/survey/${surveyFormId}/answers`,
    };
  }

  get = (id: string) => this.api.get<LiveSession>(this.paths.get(id));

  list = (query: AppQuery): Promise<ListLiveSessionsResponse> =>
    this.api.get(this.paths.list(), { params: { query } });

  listLearners = (query: AppQuery) => {
    return this.api.post<null, FetchListResponse<LiveSessionLearner>>(
      this.paths.listLearners(),
      {
        query,
      },
    );
  };

  create = (entity) => this.api.post(this.paths.create(), snakelize(entity));

  update = (entity) =>
    this.api.patch(this.paths.update(entity.id), snakelize(entity));

  updateStatus = ({ id, status }) =>
    this.api.post(this.paths.updateStatus(id), { status });

  createCosts = ({ sessionId, data }) =>
    this.api.post(this.paths.createCosts(sessionId), snakelize({ list: data }));

  removeCosts = ({ ids, sessionId }) =>
    this.api.delete(this.paths.removeCosts(sessionId), {
      data: { list: ids },
    });

  exportSessionAttendanceForTrainer = ({ token, ids }) =>
    this.api.get<null, string>(this.paths.exportSessionAttendanceForTrainer(), {
      params: {
        tk: token,
        training_session_learner_ids: ids,
      },
    });

  addLearners = ({ liveSessionId, learnerIds, status }) =>
    this.api.post(this.paths.addLearners(liveSessionId), {
      list: learnerIds,
      status,
    });

  copyLearners = ({ sessionIds, status, list }) => {
    return this.api.post(this.paths.copyLearners(), {
      session_ids: sessionIds,
      list,
      status,
    });
  };

  removeLearners = (entityList) => {
    const payload: Record<string, unknown> = {};
    if (!Array.isArray(entityList)) {
      payload.filters = entityList;
    } else {
      payload.list = entityList;
    }
    return this.api.delete(this.paths.removeLearners(), {
      data: payload,
    });
  };

  listForSelect = (inputValue) =>
    this.api.get<null, LiveSession[]>(this.paths.listForSelect(), {
      params: { query: { search: inputValue } },
    });

  updateLearnerStatus = ({ status, list }) =>
    this.api.patch(this.paths.updateLearnerStatus(), {
      list,
      status,
    });

  remove = (entity) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete(this.paths.remove(id));
  };

  addableLearners = ({ id, query }) =>
    this.api.get<
      null,
      { id: string; firstName: string; lastName: string; email: string }[]
    >(this.paths.addableLearners(id), { params: query });

  listCourses = (trainerId = null, requirements = null) =>
    this.api.get(this.paths.listCourses(), {
      params: {
        query: {
          filters: {
            value: [
              {
                value: {
                  field: 'trainer_id',
                  operator: 'equals',
                  value: trainerId,
                },
              },
              {
                value: {
                  field: 'requirements',
                  operator: 'equals', // TODO use correct filter operator
                  value: requirements,
                },
              },
            ],
          },
        },
      },
    });

  listVenues = (query: AppQuery, trainingSessionId: string) =>
    this.api.get<null, Venue[]>(this.paths.listVenues(), {
      params: {
        query: { ...query, pagination: { disable: true } },
        training_session_id: trainingSessionId,
      },
    });

  listTrainers = ({ courseId, country, region, city, trainingSessionId }) => {
    const query: Record<string, string> = { courseId, trainingSessionId };
    if (city) {
      query.deliveryCountry = country;
      query.deliveryRegion = region;
      query.deliveryCity = city;
    }
    return this.api.get<null, Trainer[]>(this.paths.listTrainers(), {
      params: { query: snakelize(query) },
    });
  };

  listCostTypes = (resource_type = 'all') =>
    this.api.get(this.paths.listCostTypes(), {
      params: { resource_type },
    });

  confirmTrainer = (token) =>
    this.api.get<null, null>(this.paths.confirmTrainer(), {
      params: { tk: token },
    });

  declineTrainer = (token) =>
    this.api.get<null, null>(this.paths.declineTrainer(), {
      params: { tk: token },
    });

  confirmLearnerApprovals = (entityList) =>
    this.api.patch(this.paths.confirmLearnerApprovals(), {
      list: entityList,
    });

  declineLearnerApprovals = (entityList) =>
    this.api.patch(this.paths.declineLearnerApprovals(), {
      list: entityList,
    });

  confirmVenue = (token) =>
    this.api.get<null, null>(this.paths.confirmVenue(), {
      params: { tk: token },
    });

  declineVenue = (token) =>
    this.api.get<null, null>(this.paths.declineVenue(), {
      params: { tk: token },
    });

  declineWaitingLearner = (token) =>
    this.api.get<null, null>(this.paths.declineWaitingLearner(), {
      params: { tk: token },
    });

  confirmWaitingLearner = (token) =>
    this.api.get<null, null>(this.paths.confirmWaitingLearner(), {
      params: { tk: token },
    });

  registerExternalLearners = (sessionId, learners) => {
    let payload = snakelize({ data: learners });
    return this.api.post(
      this.paths.registerExternalLearners(sessionId),
      payload,
    );
  };

  listSessionTasks = (sessionId) =>
    this.api.get(this.paths.listSessionTasks(sessionId));

  listSessionCosts = ({ sessionId, query }) =>
    this.api.get(this.paths.listSessionCosts(sessionId), { params: { query } });

  deleteTrainer = ({ id, sessionTrainerId }) =>
    this.api.delete(this.paths.deleteTrainer(id, sessionTrainerId));

  addTrainer = ({ id, trainerId }) => {
    return this.api.post(
      this.paths.addTrainer(id),
      snakelize({ data: { trainerId } }),
    );
  };
  updateTrainerStatus = ({ id, sessionTrainerId, status }) => {
    return this.api.patch(
      this.paths.updateTrainerStatus(id, sessionTrainerId),
      { data: { status } },
    );
  };
  changeTrainer = ({ id, sessionTrainerId, resourceTrainerId }) => {
    return this.api.patch(
      this.paths.changeTrainer(id, sessionTrainerId),
      snakelize({ data: { resourceTrainerId } }),
    );
  };

  getSessionMarkAttendanceData = ({ token }) =>
    this.api.get<null, any>(this.paths.getSessionMarkAttendanceData(), {
      params: { tk: token },
    });
  // eslint-disable-next-line max-len

  markLearnersAttendance = ({ token, ids, status }) =>
    this.api.post(this.paths.markLearnersAttendance(), {
      token,
      training_session_learner_ids: ids,
      status,
    });

  addGoShows = ({ token, emails }) =>
    this.api.post(this.paths.addGoShows(), {
      token,
      emails,
    });

  deleteVenue = (id) => this.api.delete(this.paths.deleteVenue(id));

  updateVenueStatus = ({ id, status }) =>
    this.api.post(this.paths.updateVenueStatus(id), { status });

  getVenue = (id) => this.api.get(this.paths.getVenue(id));

  upsertVenue = ({ id, venueId }) => {
    let payload = snakelize({ data: { venueId } });
    return this.api.post(this.paths.upsertVenue(id), payload);
  };

  fetchAutomatedActions = (arg: LiveSessionResourceListArg) =>
    this.api.get(this.paths.fetchAutomatedActions(arg.sessionId));

  enableAutomatedAction = ({ id, actionId }) =>
    this.api.patch(this.paths.enableAutomatedAction(id, actionId));

  disableAutomatedAction = ({ id, actionId }) =>
    this.api.patch(this.paths.disableAutomatedAction(id, actionId));

  enableAutomation = ({ id }) =>
    this.api.patch(this.paths.enableAutomation(id));

  disableAutomation = ({ id }) =>
    this.api.patch(this.paths.disableAutomation(id));

  confirmApproval = (token) =>
    this.api.get<null, null>(this.paths.confirmApproval(token));

  declineApproval = (token) =>
    this.api.get<null, null>(this.paths.declineApproval(token));

  downloadSessionAttendanceSheet = ({ id }) =>
    this.api.get(this.paths.downloadSessionAttendanceSheet(id), {
      timeout: 30000,
    });

  getSessionEmails = (id) => this.api.get(this.paths.getSessionEmails(id));

  upsertSurveyFormAnswers = ({ id, surveyFormId, ...payload }) =>
    this.api.post(
      this.paths.upsertSurveyFormAnswers(id, surveyFormId),
      snakelize(payload),
    );
}
