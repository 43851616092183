import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useNotifications } from '@nl-lms/ui/modules';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { useGoToHome } from './useGoToHome';

type ApiError =
  | { error?: { message?: string } }
  | { status: 400; body: { message?: string } }
  | { status: number; body: unknown };

export const getApiErrorMessage = (payload: ApiError) => {
  const message =
    'error' in payload && payload?.error?.message
      ? payload.error.message
      : // @ts-ignore
        'status' in payload && payload.status == 400 && payload?.body?.message
        ? // @ts-ignore
          payload.body.message
        : 'An unknown error occurred. Please contact your administrator.';

  return message;
};

export const hasApiErrorMessage = (payload: ApiError) => {
  const hasError =
    ('error' in payload && payload.error?.message) || // @ts-ignore
    ('status' in payload && payload.status == 400 && payload?.body?.message);

  return hasError;
};

export const useApiErrorHandler = () => {
  const { addAlertNotification } = useNotifications();

  const handler = useCallback((payload: ApiError) => {
    const hasError = hasApiErrorMessage(payload);
    if (!hasError) return;

    const message = getApiErrorMessage(payload);
    const translatedMessage = getTranslatedMessageFromError(message);

    addAlertNotification(translatedMessage);

    return message;
  }, []);

  return handler;
};

export const useQueryErrorHandler = (
  payload: ApiError,
  { redirect = false } = {},
) => {
  const goToHome = useGoToHome();
  const handleError = useApiErrorHandler();

  const error = useMemo(() => getApiErrorMessage(payload), [payload]);

  useEffect(() => {
    if (!error) return;
    handleError(payload);
    if (redirect) goToHome();
  }, [error]);
};
