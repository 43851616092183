import { matchSorter } from 'match-sorter';
import React, {
  ChangeEvent,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { _ } from '@nl-lms/vendor';

import { useClassNameProps, useTestProps } from '../../hooks';
import * as Icon from '../Icon';
import './Select.scss';

export type SelectOption<Entity> = {
  value: string | number;
  label: string;
  entity?: Entity;
  disabled?: boolean;
  groupName?: string;
  Component?: React.FunctionComponent<{
    entity: Entity;
    isActive: boolean;
    label: string;
  }>;
};

export interface SelectProps<Entity> {
  // TidComponent
  tid?: string;
  options: SelectOption<Entity>[];
  name: string;
  onChange?: (option?: SelectOption<Entity>) => void;
  isClearable?: boolean;
  isMenuVisible?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  onCreateOption?: (option: SelectOption<Entity>) => Promise<void>;
  hasError?: boolean;
  value?: string | number;
  initialValue?: string | number;
  disabled?: boolean;
  onBlur?: () => void;
  className?: string;
  initialInputValue?: string;
  inputValue?: string;
  filterOptions?: boolean;
  onChangeInputValue?: (inputValue: string) => void;
  shouldClearInput?: boolean;
}

interface SelectContext<Entity> {
  inputValue: string;
  activeOptionId: string | number;
  isLoading: boolean;
  options: SelectOption<Entity>[];
  isMenuVisible: boolean;
  isClearable: boolean;
  hasError: boolean;
  placeholder: string;
  value: string | number;
  filterOptions: boolean;
  disabled: boolean;
  name: string;
  onChangeInputValue: (inputValue: string) => void;
  textInputRef: React.Ref<HTMLInputElement>;
  selectContainerRef: React.Ref<HTMLDivElement>;
  onInputKeyDown: (e: KeyboardEvent) => void;
  onSelectOption: (option: SelectOption<Entity>) => void;
  onChangeActiveOption: (option: SelectOption<Entity>) => void;
  onResetSelectState: () => void;
  onFocusInput: () => void;
  onBlurInput: () => void;
}

export const createSelectContext = _.once(<Entity,>() =>
  createContext({} as SelectContext<Entity>),
);

export const useSelectContext = <T,>() => useContext(createSelectContext<T>());

export const SelectProvider = forwardRef<
  HTMLInputElement,
  SelectProps<any> & { children: React.ReactNode }
>(({ children, ...rest }, ref) => {
  const {
    options,
    onChange,
    value: _value = undefined,
    inputValue: _inputValue = undefined,
    isMenuVisible: _isMenuVisible = undefined,
    placeholder = 'Select or type value to search',
    initialValue = null,
    hasError = false,
    isClearable = false,
    onChangeInputValue: _onChangeInputValue = null,
    isLoading = false,
    disabled = false,
    filterOptions = true,
    initialInputValue = '',
    name,
    onBlur = null,
    shouldClearInput,
  } = rest;
  const commonProps = useTestProps(rest);
  const classNameProps = useClassNameProps('select', rest);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  // @ts-ignore
  const [value, setValue] = useState<string | number>(initialValue);
  const [inputValue, setInputValue] = useState(() => {
    if (initialInputValue) return initialInputValue;
    if (initialValue) {
      return options.find((o) => o.value === initialValue)?.label;
    }
    if (value) {
      return options.find((o) => o.value === value)?.label;
    }
    return '';
  });
  const _textInputRef = useRef<HTMLInputElement>(null);
  const textInputRef = ref || _textInputRef;
  const selectContainerRef = useRef<HTMLDivElement>(null);
  // @ts-ignore
  const [activeOptionId, setActiveOptionId] = useState<string | number>(null);
  const onSelectOption = useCallback(
    (option?: SelectOption<any>) => {
      const newOptionValue = option?.value || null;
      const newInputValue = option?.label || '';
      // @ts-ignore
      setActiveOptionId(null);
      setIsMenuVisible(false);
      // @ts-ignore
      setValue(newOptionValue);
      setInputValue(newInputValue);
      if (onChange) onChange(option);
    },
    [onChange],
  );
  const onResetSelectState = useCallback(() => {
    // @ts-ignore
    onSelectOption(null);
  }, []);
  const SelectContext = createSelectContext();
  const onKeyDown = useCallback(
    (e) => {
      const activeOptionIndex = options.findIndex(
        (o) => o.value === activeOptionId,
      );
      const nextActiveOptionIndex =
        options.length !== activeOptionIndex + 1 ? activeOptionIndex + 1 : 0;
      const prevActiveOptionIndex =
        activeOptionIndex !== 0 ? activeOptionIndex - 1 : options.length - 1;
      if (isMenuVisible) {
        switch (e.keyCode) {
          // Escape
          case 27:
            setIsMenuVisible(false);
            break;
          // Down
          case 38:
            const prevActiveOptionItem = options[prevActiveOptionIndex];
            setActiveOptionId(prevActiveOptionItem?.value);
            break;
          // Up
          case 40:
            const nextActiveOptionItem = options[nextActiveOptionIndex];
            setActiveOptionId(nextActiveOptionItem?.value);
            break;
          // Enter
          case 13:
            const activeOption = options[activeOptionIndex];
            if (activeOption) onSelectOption(activeOption);
            break;
          default:
            break;
        }
      } else {
        setIsMenuVisible(true);
      }
    },
    [
      isMenuVisible,
      options,
      activeOptionId,
      selectContainerRef,
      activeOptionId,
    ],
  );
  const onClickOutside = useCallback((e) => {
    if (
      selectContainerRef.current &&
      !selectContainerRef.current.contains(e.target)
    ) {
      setIsMenuVisible(false);
    }
  }, []);
  const onFocusInput = useCallback(() => {
    setIsMenuVisible(true);
  }, []);

  const onBlurInput = useCallback(() => {
    if (onBlur) onBlur();
  }, []);

  const onChangeActiveOption = useCallback((option) => {
    setActiveOptionId(option.value);
  }, []);

  const onChangeInputValue = useCallback(
    (inputValue: string) => {
      if (_onChangeInputValue) _onChangeInputValue(inputValue);
      setInputValue(inputValue);
    },
    [_onChangeInputValue],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, []);

  useEffect(() => {
    if (
      isMenuVisible &&
      textInputRef &&
      // @ts-ignore
      textInputRef.current &&
      // @ts-ignore
      textInputRef.current.focus
    ) {
      // @ts-ignore
      textInputRef.current.focus();
    }
  }, [isMenuVisible]);

  useEffect(() => {
    if (shouldClearInput) {
      setInputValue('');
      setActiveOptionId('');
    }
  }, [shouldClearInput]);

  return (
    <SelectContext.Provider
      value={{
        value: _value !== undefined ? _value : value,
        // @ts-ignore
        inputValue: _inputValue !== undefined ? _inputValue : inputValue,
        isMenuVisible:
          _isMenuVisible !== undefined ? _isMenuVisible : isMenuVisible,
        options,
        onSelectOption,
        onInputKeyDown: onKeyDown,
        onFocusInput,
        placeholder,
        textInputRef,
        onBlurInput,
        hasError,
        name,
        selectContainerRef,
        disabled,
        filterOptions,
        onResetSelectState,
        onChangeActiveOption,
        activeOptionId,
        isLoading,
        isClearable,
        onChangeInputValue,
      }}
    >
      <div {...classNameProps} ref={selectContainerRef} {...commonProps}>
        {children}
      </div>
    </SelectContext.Provider>
  );
});

export const Select = forwardRef<HTMLInputElement, SelectProps<any>>(
  (props, ref) => {
    return (
      <SelectProvider {...props} ref={ref}>
        <SelectInputContainer>
          <SelectInput />
          <SelectClearIcon />
          <SelectLoadingSpinner />
          <SelectToggleIcon />
        </SelectInputContainer>
        <SelectMenu />
      </SelectProvider>
    );
  },
);

export const SelectInputContainer = ({ children }) => {
  const { disabled, hasError, isLoading } = useSelectContext();
  return (
    <div
      className={`select__input-container ${
        disabled ? 'select__input-container--disabled' : ''
      } ${isLoading ? 'select__input-container--loading' : ''}
      ${hasError ? 'select__input-container--with-error' : ''} `}
    >
      {children}
    </div>
  );
};

export const SelectInput = () => {
  const {
    inputValue,
    onChangeInputValue,
    textInputRef,
    onInputKeyDown,
    placeholder,
    disabled,
    value,
    name,
    onFocusInput,
    onBlurInput,
  } = useSelectContext();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChangeInputValue(e.target.value),
    [],
  );
  return (
    <>
      <input
        className="select__input"
        data-testid="select-input"
        value={inputValue === null ? '' : inputValue}
        ref={textInputRef}
        placeholder={placeholder}
        onFocus={onFocusInput}
        onChange={onChange}
        // @ts-ignore
        onKeyDown={onInputKeyDown}
        onBlur={onBlurInput}
        onClick={onFocusInput}
        disabled={disabled}
      />
      <input name={name} value={value === null ? '' : value} hidden />
    </>
  );
};

export const SelectToggleIcon = () => {
  const { isMenuVisible, onFocusInput, isLoading } = useSelectContext();

  if (isLoading) return null;
  return (
    <div className="select__input-icon" onClick={onFocusInput}>
      <div
        className={`select__input-arrow-icon ${
          isMenuVisible ? 'select__input-arrow-icon--toggled' : ''
        }`}
      >
        <Icon.ArrowDownIcon />
      </div>
    </div>
  );
};

export const SelectClearIcon = () => {
  const { isClearable, value, onResetSelectState } = useSelectContext();

  if (!isClearable || !value) return null;
  return (
    <div className="select__input-icon" onClick={onResetSelectState}>
      <Icon.CloseIcon />
    </div>
  );
};

export const SelectLoadingSpinner = () => {
  const { isLoading } = useSelectContext();
  if (!isLoading) return null;
  return <div className="select__spinner" />;
};

export const SelectMenu = () => {
  const {
    isLoading,
    isMenuVisible,
    inputValue,
    filterOptions,
    value,
    disabled,
    options,
  } = useSelectContext();
  const filteredOptions = useMemo(() => {
    if (!filterOptions || !inputValue) return options;
    const result = matchSorter(options, inputValue, { keys: ['label'] });
    if (
      result.length === 1 &&
      result[0].value === value &&
      inputValue === result[0].label
    )
      return options;
    return result;
  }, [options, inputValue, filterOptions, value]);

  if (!isMenuVisible || disabled) return null;
  if (!options.length) {
    if (isLoading) {
      return <div className="select__menu">Loading</div>;
    } else {
      return <div className="select__menu">No options</div>;
    }
  }

  return (
    <div className="select__menu">
      {filteredOptions.map((option, index) => (
        <SelectMenuOption
          option={option}
          index={index}
          key={`option-${option.value}-${index}`}
        />
      ))}
    </div>
  );
};

export function SelectMenuOption<Entity>({
  option,
  index,
}: {
  option: SelectOption<Entity>;
  index: number;
}) {
  const { options, activeOptionId, onChangeActiveOption, onSelectOption } =
    useSelectContext();
  const { label, Component, entity, value } = option;

  const isActive = activeOptionId === value;
  const groupName = useMemo(() => {
    if (!option.groupName) return null;
    if (index === 0) return option.groupName;
    if (options[index - 1].groupName !== option.groupName)
      return option.groupName;
    return null;
  }, [options, index, option]);
  const onClick = useCallback(() => {
    if (option.disabled) return;
    // @ts-ignore
    onSelectOption(option);
  }, [onSelectOption]);
  const onMouseEnter = useCallback(() => {
    // @ts-ignore
    onChangeActiveOption(option);
  }, [option, onChangeActiveOption]);

  if (Component)
    return (
      <>
        {groupName ? (
          <span className="select__menu-options-group-name">{groupName}</span>
        ) : null}
        <div
          className={`select__menu-option ${
            isActive ? 'select__menu-option--active' : ''
          } ${option.disabled ? 'select__menu-option--disabled' : ''}`}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          data-list-item-id={value}
          data-list-item-type="option"
        >
          {/*// @ts-ignore */}
          <Component entity={entity} isActive={isActive} label={label} />
        </div>
      </>
    );

  return (
    <>
      {groupName ? (
        <span className="select__menu-options-group-name">{groupName}</span>
      ) : null}
      <div
        className={`select__menu-option ${
          isActive ? 'select__menu-option--active' : ''
        } ${option.disabled ? 'select__menu-option--disabled' : ''}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        data-list-item-id={value}
        data-list-item-type="option"
      >
        {label}
      </div>
    </>
  );
}
