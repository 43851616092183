import { yupResolver } from '@hookform/resolvers/yup';
import { Slot } from '@radix-ui/react-slot';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  ReportDataColumn,
  ReportTemplate,
  ReportsScope,
} from '@nl-lms/feature/reports/sdk';
import {
  Box,
  Button,
  Checkbox,
  CheckboxField,
  FormField,
  FormWizard,
  Icon,
  Input,
  Sensitive,
  Separator,
  SideModal,
  Textarea,
  Typography,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useNotifications } from '@nl-lms/ui/modules';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { Can } from '../../../../Can';
import { TextEditorWithFileUpload } from '../../../../_common/modules/TextEditorWithFileUpload';
import { reportsApi } from '../../../../_common/services/api';
import { AdminLearnerGroupMultiSelect } from '../../AdminLearner/AdminLearnerGroupSelect';
import { AdminAnalyticsEditReportTemplateColumnsSideModal } from './AdminAnalyticsEditReportTemplateColumnsSideModal';
import { AdminAnalyticsReportColumnsInput } from './AdminAnalyticsReportColumnsInput';
import { AdminAnalyticsReportFormFilterBar } from './AdminAnalyticsReportFormFilterBar';
import { AdminAnalyticsReportSortInput } from './AdminAnalyticsReportSortInput';
import { AdminAnalyticsReportTemplateSelect } from './AdminAnalyticsReportTemplatesSelect';

const { useCreateReportTemplateMutation, useUpdateReportTemplateMutation } =
  reportsApi;

type AdminAnalyticsUpsertReportTemplateSideModalFormProps = {
  reportTemplate?: ReportTemplate;
};

const Schema = yup.object().shape({
  id: yup.string(),
  label: yup.string().required(),
  name: yup.string().required(),
  description: yup.string().required(),
  targetLearnerGroupIds: yup.array(yup.string()).required(),
  columns: yup.array().required(),
  filters: yup.object().nullable(),
  sorting: yup
    .array(
      yup
        .tuple([
          yup.string().required(),
          yup.string().oneOf(['asc', 'desc']).required(),
        ])
        .required(),
    )
    .nullable(),
  withHistoricalLearnerData: yup.boolean(),
  emailTitle: yup.string().required(),
  emailSubject: yup.string().required(),
  emailMessage: yup.string().required(),
});

export const AdminAnalyticsUpsertReportTemplateSideModal = (
  props: AdminAnalyticsUpsertReportTemplateSideModalFormProps,
) => {
  const { hide } = useModal();
  const [originalReportTemplate, setOriginalReportTemplate] =
    // @ts-ignore
    useState<ReportTemplate>(() => {
      return props?.reportTemplate || null;
    });
  const isEditing = useMemo(() => {
    return !!props?.reportTemplate;
  }, [props]);

  const [
    createReportTemplate,
    { isLoading: isCreateLoading, error: createError },
  ] = useCreateReportTemplateMutation();
  const createReportTemplateAction = useAction(createReportTemplate, {
    successMessage: 'Report template created',
  });

  const [
    updateReportTemplate,
    { isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateReportTemplateMutation();
  const updateReportTemplateAction = useAction(updateReportTemplate, {
    successMessage: 'Report template updated',
  });

  const onSubmit = useCallback(
    async (entity) => {
      const parsedEntity = {
        ...entity,
        notification: {
          subject: entity.emailSubject,
          message: entity.emailMessage,
          title: entity.emailTitle,
        },
        query: {
          filters: entity.filters,
          sorting: entity.sorting,
        },
      };

      if (props?.reportTemplate?.id) {
        await updateReportTemplateAction({
          ...parsedEntity,
          reportTemplateId: props.reportTemplate.id,
        });
      } else {
        await createReportTemplateAction(parsedEntity);
      }

      hide();
    },
    [props],
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    // @ts-ignore
    defaultValues: {
      id: props?.reportTemplate?.id,
      name: props?.reportTemplate?.name || '',
      label: props?.reportTemplate?.label || '',
      description: props?.reportTemplate?.description || '',
      targetLearnerGroupIds: props?.reportTemplate?.targetLearnerGroupIds || [],
      columns: props?.reportTemplate?.columns || [],
      filters: props?.reportTemplate?.query?.filters,
      sorting: props?.reportTemplate?.query?.sorting,
      withHistoricalLearnerData:
        typeof props?.reportTemplate?.withHistoricalLearnerData === 'undefined'
          ? !!C.FEATURES.defaultReportIncludeLearnerHistory
          : props?.reportTemplate?.withHistoricalLearnerData,
      emailTitle: props?.reportTemplate?.notification?.title || '',
      emailSubject: props?.reportTemplate?.notification?.subject || '',
      emailMessage: props?.reportTemplate?.notification?.message || '',
    },
  });

  const onChangeOriginalReportTemplate = useCallback((selectedOption) => {
    if (!selectedOption?.report) return;

    const originalReport = selectedOption.report;
    setOriginalReportTemplate(originalReport);

    setValue('name', originalReport.name);
    setValue('label', originalReport.label);
    setValue('columns', originalReport.columns);
    setValue('filters', originalReport?.query?.filters);
    setValue('sorting', originalReport?.query?.sorting);
    setValue('description', originalReport.description);
    setValue('targetLearnerGroupIds', originalReport.targetLearnerGroupIds);
    setValue(
      'withHistoricalLearnerData',
      originalReport.withHistoricalLearnerData,
    );
    setValue('emailTitle', originalReport.notification.title);
    setValue('emailSubject', originalReport.notification.subject);
    setValue('emailMessage', originalReport.notification.message);
  }, []);

  const columns = watch('columns') as ReportDataColumn[];

  return (
    <SideModal.Content>
      <FormWizard.Provider
        steps={['details', 'data', 'notification']}
        withContainer={false}
      >
        <SideModal.Header>
          {isEditing ? 'Edit Report Template' : 'Create report template'}
        </SideModal.Header>
        <SideModal.Body>
          <FormWizard.Tabs />

          <FormWizard.Step name="details">
            <Box margin={{ top: 'm', bottom: 'm' }}>
              <Typography.p>
                Start by selecting an original report template and who should be
                able to use it.
              </Typography.p>
            </Box>

            {isEditing ? (
              <FormField errorMessage={errors.label?.message} label="Name">
                <Input
                  hasError={!!errors.label?.message}
                  // @ts-ignore
                  name="label"
                  {...register('label')}
                />
              </FormField>
            ) : (
              <Box flex={{ flexDirection: 'row', gap: 'm' }}>
                <FormField label="Original Template">
                  <AdminAnalyticsReportTemplateSelect
                    onChange={onChangeOriginalReportTemplate}
                    isClearable
                  />
                </FormField>
                <FormField errorMessage={errors.label?.message} label="Name">
                  <Input
                    hasError={!!errors.label?.message}
                    // @ts-ignore
                    name="label"
                    {...register('label')}
                  />
                </FormField>
              </Box>
            )}
            <FormField
              label="Description"
              errorMessage={errors.description?.message}
            >
              <Textarea
                // @ts-ignore
                name="description"
                hasError={!!errors.description?.message}
                {...register('description')}
              />
            </FormField>
            <FormField label="Visibility">
              <Controller
                name="targetLearnerGroupIds"
                control={control}
                render={({ field }) => (
                  <AdminLearnerGroupMultiSelect
                    selectedItems={field.value}
                    {...field}
                  />
                )}
              />
            </FormField>
          </FormWizard.Step>

          <FormWizard.Step name="data">
            <Box margin={{ top: 'm', bottom: 'm' }}>
              <Typography.p>
                Set which columns will be visible and specify if the properties
                of a learner should be relative to the point in time at which
                the learning event took place
              </Typography.p>
            </Box>

            <FormField label="Filters">
              <Controller
                name="filters"
                control={control}
                render={({ field }) => (
                  <AdminAnalyticsReportFormFilterBar
                    value={field.value}
                    onChange={field.onChange}
                    columns={columns}
                    key={`filter-bar-${originalReportTemplate?.name}`}
                  />
                )}
              />
            </FormField>

            <CheckboxField
              helpText="Use this field to build your report with historical learner values"
              label="With Historical Learner Data"
            >
              <Checkbox
                // @ts-ignore
                name="withHistoricalLearnerData"
                {...register('withHistoricalLearnerData')}
              />
            </CheckboxField>

            <FormField label="Columns">
              <Controller
                name="columns"
                control={control}
                render={({ field }) => (
                  <AdminAnalyticsReportColumnsInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormField>

            <FormField label="Sorting">
              <Controller
                name="sorting"
                control={control}
                render={({ field }) => (
                  <AdminAnalyticsReportSortInput
                    value={field.value || []}
                    onChange={field.onChange}
                    columns={columns}
                  />
                )}
              />
            </FormField>
          </FormWizard.Step>

          <FormWizard.Step name="notification">
            <Box margin={{ top: 'm', bottom: 'm' }}>
              <Typography.p>
                Customize how the notification email will look like
              </Typography.p>
            </Box>

            <Box flex={{ flexDirection: 'row', gap: 'm' }}>
              <FormField
                label="Subject"
                errorMessage={errors.emailSubject?.message}
              >
                <Input
                  hasError={!!errors.emailSubject?.message}
                  // @ts-ignore
                  name="emailSubject"
                  {...register('emailSubject')}
                />
              </FormField>
              <FormField
                label="Title"
                errorMessage={errors.emailTitle?.message}
              >
                <Input
                  hasError={!!errors.emailTitle?.message}
                  // @ts-ignore
                  name="emailTitle"
                  {...register('emailTitle')}
                />
              </FormField>
            </Box>
            <FormField
              label="Message"
              errorMessage={errors.emailMessage?.message}
            >
              <Controller
                name="emailMessage"
                control={control}
                render={({ field }) => (
                  <Sensitive>
                    <TextEditorWithFileUpload
                      onChange={field.onChange}
                      value={field.value}
                      key={`editor-${originalReportTemplate?.id}`}
                    />
                  </Sensitive>
                )}
              />
            </FormField>
          </FormWizard.Step>
        </SideModal.Body>
        <SideModal.Actions>
          <SideModal.Error>
            {getTranslatedMessageFromError(updateError)}
            {getTranslatedMessageFromError(createError)}
          </SideModal.Error>

          {isEditing && originalReportTemplate.isDefaultTemplate ? (
            <Can
              do={ReportsScope.superEditTemplate.action}
              on={ReportsScope.superEditTemplate.resource}
            >
              <AdminAnalyticsEditReportTemplateColumnsSideModal
                reportTemplate={originalReportTemplate}
              >
                <Button label="God Mode" regular icon={<Icon.SmileIcon />} />
              </AdminAnalyticsEditReportTemplateColumnsSideModal>
            </Can>
          ) : null}
          <Button
            label={isEditing ? 'Update' : 'Create'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isCreateLoading || isUpdateLoading}
            icon={<Icon.FileIcon />}
          />
        </SideModal.Actions>
      </FormWizard.Provider>
    </SideModal.Content>
  );
};

export const AdminAnalyticsUpsertReportTemplateSideModalTrigger = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const showModal = useShowModal(AdminAnalyticsUpsertReportTemplateSideModal);

  return (
    <Can
      do={ReportsScope.updateTemplate.action}
      on={ReportsScope.updateTemplate.resource}
    >
      <Slot onClick={() => showModal({})}>{children}</Slot>
    </Can>
  );
};

export const useShowAdminAnalyticsUpsertReportTemplateSideModal = () => {
  const showModal = useShowModal(AdminAnalyticsUpsertReportTemplateSideModal);

  return useCallback(
    (reportTemplate: ReportTemplate) => showModal({ reportTemplate }),
    [],
  );
};
