import { initContract } from '@ts-rest/core';
import z from 'zod';

import { ErrorResponseSchema } from '@nl-lms/common/sdk';

const contract = initContract();
export const AssessmentRouterContractSdk = contract.router({
  ok: {
    method: 'GET',
    path: '/hi',
    responses: {
      200: z.object({
        message: z.string(),
      }),
      400: ErrorResponseSchema,
    },
  },
});
