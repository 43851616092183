import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import { ApiQuerySchema } from '@nl-lms/common/shared';

import {
  LiveLearningRouterPrefix,
  PortalLiveLearningRouterPrefix,
} from './live-learning.constants.sdk';
import {
  GetLiveCourseSchema,
  ListLiveSessionLearnersSchema,
  ListLiveSessionsSchema,
  LiveCourseWithLiveSessionsSchema,
} from './live-learning.schema.sdk';

const contract = initContract();

export const LiveLearningRouterContract = contract.router({
  listVisibleLiveCoursesWithLiveSessions: {
    method: 'GET',
    path: `${PortalLiveLearningRouterPrefix}/live-course`,
    responses: ContractPaginatedResponse(LiveCourseWithLiveSessionsSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  getLiveCourse: {
    method: 'GET',
    path: `${PortalLiveLearningRouterPrefix}/live-course/:liveCourseId`,
    responses: ContractStandardResponse(GetLiveCourseSchema),
    pathParams: z.object({ liveCourseId: z.string() }),
  },
  listLiveSessions: {
    method: 'GET',
    path: `${PortalLiveLearningRouterPrefix}/live-session`,
    responses: ContractPaginatedResponse(ListLiveSessionsSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  listLiveSessionLearners: {
    method: 'GET',
    path: `${LiveLearningRouterPrefix}/live-session/learner`,
    responses: ContractPaginatedResponse(ListLiveSessionLearnersSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
});
