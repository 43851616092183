import React, { useCallback, useState } from 'react';

import { AppQuery, QueryFilter, QueryOperator } from '@nl-lms/common/shared';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import { AsyncSingleSelect, Button, useShowModal } from '@nl-lms/ui/components';

import { elearningApiClient } from '../../../_common/services/api';
import {
  AdminContentFileCreateModal,
  AdminContentFileCreateModalReturnType,
} from './AdminContentFileCreateModal';

interface Props {
  onChange: (id: string) => void;
  value?: string;
  initialSelectedItem?: { value: string; label: string };
  hasError?: boolean;
}

export const AdminContentFileSelect = (props: Props) => {
  const { onChange, value, initialSelectedItem, hasError = false } = props;

  const [selectedItem, setSelectedItem] = useState(() => {
    return value == initialSelectedItem?.value
      ? initialSelectedItem
      : undefined;
  });

  const loadOptions = useCallback(
    async (input) => {
      const queryFilter = new QueryFilter();
      queryFilter.add({
        field: 'name',
        operator: QueryOperator.Contains,
        value: input,
      });
      const query: AppQuery = {
        filters: queryFilter.appQueryFilter,
        pagination: { offset: 0, limit: 200 },
      };
      const res = await elearningApiClient.listContentFiles({
        query: {
          query: transformTsRestQuery(query),
        },
      });

      if (res.status !== 200) {
        return [];
      }
      const options = res.body.rows.map((c) => {
        return {
          label: c.name,
          value: c.id,
          item: c,
        };
      });
      if (initialSelectedItem) {
        return [...options];
      }
      return options;
    },
    [initialSelectedItem],
  );

  const showAddModal = useShowModal<AdminContentFileCreateModalReturnType>(
    AdminContentFileCreateModal,
  );

  const onClickAdd = useCallback(async () => {
    const res = await showAddModal();
    if (res?.success) {
      const { id, name } = res.payload;
      const _selectedFile = { value: id, label: name };
      setSelectedItem(_selectedFile);
      onChange(id);
    }
  }, [onChange]);

  const onSelectContentFile = useCallback(
    (fileOption) => {
      setSelectedItem(fileOption);
      onChange(fileOption?.value);
    },
    [onChange],
  );
  return (
    <div className="content-file-select" style={{ display: 'flex' }}>
      <AsyncSingleSelect
        name="content-file-select"
        onChange={onSelectContentFile}
        isClearable
        placeholder="Search by name"
        selectedItem={selectedItem}
        initialSelectedItem={initialSelectedItem}
        loadOptions={loadOptions}
        returnEntireItemOnChange
        hasError={hasError}
      />
      <Button label="Add" onClick={onClickAdd} style={{ marginLeft: '20px' }} />
    </div>
  );
};
