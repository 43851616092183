import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import { SurveyRouterPrefix } from './survey.constants.sdk';
import {
  CreateSurveyFormLearningUnitBodySchema,
  ListSurveyFormLearningUnitResponseSchema,
} from './survey.schema.sdk';

const contract = initContract();

// the description field is for indicating if it's a mutation or a query

export const SurveyRouterContract = contract.router({
  listSurveyFormLearningUnits: {
    method: 'GET',
    path: `${SurveyRouterPrefix}/learning-unit`,
    responses: ContractPaginatedResponse(
      ListSurveyFormLearningUnitResponseSchema,
    ),
    query: z.object({ query: ApiQuerySchema }),
  },
  createSurveyFormLearningUnit: {
    method: 'POST',
    path: `${SurveyRouterPrefix}/learning-unit`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: CreateSurveyFormLearningUnitBodySchema,
  },
  deleteSurveyFormLearningUnit: {
    method: 'DELETE',
    path: `${SurveyRouterPrefix}/learning-unit`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    pathParams: z.object({ surveyFormLearningUnitId: z.string() }),
    body: DeleteResourcesBodySchema,
  },
});
