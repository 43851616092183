import React, { useCallback, useMemo, useReducer, useState } from 'react';

import {
  LearningAssignment,
  LearningAssignmentLearningContentType,
} from '@nl-lms/common/feature/types';
import {
  AppQuery,
  DeleteResourceParam,
  QueryFilter,
  QueryOperator,
} from '@nl-lms/common/shared';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  AddIcon,
  Box,
  Button,
  CalendarIcon,
  DateTime,
  FormField,
  Icon,
  Modal,
  NoDataPlaceholder,
  Select,
  Separator,
  SideModal,
  Table,
  Typography,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';
import {
  formatConstantString,
  getTranslatedMessageFromError,
} from '@nl-lms/ui/utils';

import {
  learningAssignmentsApi,
  learningProgramsApi,
} from '../../../_common/services/api';

const {
  useAddLearningProgramAssignmentInstancesMutation,
  useScheduleLearningProgramAssignmentInstancesMutation,
} = learningProgramsApi;

const { useListLearningAssignmentsQuery } = learningAssignmentsApi;

type ContentProps = {
  selection: DeleteResourceParam;
  learningProgramId: string;
};

export const useShowAddLearningProgramAssignmentInstancesModal = () => {
  return useShowModal(LearningProgramAddAssignmentInstancesSideModal);
};

export const LearningProgramAddAssignmentInstancesSideModal = (
  props: ContentProps,
) => {
  const { selection, learningProgramId } = props;
  const { hide } = useModal();
  const [selectedAssignments, setSelectedAssignments] = useState<
    LearningAssignment[]
  >([]);
  const [selectInputValue, setSelectInputValue] = useState('');
  const onChangeInputValue = useCallback((inputValue) => {
    setSelectInputValue(inputValue);
  }, []);
  const learningAssignmentsListQuery = useMemo<AppQuery>(() => {
    const baseQuery = new QueryFilter();
    baseQuery.add({
      field: 'learningProgramId',
      operator: QueryOperator.Equals,
      value: learningProgramId,
    });

    return {
      filters: baseQuery.appQueryFilter,
      pagination: { disabled: true },
      sorting: [['name', 'asc']],
    };
  }, [learningProgramId]);
  const { data: listLearningAssignments, isLoading: isListAssignmentsLoading } =
    useListLearningAssignmentsQuery({
      query: { query: transformTsRestQuery(learningAssignmentsListQuery) },
    });
  const learningAssignments = listLearningAssignments?.rows || [];
  const [addAssignmentInstances, { isLoading: isAddLoading, error: addError }] =
    useAddLearningProgramAssignmentInstancesMutation();
  const [
    scheduleAssignmentInstances,
    { isLoading: isScheduleLoading, error: scheduleError },
  ] = useScheduleLearningProgramAssignmentInstancesMutation();

  const onSelectAssignment = useCallback(
    (option) => {
      onChangeInputValue('');
      setSelectedAssignments([...selectedAssignments, option.entity]);
    },
    [selectedAssignments],
  );
  const [scheduleOn, setScheduleOn] = useState<Date | null>(null);
  const isScheduling = !!scheduleOn;
  const onRemoveAssignment = useCallback(
    (row) => {
      setSelectedAssignments(
        selectedAssignments.filter((la) => la.id !== row.id),
      );
    },
    [selectedAssignments],
  );

  const countOfSelectedInstances = selection.count as number;
  const assignmentOptions = useMemo(() => {
    const selectedAssignmentsIds = selectedAssignments.map((la) => la.id);
    return learningAssignments
      .filter((la) => !selectedAssignmentsIds.includes(la.id))
      .map((la) => ({
        value: la.id,
        label: la.name,
        entity: la,
      }));
  }, [learningAssignments, selectedAssignments]);

  const onSubmit = useCallback(async () => {
    if (!learningAssignments.length) return;
    const method = !!scheduleOn
      ? scheduleAssignmentInstances
      : addAssignmentInstances;
    const params = {
      ...selection,
      learningAssignmentIds: selectedAssignments.map((la) => la.id),
      scheduleOn,
    };
    const result = await method(params);
    if ('data' in result) {
      hide();
    }
  }, [isScheduling, selectedAssignments, scheduleOn]);
  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {isScheduling ? 'Schedule ' : 'Add '}
          Learning Assignment Instances
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box flex={{ gap: 's' }}>
          <Box flex={{ flex: 2 }}>
            <FormField label="Learning Assignments">
              <Select
                value=""
                inputValue={selectInputValue}
                onChangeInputValue={onChangeInputValue}
                name="select-assignments"
                onChange={onSelectAssignment}
                isLoading={isListAssignmentsLoading}
                options={assignmentOptions}
              />
            </FormField>
          </Box>
          <Box flex={{ flex: 1 }}>
            <FormField
              label="Schedule On"
              helpText="Use this field to schedule the creation process. If you leave it as black the assignment instances will be created right away."
            >
              <DateTime
                date={scheduleOn}
                isClearable
                onChange={(e) => setScheduleOn(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Separator marginTop={0} marginBottom={20} />
        {!selectedAssignments.length ? (
          <NoDataPlaceholder
            title="Choose the Assignments"
            iconName="BookIcon"
            subtitle="Select what content should be assigned to the learners."
          />
        ) : (
          <>
            <Box margin={{ bottom: 's' }}>
              <Typography.h3>
                The following assignments will be created for{' '}
                {countOfSelectedInstances} learner
                {countOfSelectedInstances > 1 ? 's' : ''}.
              </Typography.h3>
            </Box>
            <Table
              data={selectedAssignments}
              rowActions={[
                {
                  name: 'Remove',
                  handler: onRemoveAssignment,
                  Icon: Icon.DeleteIcon,
                },
              ]}
              columns={[
                {
                  accessor: 'name',
                  Header: 'Name',
                  Cell: ({ row }) => row.original.name,
                },
                {
                  accessor: 'type',
                  Header: 'Type',
                  Cell: ({ row }) =>
                    formatConstantString(
                      LearningAssignmentLearningContentType[
                        row?.original?.content?.type
                      ],
                    ),
                },
              ]}
            />
          </>
        )}
      </SideModal.Body>
      <SideModal.Actions>
        <Modal.Error>{getTranslatedMessageFromError(addError)}</Modal.Error>
        <Modal.Error>
          {getTranslatedMessageFromError(scheduleError)}
        </Modal.Error>
        <Button
          onClick={onSubmit}
          disabled={!selectedAssignments.length}
          isLoading={isAddLoading || isScheduleLoading}
          icon={isScheduling ? <CalendarIcon /> : <AddIcon />}
          label={isScheduling ? 'Schedule' : 'Add'}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
