import { useMemo } from 'react';

import {
  AppQueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { C } from '@nl-lms/ui/constants';

const baseFilters = [
  {
    id: 'learner-mandatory-learning',
    value: {
      field: { label: 'mandatory', field: 'mandatory' },
      operator: {
        label: 'Equals',
        value: QueryOperator.Equals,
        type: QueryFilterOperatorType.Unary,
      },
      value: {
        label: 'ismandatory',
        value: true,
      },
    },
    combinator: QueryFilterCombinator.And,
  },
  {
    id: 'learner-learning-status',
    value: {
      field: { label: 'status', field: 'status' },
      operator: {
        label: 'Excludes',
        value: QueryOperator.Excludes,
        type: QueryFilterOperatorType.Unary,
      },
      value: {
        label: 'learningStatusValue',
        value: ['CANCELED'],
      },
    },
    combinator: QueryFilterCombinator.And,
  },
];

export const useLearnerMandatoryLearningBaseFilters = () => {
  return useMemo<AppQueryFilter>(
    () => ({
      id: 'learner-active-learning-filters',
      value: [
        // only include elearning assignments and exclude canceled items
        ...baseFilters,
      ],
    }),
    [],
  );
};
