import {
  AppQuery,
  FieldMetadataValueOption,
  QueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
  isValue,
} from '@nl-lms/common/shared';
import { LearningAssignment } from '@nl-lms/feature/learning-assignments/sdk';
import { Competency, Tag, transformTsRestQuery } from '@nl-lms/sdk/backend';
import { Sensitive } from '@nl-lms/ui/components';
import { formatConstantString, prettyDate } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import {
  api,
  certificationApiClient,
  learningAssignmentsApiClient,
  learningProgramsApiClient,
  organizationApiClient,
  reportsApiClient,
  subscriptionsApiClient,
} from '../../../_common/services/api';

export const fetchAndMapLiveCourses = async (inputValue) => {
  const res = await api.course.listForSelect(inputValue);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const fetchAndMapElearningCourses = async (inputValue) => {
  const res = await api.elearningCourse.listForSelect(inputValue, true);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const fetchAndMapAvailableElearningCourses = async (inputValue) => {
  const res = await api.elearningCourse.listForSelect(inputValue, false);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const fetchAndMapAssessmentForms = async (inputValue) => {
  const res = await api.assessment.listForSelect(inputValue);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const fetchAndMapLearningPaths = async (inputValue) => {
  const res = await api.learningPath.listForSelect(inputValue);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const fetchAndMapVendors = async (inputValue) => {
  const res = await api.vendor.listForSelect(inputValue);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

const getAssignmentLabel = (assignment: LearningAssignment) => {
  const dueDate = assignment.dueDate
    ? prettyDate(assignment.dueDate)
    : 'No Due Date';
  const name = assignment.name || 'No name';

  return `${name} · ${dueDate}`;
};

export const fetchAndMapAssignments = async (
  inputValue,
  extraFilters = null,
) => {
  const filters = new QueryFilter();

  filters.add({
    field: 'name',
    operator: QueryOperator.Contains,
    value: inputValue,
  });

  if (extraFilters && !_.isEmpty(extraFilters)) {
    filters.add(extraFilters, { combinator: QueryFilterCombinator.And });
  }

  const query: AppQuery = {
    filters: filters.appQueryFilter,
    pagination: { offset: 0, limit: 50 },
  };
  const res = await learningAssignmentsApiClient.listLearningAssignments({
    query: {
      query: transformTsRestQuery(query),
    },
  });

  if (res.status === 200) {
    return res.body.rows.map((l) => ({
      value: l.id,
      label: getAssignmentLabel(l),
    }));
  }
  return [];
};

export const fetchAndMapLearningPrograms = async (
  inputValue,
  extraFilters = null,
) => {
  const filters = new QueryFilter();

  filters.add({
    field: 'name',
    operator: QueryOperator.Contains,
    value: inputValue,
  });

  if (extraFilters && !_.isEmpty(extraFilters)) {
    filters.add(extraFilters, { combinator: QueryFilterCombinator.And });
  }

  const query: AppQuery = {
    filters: filters.appQueryFilter,
    pagination: { offset: 0, limit: 50 },
  };
  const res = await learningProgramsApiClient.listLearningPrograms({
    query: {
      query: transformTsRestQuery(query),
    },
  });

  if (res.status === 200) {
    return res.body.rows.map((l) => ({
      value: l.id,
      label: l.name,
    }));
  }
  return [];
};

export const fetchAndMapLiveSessions = async (inputValue) => {
  const res = await api.session.listForSelect(inputValue);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const fetchAndMapLearners = async (inputValue) => {
  const filters: AppQuery = {
    pagination: { offset: 0, limit: 20 },
    filters: {
      id: 'ids',
      value: [
        {
          id: 'ids',
          value: {
            field: { field: 'isearch', label: 'isearch' },
            operator: {
              value: QueryOperator.Contains,
              type: QueryFilterOperatorType.Binary,
            },
            value: {
              value: inputValue,
              label: inputValue,
            },
          },
          combinator: QueryFilterCombinator.And,
        },
      ],
    },
  };
  const res = await api.learner.listForSelect(filters);
  return res.map((l) => ({
    value: l.id,
    label: l.deletedAt
      ? `${l.firstName} ${l.lastName} · ${l.email} · Inactive`
      : `${l.firstName} ${l.lastName} · ${l.email}`,
    learner: l,
    Component: ({ label }) => <Sensitive>{label}</Sensitive>,
  }));
};

export const fetchAndMapReportTemplates = async (inputValue) => {
  const filters = new QueryFilter();
  filters.add({
    field: 'label',
    value: inputValue,
    operator: QueryOperator.Contains,
  });
  const query: AppQuery = {
    filters: filters.appQueryFilter,
    pagination: { disabled: true },
    sorting: [['label', 'asc']],
  };
  const result = await reportsApiClient.selectReportTemplates({
    query: { query: transformTsRestQuery(query) },
  });

  if (result.status === 200) {
    return result.body.rows.map((r) => ({
      value: r.id,
      label: r.label,
      report: r,
    }));
  }
  return [];
};

export const fetchAndMapManagers = async (inputValue) => {
  const filters: AppQuery = {
    pagination: { offset: 0, limit: 20 },
    filters: {
      id: 'ids',
      value: [
        {
          id: 'ids',
          value: {
            field: { field: 'is_manager', label: 'isManager' },
            operator: {
              value: QueryOperator.IsTrue,
              type: QueryFilterOperatorType.Unary,
            },
          },
          combinator: QueryFilterCombinator.And,
        },
        {
          id: 'ids',
          value: {
            field: { field: 'isearch', label: 'isearch' },
            operator: {
              value: QueryOperator.Contains,
              type: QueryFilterOperatorType.Binary,
            },
            value: {
              value: inputValue,
              label: inputValue,
            },
          },
          combinator: QueryFilterCombinator.And,
        },
      ],
    },
  };
  const res = await api.learner.listForSelect(filters);
  return res.map((l) => ({
    value: l.id,
    label: `${l.firstName} ${l.lastName} · ${l.email}`,
    Component: ({ label }) => <Sensitive>{label}</Sensitive>,
  }));
};

export const fetchAndMapLearnerGroups = async (inputValue: string) => {
  const filters = new QueryFilter();

  filters.add({
    field: 'title',
    operator: QueryOperator.Contains,
    value: inputValue,
  });
  const query: AppQuery = {
    filters: filters.appQueryFilter,
    pagination: { offset: 0, limit: 50 },
  };
  const res = await organizationApiClient.listLearnerGroups({
    query: {
      query: transformTsRestQuery(query),
    },
  });

  if (res.status === 200) {
    return res.body.rows.map((l) => ({
      value: l.id,
      label: l.title,
    }));
  }
  return [];
};

export const fetchAndMapUsers = async (inputValue) => {
  const res = await api.user.listForSelect(inputValue);
  return res.map((l) => ({
    value: l.id,
    label: `${l.firstName} ${l.lastName}`,
    Component: ({ label }) => <Sensitive>{label}</Sensitive>,
  }));
};

export const fetchAndMapSurveys = async (inputValue) => {
  const res = await api.survey.listForSelect(inputValue);
  return res.map((s) => ({
    value: s.id,
    label: s.name,
  }));
};

export const fetchAndMapTags = async (inputValue) => {
  const filter = new QueryFilter();
  filter.add({
    field: 'title',
    operator: QueryOperator.Contains,
    value: inputValue,
  });
  const res = await api.tag.list({
    filters: filter.appQueryFilter,
    pagination: { offset: 0, limit: 50 },
  });
  return res.rows.map((s) => ({
    value: s.id,
    label: s.scope ? `${s.scope}:${s.title}` : s.title,
  }));
};

export const fetchAndMapTagsForType = async (type) => {
  const res = await api.tag.listForSelect(type);
  return res.map((s) => ({
    value: s.id,
    label: s.scope ? `${s.scope}:${s.title}` : s.title,
  }));
};

export const fetchAndMapLearnerTagsForType = async ({ type, learnerId }) => {
  const res = await api.learnerApp.listTagsForSelect({ type, learnerId });
  // @ts-ignore
  return res.map((s: Tag) => ({
    value: s.id,
    label: s.scope ? `${s.scope}:${s.title}` : s.title,
  }));
};

export const fetchAndMapCompetencies = async (inputValue) => {
  const filter = new QueryFilter();
  filter.add({
    field: 'title',
    operator: QueryOperator.Contains,
    value: inputValue,
  });
  const res = await api.competency.list({
    filters: filter.appQueryFilter,
    pagination: { offset: 0, limit: 50 },
  });
  return res.rows.map((s) => ({
    value: s.id,
    label: s.scope ? `${s.scope}:${s.title}` : s.title,
  }));
};

export const fetchAndMapCompetenciesForType = async () => {
  const res = await api.competency.listForSelect();
  return res.map((s) => ({
    value: s.id,
    label: s.scope ? `${s.scope}:${s.title}` : s.title,
  }));
};

export const fetchAndMapLearnerCompetencies = async ({ learnerId }) => {
  const res = await api.learnerApp.listCompetencies({ learnerId });
  // @ts-ignore
  return res.map((s: Competency) => ({
    value: s.id,
    label: s.scope ? `${s.scope}:${s.title}` : s.title,
  }));
};

export const fetchAndMapTrainers = async (inputValue) => {
  const filter = new QueryFilter();
  filter.add({
    field: 'first_name',
    operator: QueryOperator.Contains,
    value: inputValue,
  });
  filter.add(
    {
      field: 'last_name',
      operator: QueryOperator.Contains,
      value: inputValue,
    },
    { combinator: QueryFilterCombinator.Or },
  );
  filter.add(
    {
      field: 'email',
      operator: QueryOperator.Contains,
      value: inputValue,
    },
    { combinator: QueryFilterCombinator.Or },
  );
  const res = await api.trainer.list({
    filters: filter.appQueryFilter,
    pagination: { offset: 0, limit: 50 },
  });
  return res.rows.map((s) => ({
    value: s.id,
    label: `${s.firstName} ${s.lastName}`,
    Component: ({ label }) => <Sensitive>{label}</Sensitive>,
  }));
};

export const fetchAndMapChecklists = async (inputValue) => {
  const res = await api.checklist.listForSelect(inputValue);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const fetchAndMapIndividualLearnings = async (inputValue) => {
  const res = await api.individualLearning.listForSelect(inputValue);
  return res.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const fetchAndMapSubscriptions = async (inputValue) => {
  const filters = new QueryFilter();
  filters.add({
    field: 'name',
    value: inputValue,
    operator: QueryOperator.Contains,
  });
  const query: AppQuery = {
    filters: filters.appQueryFilter,
    pagination: { disabled: true },
    sorting: [['label', 'asc']],
  };
  const result = await subscriptionsApiClient.listSubscriptions({
    query: { query: transformTsRestQuery(query) },
  });

  if (result.status === 200) {
    return result.body.rows.map((r) => ({
      value: r.id,
      label: r.name,
      entity: r,
    }));
  }

  return [];
};

export const fetchAndMapCertifications = async (inputValue) => {
  const filters = new QueryFilter();
  filters.add({
    field: 'name',
    value: inputValue,
    operator: QueryOperator.Contains,
  });
  const query: AppQuery = {
    filters: filters.appQueryFilter,
    pagination: { disabled: true },
    sorting: [['name', 'asc']],
  };

  const result = await certificationApiClient.listCertifications({
    query: { query: transformTsRestQuery(query) },
  });

  if (result.status === 200) {
    return result.body.rows.map((r) => ({
      value: r.id,
      label: r.name,
      entity: r,
    }));
  }

  return [];
};

export const mapAndLoadEnumsForSelect =
  (
    enumValues: Record<string | number | symbol, unknown>,
    options: { valueParser: any } = { valueParser: parseInt },
  ): ((inputValue: string) => Promise<FieldMetadataValueOption[]>) =>
  // @ts-ignore
  async (inputValue) => {
    return Object.keys(enumValues)
      .map((key) => {
        // @ts-expect-error
        if (!isNaN(key)) return;
        return {
          value: options.valueParser(enumValues[key]),
          label: formatConstantString(key),
        };
      })
      .filter((v) => !!v);
  };

export const mapAndLoadConstantsForSelect =
  (
    constantValues: Record<any, string>,
    options: { valueParser: any } = { valueParser: parseInt },
  ): ((inputValue: string) => Promise<FieldMetadataValueOption[]>) =>
  // @ts-ignore
  async (inputValue) => {
    return _.map(constantValues, (label, value) => ({
      value: options.valueParser(value),
      label: formatConstantString(label),
    }));
  };

export const mapAndLoadStatusesForSelect =
  (
    constantValues: Record<any, string | number>,
  ): ((inputValue: string) => Promise<FieldMetadataValueOption[]>) =>
  async (inputValue) => {
    return Object.values(constantValues)
      .map((label) => {
        if (typeof label === 'number') return;
        return {
          value: constantValues[label],
          label: formatConstantString(label),
        };
      })
      .filter(isValue);
  };

export const mapAndLoadValArrayForSelect =
  (values: string[]) => async (inputValue) =>
    _.map(values, (value) => ({
      value,
      label: formatConstantString(value),
    }));
