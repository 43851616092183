import store from 'store';

import { getClients } from '@nl-lms/feature/surveys/sdk';

import { apiBaseUrl } from './api';

const { raw: surveyApiClient, rtk: surveyRtkClient } = getClients({
  baseUrl: `${apiBaseUrl('v2')}`,
  tokenGetter: () => store.get('accessToken'),
});

export { surveyApiClient };

export const surveyApi = surveyRtkClient;
surveyApi.enhanceEndpoints<'SurveyFormLearningUnit'>({
  endpoints: {
    createSurveyFormLearningUnit: {
      invalidatesTags: [{ type: 'SurveyFormLearningUnit', id: 'LIST' }],
    },
    deleteSurveyFormLearningUnit: {
      invalidatesTags: [{ type: 'SurveyFormLearningUnit', id: 'LIST' }],
    },
    listSurveyFormLearningUnits: {
      providesTags: [{ type: 'SurveyFormLearningUnit', id: 'LIST' }],
    },
  },
});
