import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Venue } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Input,
  Sensitive,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { LocationSelect } from '../../_common/LocationSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';
import { AdminVenueFeaturesSelect } from './AdminVenueFeaturesSelect';

type Props = {
  venue?: Partial<Venue>;
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  vendorId: yup.string().required(),
  touchpointEmail: yup.string().email().required(),
  address: yup.string().required(),
  building: yup.string(),
  floor: yup.string(),
  roomNo: yup.string(),
  location: yup.object(),
  features: yup.array(),
  seatsNo: yup.number().required(),
});

export const AdminVenueEditFormSideModal = ({ venue = {} }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...venue,
      location: {
        city: venue.city,
        region: venue.region,
        country: venue.country,
      },
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateVenueMutation',
    createHookName: 'useCreateVenueMutation',
    entityId: venue.id,
    parseEntity: (entity) => ({ ...entity, ...entity?.location }),
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {venue.id ? 'Edit Venue' : 'Create New Venue'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Venue Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Vendor"
              errorMessage={errors?.vendorId?.message}
              required
            >
              <Controller
                control={control}
                name="vendorId"
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    isClearable
                    hasError={!!errors?.vendorId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="Email"
              errorMessage={errors?.touchpointEmail?.message}
            >
              <Sensitive>
                <Input
                  {...register('touchpointEmail')}
                  required
                  hasError={!!errors?.touchpointEmail?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField
              required
              label="Capacity"
              errorMessage={errors?.seatsNo?.message}
            >
              <Input
                type="number"
                {...register('seatsNo')}
                required
                hasError={!!errors?.seatsNo?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Features">
              <Controller
                control={control}
                name="features"
                render={({ field }) => (
                  <AdminVenueFeaturesSelect
                    {...field}
                    // @ts-ignore
                    initialValue={venue.features}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Address</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField label="Location" required>
              <Controller
                control={control}
                name="location"
                render={({ field }) => (
                  <Sensitive>
                    <LocationSelect
                      {...field}
                      initialLocation={{
                        // @ts-ignore
                        country: venue.country,
                        // @ts-ignore
                        city: venue.city,
                        // @ts-ignore
                        region: venue.region,
                      }}
                    />
                  </Sensitive>
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Building">
              <Sensitive>
                <Input
                  {...register('building')}
                  required
                  hasError={!!errors?.building?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField label="Floor">
              <Sensitive>
                <Input
                  {...register('floor')}
                  hasError={!!errors?.floor?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField label="Room Number">
              <Sensitive>
                <Input
                  {...register('roomNo')}
                  hasError={!!errors?.roomNo?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Address"
              required
              errorMessage={errors?.address?.message}
            >
              <Sensitive>
                <Input
                  {...register('address')}
                  required
                  hasError={!!errors?.address?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <Button
          label={venue.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
