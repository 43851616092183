import * as z from 'zod';

import {
  ApiQuery,
  ApiQueryFilter,
  ApiQueryFilterModel,
  AppQuery,
  AppQueryFilter,
  AppQueryFilterField,
  AppQueryFilterModel,
  AppQueryFilterValue,
  AppQueryPagination,
  AppQuerySorting,
  FieldType,
  QueryFilterCombinator,
  QueryFilterOperator,
  QueryFilterOperatorType,
  QueryOperator,
} from './types';

export const FieldTypeSchema: z.ZodType<FieldType> = z.nativeEnum(FieldType);

export const QueryOperatorSchema: z.ZodType<QueryOperator> = z.enum([
  QueryOperator.Equals,
  QueryOperator.NEqual,
  QueryOperator.GreaterThan,
  QueryOperator.Includes,
  QueryOperator.Excludes,
  QueryOperator.Like,
  QueryOperator.GreaterThanEqual,
  QueryOperator.LowerThan,
  QueryOperator.LowerThanEqual,
  QueryOperator.Contains,
  QueryOperator.DoesNotContain,
  QueryOperator.IsTrue,
  QueryOperator.IsFalse,
  QueryOperator.IsNull,
  QueryOperator.IsNotNull,
  QueryOperator.Overlaps,
  QueryOperator.DoesNotOverlap,
  QueryOperator.JsonArrayIncludes,
  QueryOperator.JsonArrayDoesNotInclude,
]);

export const QueryFilterCombinatorSchema: z.ZodType<QueryFilterCombinator> =
  z.enum([QueryFilterCombinator.And, QueryFilterCombinator.Or]);

export const QueryFilterOperatorTypeSchema: z.ZodType<QueryFilterOperatorType> =
  z.enum([QueryFilterOperatorType.Binary, QueryFilterOperatorType.Unary]);

export const AppQueryFilterFieldSchema: z.ZodType<AppQueryFilterField> =
  z.object({
    field: z.string(),
    label: z.string(),
    type: FieldTypeSchema.optional(),
  });

export const AppQueryFilterOperatorSchema: z.ZodType<QueryFilterOperator> =
  z.object({
    value: QueryOperatorSchema,
    label: z.string().optional(),
    type: QueryFilterOperatorTypeSchema,
  });

// @ts-ignore
export const AppQueryFilterValueSchema: z.ZodType<AppQueryFilterValue> =
  z.object({
    label: z.string().optional(),
    value: z.union([
      z.string(),
      z.number(),
      z.array(z.string()),
      z.array(z.number()),
      z.boolean(),
    ]),
  });

export const AppQueryFilterModelSchema: z.ZodType<AppQueryFilterModel> =
  z.object({
    field: AppQueryFilterFieldSchema,
    operator: AppQueryFilterOperatorSchema,
    value: AppQueryFilterValueSchema,
  });

export const AppQueryFiltersSchema: z.ZodType<AppQueryFilter> = z.object({
  id: z.string(),
  value: z
    .union([
      AppQueryFilterModelSchema,
      z.lazy(() => z.array(AppQueryFiltersSchema)),
    ])
    .optional(),
  combinator: QueryFilterCombinatorSchema.nullable().optional(),
});

export const AppQueryPaginationSchema: z.ZodType<AppQueryPagination> = z.union([
  z.object({
    limit: z.union([z.number(), z.string()]),
    offset: z.union([z.number(), z.string()]),
    disabled: z.boolean().optional(),
  }),
  z.object({
    limit: z.union([z.number(), z.string()]).optional(),
    offset: z.union([z.number(), z.string()]).optional(),
    disabled: z.boolean(),
  }),
]);

export const AppQuerySortingSchema: z.ZodType<AppQuerySorting> = z.array(
  z.tuple([z.string(), z.enum(['desc', 'asc'])]),
);
export const AppQuerySchema: z.ZodType<AppQuery> = z
  .object({
    filters: AppQueryFiltersSchema,
    pagination: AppQueryPaginationSchema,
    sorting: AppQuerySortingSchema,
  })
  .partial();

const ApiQueryFilterModelSchema: z.ZodType<ApiQueryFilterModel> = z.object({
  field: z.string(),
  operator: z.string(),
  value: z
    .union([
      z.string(),
      z.number(),
      z.array(z.string()),
      z.array(z.number()),
      z.boolean(),
    ])
    .optional(),
});

export const ApiQueryFilterSchema: z.ZodType<ApiQueryFilter> = z
  .object({
    value: z.union([
      ApiQueryFilterModelSchema,
      z.lazy(() => z.array(ApiQueryFilterSchema)),
    ]),
    combinator: QueryFilterCombinatorSchema,
  })
  .partial();

export const ApiQuerySchema: z.ZodType<ApiQuery> = z
  .object({
    filters: ApiQueryFilterSchema,
    pagination: AppQueryPaginationSchema,
    sorting: AppQuerySortingSchema,
    search: z.string(),
  })
  .partial();

export const DeleteResourcesBodySchema = z.object({
  list: z.union([z.array(z.string()), ApiQueryFilterSchema]),
  count: z.number().nullish(),
});

// @ts-ignore
export const TransformedDateSchema: z.ZodType<Date> = z
  .string()
  .pipe(z.coerce.date());

export const TimeDeltaSchema = z
  .object({
    minutes: z.number(),
    hours: z.number(),
    days: z.number(),
    weeks: z.number(),
    months: z.number(),
    years: z.number(),
    settings: z
      .object({
        skipWeekends: z.boolean().optional(),
        minute: z.number().optional(),
        hour: z.number().optional(),
        dayOfWeek: z.number().optional(),
        dayOfMonth: z.number().optional(),
        year: z.number().optional(),
        month: z.number().optional(),
        // diff in minutes from UTC time
        // Use this instead of the actual timezone value to avoid issues with Intl support in node
        // The client/web app will have to make sure that the offset is correct
        utcOffset: z.number().optional(),
      })
      .nullable(),
  })
  .partial()
  .refine(
    (obj: Record<string | number | symbol, unknown>) =>
      Object.values(obj).some((v) => v !== undefined),
    'At least one property needs to be specified',
  );
export type TimeDelta = z.infer<typeof TimeDeltaSchema>; // can't be moved to types because of circular dependency

export const FieldConditionSchema = z.object({
  field: z.string(),
  operator: z.union([
    z.literal('equals'),
    z.literal('notEquals'),
    z.literal('includes'),
    z.literal('doesNotInclude'),
    z.literal('contains'),
    z.literal('doesNotContain'),
    z.literal('jsonArrayIncludes'),
    z.literal('jsonArrayDoesNotInclude'),
    z.literal('greaterThan'),
    z.literal('greaterThanOrEqual'),
    z.literal('lowerThanOrEqual'),
    z.literal('lowerThan'),
    z.literal('isTrue'),
    z.literal('isFalse'),
  ]),
  value: z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.array(z.string()),
    z.array(z.number()),
  ]),
});

export type FieldCondition = z.infer<typeof FieldConditionSchema>;
