import { z } from 'zod';

import {
  LearnerGroupSchema,
  LearnerHistorySchema,
  LearnerSchema,
} from '@nl-lms/common/feature/types';

const LearnerDynamicDetailsSchema = z.record(z.string());

export const LearnerWithDynamicDetailsSchema = LearnerSchema.extend({
  dynamicDetails: LearnerDynamicDetailsSchema,
});

export const ExtendedLearnerSchema = LearnerWithDynamicDetailsSchema.extend({
  hierarchicManagers: z.array(LearnerSchema),
  learnerGroups: z.array(LearnerGroupSchema),
  history: z.array(LearnerHistorySchema),
});

export const CreateLearnerPayloadSchema = LearnerSchema.pick({
  firstName: true,
  lastName: true,
  email: true,
  customerInternalId: true,
  details: true,
}).extend({
  managerId: z.string().optional(),
});

export const UpdateLearnerPayloadSchema = LearnerSchema.pick({
  firstName: true,
  lastName: true,
  email: true,
  customerInternalId: true,
  details: true,
}).extend({
  managerId: z.string().optional(),
});
