import React, { useCallback, useMemo } from 'react';

import { AsyncSingleSelect, SelectSkeleton } from '@nl-lms/ui/components';
import { getSelectOptions } from '@nl-lms/ui/utils';

import { fetchAndMapIndividualLearnings } from '../../adminApp/_common/utils/fetchEntitiesForSelectMethods';
import { adminApi } from '../services/api';

const { useListIndividualLearningsForSelectQuery } = adminApi;

const buildLabel = (item) => {
  return item.name;
};

const parseOptions = (res) => getSelectOptions(res, buildLabel);

const parseSelectedItems = (selectedItemsIds: string[], options) => {
  if (!selectedItemsIds) return null;
  return options.filter((o) => selectedItemsIds.includes(o.value));
};

export const IndividualLearningSelect = (props) => {
  const {
    onChange,
    name,
    placeholder = 'Select individual learning',
    isAdmin,
    disabled = false,
    isClearable = false,
    hasError = false,
    initialSelectedItem = null,
    selectedItem,
  } = props;
  const { data, isLoading, isFetching } =
    useListIndividualLearningsForSelectQuery({
      query: '',
      isAdmin,
    });
  const options = useMemo(() => {
    if (!data) return [];
    return parseOptions(data);
  }, [data]);

  const value = parseSelectedItems([selectedItem], options)[0] || null;
  const handleOnChange = useCallback(
    (value, name) => {
      const item = data?.find((d) => d.id === value);
      onChange(value, name, item);
    },
    [data],
  );

  const handleOnChangeInputValue = (value) => {
    if (!value) {
      handleOnChange(null, '');
    }
  };

  if (isFetching) {
    return (
      <SelectSkeleton
        hasError={hasError}
        placeholder={'Loading individual learning...'}
        disabled={disabled}
      />
    );
  }

  return (
    <AsyncSingleSelect
      name={name}
      placeholder={placeholder}
      onChange={handleOnChange}
      onChangeInputValue={handleOnChangeInputValue}
      initialSelectedItem={initialSelectedItem}
      selectedItem={value}
      isClearable={isClearable}
      hasError={hasError}
      disabled={disabled}
      loadOptions={(value) =>
        fetchAndMapIndividualLearnings({ query: value, isAdmin })
      }
    />
  );
};
