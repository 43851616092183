import React, { useCallback, useMemo, useState } from 'react';

import { useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { Checkbox } from '../Checkbox/Checkbox';
import { TidComponent } from '../index.types';
import './CheckboxGroup.scss';

type Option = {
  value: string;
  label: string;
  hasError?: boolean;
};

type CheckboxGroupProps = TidComponent<{
  options: Option[];
  value: string[];
  onChange: (e: { target: { value?: string[]; name?: string } }) => void;
  name: string;
  readOnly?: boolean;
  controlled?: boolean;
  disabled?: boolean;
}>;

export const CheckboxGroup: React.FunctionComponent<CheckboxGroupProps> = (
  props,
) => {
  const {
    options,
    onChange,
    name,
    readOnly = false,
    value = null,
    controlled = false,
    disabled = false,
  } = props;
  const commonProps = useTestProps(props);

  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    value ? options.filter((o) => value.includes(o.value)) : [],
  );

  const _selectedOptions = useMemo(() => {
    if (!controlled) return selectedOptions;
    return value ? options.filter((o) => value.includes(o.value)) : [];
  }, [selectedOptions, value, controlled, options]);

  const handleCheckboxChange = useCallback(
    (e) => {
      let newSelectedOptions = _.clone(_selectedOptions) as Option[];

      if (e.target.checked) {
        const checkedOption = options.find(
          (option) => option.value === e.target.name,
        );
        if (checkedOption) {
          newSelectedOptions.push(checkedOption);
        }
      } else {
        newSelectedOptions = newSelectedOptions.filter(
          (option) => option.value !== e.target.name,
        );
      }
      setSelectedOptions(newSelectedOptions);
      const newlySelectedOptionsValues = newSelectedOptions.map(
        (option) => option.value,
      );
      onChange({ target: { value: newlySelectedOptionsValues, name } });
    },
    [setSelectedOptions, _selectedOptions, options, onChange, name],
  );

  return (
    <div className="checkbox-group" {...commonProps}>
      {options.map((option, index) => (
        <Checkbox
          key={`${name}-${option.value}-${index}`}
          onChange={handleCheckboxChange}
          checked={!!_selectedOptions.find((o) => option.value === o.value)}
          name={`${option.value}`}
          label={option.label}
          hasError={option.hasError}
          readOnly={readOnly}
          disabled={disabled}
        />
      ))}
    </div>
  );
};
