import { z } from 'zod';

import {
  AppQueryFiltersSchema,
  AppQueryPaginationSchema,
  AppQuerySchema,
  AppQuerySortingSchema,
  FieldType,
  FieldTypeSchema,
  TimeDelta,
  TimeDeltaSchema,
} from '@nl-lms/common/shared';

export enum ReportStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  Failed = 'FAILED',
}

export const ReportQueryNotificationSchema = z.object({
  message: z.string(),
  subject: z.string(),
  title: z.string(),
});
export type ReportQueryNotification = z.infer<
  typeof ReportQueryNotificationSchema
>;

export const ReportTemplateNameSchema = z.enum([
  'all_learning_history',
  'all_learning_history_with_best_result',
  'live_sessions_by_department',
  'learning_programs',
  'learning_program_instances',
  'assessment_responses',
  'assessment_instances',
  'costs',
  'elearning_activity',
  'elearning_and_live_session_activity',
  'individual_learning_activity',
  'learners',
  'notifications',
  'learning_assignments',
  'learning_assignment_instances',
  'learning_paths',
  'learning_path_items',
  'live_session_attendance',
  'learning_programs',
  'learning_program_instances',
  'survey_responses',
  'emails',
  'survey_responses_grouped',
  'trainers',
  'all_courses',
  'customer_trend_consult_lp_by_company',
  'subscription_instances',
]);
export type ReportTemplateName = z.infer<typeof ReportTemplateNameSchema>;

// I think we should remove this
// since we're using it only on the client for some kind of type safety
export const ReportTemplateEntitySchema = z.enum([
  'course',
  'training_session',
  'training_session_learner',
  'elearning_course',
  'learner',
  'learning_path',
  'assessment',
  'learning_assignment',
  'learning_program',
  'trainer',
  'email',
  'individual_learning',
  'cost',
  'owner',
  'survey',
]);
export type ReportTemplateEntity = z.infer<typeof ReportTemplateEntitySchema>;

export const LoadOptionsKeySchema = z.enum([
  'fetchAndMapLiveCourses',
  'fetchAndMapElearningCourses',
  'fetchAndMapAssessmentForms',
  'fetchAndMapLearningPaths',
  'fetchAndMapVendors',
  'fetchAndMapAssignments',
  'fetchAndMapLiveSessions',
  'fetchAndMapLearners',
  'fetchAndMapReportTemplates',
  'fetchAndMapManagers',
  'fetchAndMapLearnerGroups',
  'fetchAndMapLearningPrograms',
  'fetchAndMapTags',
  'fetchAndMapTrainers',
  'fetchAndMapCompetencies',
  'fetchAndMapUsers',
  'fetchAndMapSurveys',
  'fetchAndMapSubscriptions',
]);
export type LoadOptionsKey = z.infer<typeof LoadOptionsKeySchema>;

export const SelectOptionsNameSchema = z.enum([
  'assessmentStatus',
  'elearningSessionStatus',
  'indiviualLearningType',
  'deliveryTypes',
  'learningPathStatus',
  'individualLearningTypes',
  'trainingSessionLearnerStatus',
  'languages',
  'learningUnitContentTypes',
  'learningUnitTypes',
  'allLearningTypes',
  'registrationTypes',
  'trainingSessionStatus',
  'learningProgramInstanceStatus',
  'surveyTypes',
  'learningTypes',
  'surveyStatus',
  'vendorTypes',
]);
export type SelectOptionsName = z.infer<typeof SelectOptionsNameSchema>;

export const ReportDataColumnOptionsSchema = z.union([
  z.object({
    // This is used to mask learner group id column in forms
    alwaysHide: z.boolean().optional(),
    loadOptions: LoadOptionsKeySchema,
  }),
  z.object({
    selectOptionsName: SelectOptionsNameSchema,
  }),
  z.object({
    parseAs: z.enum(['tag', 'competency', 'survey-answer', 'cost-type']),
  }),
]);

export type ReportDataColumnOptions = z.infer<
  typeof ReportDataColumnOptionsSchema
>;

export const ReportDataColumnSchema = z.object({
  name: z.string(),
  isFilterable: z.boolean(),
  isSortable: z.boolean().default(false),
  isVisible: z.boolean(),
  label: z.string(),
  type: FieldTypeSchema,
  options: ReportDataColumnOptionsSchema.optional(),
});
export type ReportDataColumn = z.infer<typeof ReportDataColumnSchema>;

export const ReportTemplateNotificationSchema = z.object({
  subject: z.string(),
  message: z.string(),
  title: z.string(),
});
export type ReportTemplateNotification = z.infer<
  typeof ReportTemplateNotificationSchema
>;

export const ReportTemplateSchema = z.object({
  id: z.string(),
  name: ReportTemplateNameSchema,
  targetLearnerGroupIds: z.array(z.string()),
  label: z.string(),
  description: z.string(),
  columns: z.array(ReportDataColumnSchema),
  query: AppQuerySchema.nullable(), // todo add AppQuery schema
  notification: ReportTemplateNotificationSchema,
  isDefaultTemplate: z.boolean(),
  withHistoricalLearnerData: z.boolean(),
  userId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ReportTemplate = z.infer<typeof ReportTemplateSchema>;

export const BaseReportQuerySchema = z.object({
  description: z.string(),
  name: ReportTemplateNameSchema,
  label: z.string(),
  recipientIds: z.array(z.string()),
  learnerId: z.string(),
  notification: ReportQueryNotificationSchema,
  columns: z.array(ReportDataColumnSchema),
  scheduleDate: z.date().nullable(),
  recurrenceDelta: TimeDeltaSchema.nullable(),
  recurrenceUntilDate: z.date().nullable(),
  withHistoricalLearnerData: z.boolean(),
  timezone: z.string(),
});

type BaseReportQuery = z.infer<typeof BaseReportQuerySchema> & {
  recurrenceDelta: TimeDelta;
};

export const ReportQuerySchema = z
  .object({
    query: AppQuerySchema.nullable(),
  })
  .merge(BaseReportQuerySchema);
export type ReportQuery = z.infer<typeof ReportQuerySchema>;

export const PaginatedReportQuerySchema = z
  .object({
    query: z.object({
      filters: AppQueryFiltersSchema.optional(),
      pagination: AppQueryPaginationSchema,
      sorting: AppQuerySortingSchema.optional(),
    }),
  })
  .merge(BaseReportQuerySchema);
export type PaginatedReportQuery = z.infer<typeof PaginatedReportQuerySchema>;

export const ReportSchema = z.object({
  id: z.string(),
  name: ReportTemplateNameSchema,
  label: z.string(),
  path: z.string(),
  query: ReportQuerySchema,
  status: z.nativeEnum(ReportStatus),
  userId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});
// using type instead of interface in order to prevent ts errors when implementing domain events
// https://stackoverflow.com/questions/60697214/how-to-fix-index-signature-is-missing-in-type-error
export type Report = z.infer<typeof ReportSchema>;

export const isReportDataColumn = (
  column: ReportDataColumn,
): column is ReportDataColumn => {
  if (!column.name || !column.label || !column.type) return false;
  if (
    ![
      FieldType.string,
      FieldType.select,
      FieldType.number,
      FieldType.jsonArraySelect,
      FieldType.arraySelect,
      FieldType.date,
      FieldType.datetime,
      FieldType.boolean,
    ].includes(column.type)
  )
    return false;
  if (!('isVisible' in column) || !('isFilterable' in column)) return false;
  if (column.options) {
    if (
      !('parseAs' in column.options) &&
      !('selectOptionsName' in column.options) &&
      !('loadOptions' in column.options)
    )
      return false;
    if (
      'parseAs' in column.options &&
      !['tag', 'competency', 'cost-type', 'survey-answer'].includes(
        column.options.parseAs,
      )
    )
      return false;
  }
  return true;
};
