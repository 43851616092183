import * as z from 'zod';

import {
  ElearningCourseSchema,
  LiveCourseSchema,
  LiveSessionSchema,
  SurveyFormLearningUnitSchema,
  SurveyFormSchema,
  SurveyFormType,
} from '@nl-lms/common/feature/types';

export const CreateSurveyFormLearningUnitBodySchema = z.object({
  surveyFormId: z.string(),
  elearningCourseId: z.string().optional(),
  trainingSessionId: z.string().optional(),
  courseId: z.string().optional(),
  type: z.nativeEnum(SurveyFormType).default(SurveyFormType.PublicRegistration),
});
export const CreateSurveyFormLearningUnitResponseSchema =
  SurveyFormLearningUnitSchema;

export const ListSurveyFormLearningUnitResponseSchema =
  CreateSurveyFormLearningUnitResponseSchema.extend({
    liveSession: LiveSessionSchema.pick({ id: true, name: true }).optional(),
    elearningCourse: ElearningCourseSchema.pick({
      id: true,
      name: true,
    }).optional(),
    liveCourse: LiveCourseSchema.pick({ id: true, name: true }).optional(),
    surveyForm: SurveyFormSchema.pick({ id: true, name: true }).optional(),
    url: z.string().optional(),
  });
