import { dateFns } from '@nl-lms/vendor';

type PrettyDateOptions = {
  withTime?: boolean;
  withDate?: boolean;
};

export const prettyDate = (
  value: string | Date | null | undefined,
  { withTime, withDate = true }: PrettyDateOptions = {},
) => {
  if (!value) return '';

  let dateFormat = '';
  if (withDate) dateFormat = 'dd MMM yyyy';
  if (withTime) dateFormat = `${dateFormat ? dateFormat + ' ' : ''}HH:mm`;

  return dateFns.format(new Date(value), dateFormat);
};

export enum PrettyDistanceType {
  Past = -1,
  Now = 0,
  Before = 1,
}

type ParsedDistance = {
  unit?: string;
  value?: string;
  est?: string;
};

export const prettyDistance = (
  date: string | Date,
  baseDate?: string,
): { content: string; type: PrettyDistanceType | null } => {
  if (!date) return { content: '', type: null };

  const _date = new Date(date);
  const _baseDate = baseDate ? new Date(baseDate) : new Date();

  const datecomp = dateFns.compareAsc(_date, _baseDate);

  let result;
  if (datecomp === PrettyDistanceType.Past) {
    result = dateFns.formatDistance(_date, _baseDate);
  } else {
    result = dateFns.formatDistance(_baseDate, _date);
  }

  return { content: result, type: datecomp };
};

export const parseDistance = (distance: string): ParsedDistance | null => {
  if (!distance) return null;
  if (distance === 'less than a minute') {
    return {
      est: 'lessthan',
      value: '1',
      unit: 'minute',
    };
  }

  const parseDistance = distance.split(' ');
  let parsed = {};

  if (parseDistance.length === 2) {
    const [value, unit] = parseDistance;
    parsed = { value, unit };
  } else if (parseDistance.length === 3) {
    const [est, value, unit] = parseDistance;
    parsed = { est, value, unit };
  }

  return parsed;
};

export const prettyDuration = (duration: number, unit = 'seconds') => {
  if (!duration) return '';
  const baseDate = dateFns.add(new Date(0), { [unit]: duration });
  return dateFns.format(baseDate, 'mm:ss');
};
