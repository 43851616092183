import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Checklist, ChecklistChecklistItem } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Icon,
  Input,
  Modal,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { useSubmitUpsertEntityFromSideModal } from '../../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { ImageUploadInput } from '../../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { TextEditorWithFileUpload } from '../../../../_common/modules/TextEditorWithFileUpload';
import { adminApi } from '../../../../_common/services/api/adminApi';
import { AdminCompetencySelect } from '../../AdminCompetency/AdminCompetencySelect';
import { AdminLearnerGroupMultiSelect } from '../../AdminLearner/AdminLearnerGroupSelect';
import { AdminTagSelect } from '../../AdminTag/AdminTagSelect';
import { ChecklistItemsSection } from './AdminChecklistEditFormItemsSection';
import './AdminChecklistEditFormSideModal.scss';

const { useListChecklistItemInstancesByChecklistIdQuery } = adminApi;

type Props = {
  checklist?: Partial<Checklist> & {
    items?: (ChecklistChecklistItem & {
      title: string;
      description?: string;
    })[];
  };
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  title: yup.string().required(),
  description: yup.string().nullable(),
  thumbnail: yup.string().nullable(),
  tagIds: yup.array(),
  competencyIds: yup.array(),
  targetLearnerGroupIds: yup.array(),
  items: yup.array(),
});

enum FormLocation {
  Details = 'Details',
  Content = 'Content',
}

const ChecklistDetailsSection = ({
  register,
  errors,
  control,
  checklist,
  getValues,
}) => (
  <>
    <Box margin={{ bottom: 'm' }}>
      <Typography.h2>Details</Typography.h2>
    </Box>
    <Box>
      <Box>
        <FormField label="Name" required errorMessage={errors?.name?.message}>
          <Input
            required
            {...register('name')}
            hasError={!!errors?.name?.message}
          />
        </FormField>
      </Box>
      <Box>
        <FormField label="Title" required errorMessage={errors?.title?.message}>
          <Input
            required
            {...register('title')}
            hasError={!!errors?.title?.message}
          />
        </FormField>
      </Box>
    </Box>
    <Box>
      <FormField
        label="Description"
        errorMessage={errors?.description?.message}
      >
        <Controller
          name="description"
          control={control}
          render={({ field }) => <TextEditorWithFileUpload {...field} />}
        />
      </FormField>
    </Box>
    <Box>
      <Box>
        <FormField label="Tags">
          <Controller
            name="tagIds"
            control={control}
            render={({ field }) => (
              <AdminTagSelect {...field} selectedItems={field.value} />
            )}
          />
        </FormField>
      </Box>
    </Box>
    <Box>
      <Box>
        <FormField label="Competencies">
          <Controller
            name="competencyIds"
            control={control}
            render={({ field }) => (
              <AdminCompetencySelect {...field} selectedItems={field.value} />
            )}
          />
        </FormField>
      </Box>
    </Box>
    <Box>
      <Box>
        <FormField label="Learner Visibility">
          <Controller
            name="targetLearnerGroupIds"
            control={control}
            render={({ field }) => (
              <AdminLearnerGroupMultiSelect
                {...field}
                selectedItems={field.value}
              />
            )}
          />
        </FormField>
      </Box>
    </Box>
    <Box>
      <Box>
        <FormField label="Thumbnail" errorMessage={errors?.thumbnail?.message}>
          <Controller
            name="thumbnail"
            control={control}
            render={({ field }) => (
              <ImageUploadInput
                {...field}
                initialS3Url={checklist.thumbnail || getValues('thumbnail')}
              />
            )}
          />
        </FormField>
      </Box>
    </Box>
  </>
);

const CurrentSectionComponent = ({
  formLocation,
  control,
  hasInstances,
  ...props
}: {
  formLocation: FormLocation;
  register: any;
  errors: any;
  control: any;
  checklist: any;
  getValues: any;
  setError: any;
  hasInstances: boolean;
}) => {
  if (hasInstances) {
    return <ChecklistDetailsSection control={control} {...props} />;
  }
  switch (formLocation) {
    case FormLocation.Details:
      return <ChecklistDetailsSection control={control} {...props} />;
    case FormLocation.Content:
      return <ChecklistItemsSection control={control} {...props} />;
    default:
      return null;
  }
};

const CurrentSectionNavigation = ({
  formLocation,
  setFormLocation,
  hasInstances = false,
}: {
  formLocation: FormLocation;
  setFormLocation: any;
  hasInstances?: boolean;
}) => {
  if (hasInstances) {
    return null;
  }
  switch (formLocation) {
    case FormLocation.Details:
      return (
        <Button
          regular
          onClick={() => setFormLocation(FormLocation.Content)}
          label="Content"
          icon={<Icon.ArrowRightIcon />}
        />
      );
    case FormLocation.Content:
      return (
        <Button
          regular
          onClick={() => setFormLocation(FormLocation.Details)}
          label="Details"
          icon={<Icon.ArrowLeftIcon />}
        />
      );
    default:
      return null;
  }
};

export const AdminChecklistEditFormSideModal = ({ checklist = {} }: Props) => {
  const [formLocation, setFormLocation] = useState(FormLocation.Details);
  const {
    watch,
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ..._.omit(checklist, 'id'),
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateChecklistMutation',
    createHookName: 'useCreateChecklistMutation',
    entityId: checklist.id,
  });
  const checklistId = useMemo(() => checklist?.id, [checklist]);
  let hasInstances = false;
  if (checklistId) {
    const { data: checklistItems, isLoading } =
      useListChecklistItemInstancesByChecklistIdQuery(checklistId);
    // @ts-ignore
    if (checklistItems?.length) {
      hasInstances = true;
    }
  }

  const formItems = watch('items');
  const isSubmitDisabled = useMemo(
    () => !formItems?.length && !checklist?.checklistItems?.length,
    [formItems, checklist],
  );

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {checklistId ? 'Edit Checklist' : 'Create New Checklist'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <CurrentSectionComponent
          formLocation={formLocation}
          register={register}
          errors={errors}
          control={control}
          checklist={checklist}
          getValues={getValues}
          setError={setError}
          hasInstances={hasInstances}
        />
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <CurrentSectionNavigation
          formLocation={formLocation}
          setFormLocation={setFormLocation}
          hasInstances={hasInstances}
        />
        <Button
          label={checklistId ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          disabled={isSubmitDisabled}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
