import React, {
  CSSProperties,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { useClassNameProps, useHotkey, useTestProps } from '@nl-lms/ui/hooks';

import * as Icon from '../Icon';
import { Separator } from '../Separator/Separator';
import { Tooltip } from '../Tooltip/Tooltip';
import { Typography } from '../Typography/Typography';
import { TidComponent } from '../index.types';
import './Card.scss';

const CardContext = createContext<{
  isExpanded: boolean;
  expand: () => void;
  shrink: () => void;
  // @ts-ignore
}>(null);

type Props = TidComponent<{
  className?: string;
  children: any;
  paddingType?: 'none' | 'large' | 'medium' | 'small';
  style?: CSSProperties;
}>;

export const Card = (props: Props) => {
  const { children, paddingType = 'none', style = {} } = props;
  const testProps = useTestProps(props);
  const classNameProps = useClassNameProps(
    'card',
    props,
    `card--padding-${paddingType}`,
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const cardPositionRef = useRef<HTMLDivElement>(null);
  const expand = useCallback(() => {
    setIsExpanded(true);
  }, []);
  const shrink = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const shrinkOnEscKeyDown = useCallback(() => {
    if (isExpanded) shrink();
  }, [isExpanded]);
  useHotkey('esc', shrinkOnEscKeyDown);

  const expandedStyle = useMemo<CSSProperties>(() => {
    const variables = {
      '--card-top': '0',
      '--card-left': '0',
      '--card-width': '100vw',
      '--card-height': '100vh',
    };

    if (cardPositionRef?.current) {
      const clientRect = cardPositionRef?.current.getBoundingClientRect();
      variables['--card-top'] = `${clientRect.top}px`;
      variables['--card-left'] = `${clientRect.left}px`;
      variables['--card-width'] = `${clientRect.width}px`;
      variables['--card-height'] = `${clientRect.height}px`;
    }
    return {
      ...variables,
      position: 'fixed',
      width: 'calc(100vw - 60px)',
      height: 'calc(100vh - 60px)',
      zIndex: '10',
      top: '60px',
      left: '60px',
      animation: 'expand 0.25s ease',
    };
  }, [isExpanded]);

  return (
    <CardContext.Provider value={{ isExpanded, shrink, expand }}>
      {isExpanded ? (
        <CardPortal>
          <div
            style={{ ...style, ...expandedStyle }}
            {...classNameProps}
            {...testProps}
          >
            {children}
          </div>
        </CardPortal>
      ) : (
        <div
          style={style}
          ref={cardPositionRef}
          {...classNameProps}
          {...testProps}
        >
          {children}
        </div>
      )}
    </CardContext.Provider>
  );
};

const CardPortal = ({ children }) => {
  // @ts-ignore
  return createPortal(children, document.getElementById('root'));
};

const CardExpandAction = (props: TidComponent<{}>) => {
  const { expand, shrink, isExpanded } = useContext(CardContext);

  if (isExpanded) {
    return (
      <CardHeaderAction onClick={shrink} {...props}>
        <Tooltip title="Shrink">
          <Icon.MinimizeIcon />
        </Tooltip>
      </CardHeaderAction>
    );
  }
  return (
    <CardHeaderAction onClick={expand} {...props}>
      <Tooltip title="Maximize">
        <Icon.MaximizeIcon />
      </Tooltip>
    </CardHeaderAction>
  );
};
const CardContent = ({
  children,
  paddingType = 'medium',
  fullHeight = false,
  // Used to bypass the height issue on reports/analytics
  style = {},
  ...props
}: TidComponent<{
  children: React.ReactNode;
  paddingType?: 'small' | 'medium' | 'large' | 'none';
  fullHeight?: boolean;
  className?: string;
  style?: CSSProperties;
}>) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps(
    'card__content',
    props,
    `card__content--${paddingType}`,
    fullHeight ? 'card__content--full-height' : null,
  );
  return (
    <div {...classNameProps} {...commonProps} style={style}>
      {children}
    </div>
  );
};

const CardActions = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__actions" {...commonProps}>
      {children}
    </div>
  );
};

const CardHeader = ({
  children,
  separatorMarginTop = 10,
  separatorMarginBottom = 20,
  withSeparator = true,
  noActions = false,
  ...props
}) => {
  const commonProps = useTestProps(props);
  const cardContentClassNameProps = useClassNameProps(
    'card__header-content',
    noActions ? ' card__header-content--no-actions' : null,
  );
  return (
    <div className="card__header" {...commonProps}>
      <div {...cardContentClassNameProps}>{children}</div>
      {withSeparator && (
        <Separator
          marginTop={separatorMarginTop}
          marginBottom={separatorMarginBottom}
        />
      )}
    </div>
  );
};

const CardHeaderTitle = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('card__header-title', props);

  return (
    <div {...classNameProps} {...commonProps}>
      <Typography.h3>{children}</Typography.h3>
    </div>
  );
};

const CardHeaderActions = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__header-actions" {...commonProps}>
      {children}
    </div>
  );
};
const CardHeaderAction = ({
  children,
  // @ts-ignore
  onClick = () => {},
  label = '',
  ...props
}: {
  children: ReactNode;
  onClick?: () => void;
  label?: string;
}) => {
  const commonProps = useTestProps(props);
  if (label) {
    return (
      <Tooltip
        title={label}
        className="card__header-action"
        // @ts-ignore
        onClick={onClick}
        offsetToChildren={30}
        {...props}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    // @ts-ignore
    <div className="card__header-action" onClick={onClick} {...commonProps}>
      {children}
    </div>
  );
};

const CardBody = ({ children, noPadding = false, ...props }) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps(
    'card__body',
    noPadding ? 'card__body--no-padding' : null,
  );

  return (
    <div {...classNameProps} {...commonProps}>
      {children}
    </div>
  );
};

const CardHeaderTitleSkeleton = (props) => {
  const commonProps = useTestProps(props);
  return (
    <div
      className="card__skeleton card__header-title-skeleton"
      {...commonProps}
    >
      &zwnj;
    </div>
  );
};

const CardBodySkeleton = (props) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__skeleton card__body-skeleton" {...commonProps}>
      &zwnj;
    </div>
  );
};

const CardList = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__list" {...commonProps}>
      {children}
    </div>
  );
};

const CardListItem = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__list-item" {...commonProps}>
      {children}
    </div>
  );
};

const CardListItemHeader = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__list-item-header" {...commonProps}>
      {children}
    </div>
  );
};

const CardListItemTitle = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <Typography.p className="card__list-item-title" {...commonProps}>
      {children}
    </Typography.p>
  );
};

const CardListItemActions = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__list-item-actions" {...commonProps}>
      {children}
    </div>
  );
};

const CardListItemHoverableAction = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__list-item-hoverable-action" {...commonProps}>
      {children}
    </div>
  );
};

const CardListItemDescription = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <Typography.p className="card__list-item-description" {...commonProps}>
      {children}
    </Typography.p>
  );
};

const CardFilters = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="card__filters" {...commonProps}>
      {children}
    </div>
  );
};

const CardHeaderTooltip = ({ title, ...props }) => {
  const commonProps = useTestProps(props);
  if (!title) return null;

  return (
    <div className="card__header-tooltip" {...commonProps}>
      <Tooltip title={title} className="card__header-tooltip-trigger">
        <Icon.HelpIcon />
      </Tooltip>
    </div>
  );
};

Card.Content = CardContent;
Card.Header = CardHeader;
Card.HeaderActions = CardHeaderActions;
Card.HeaderAction = CardHeaderAction;
Card.HeaderTitle = CardHeaderTitle;
Card.HeaderTooltip = CardHeaderTooltip;
Card.Body = CardBody;
Card.Actions = CardActions;
Card.HeaderTitleSkeleton = CardHeaderTitleSkeleton;
Card.BodySkeleton = CardBodySkeleton;
Card.List = CardList;
Card.ListItem = CardListItem;
Card.ListItemHeader = CardListItemHeader;
Card.ListItemTitle = CardListItemTitle;
Card.ListItemActions = CardListItemActions;
Card.ListItemDescription = CardListItemDescription;
Card.ListItemHoverableAction = CardListItemHoverableAction;
Card.Filters = CardFilters;
Card.ExpandAction = CardExpandAction;
