import Axios from 'axios';
import { useCallback, useState } from 'react';

import { C } from '@nl-lms/ui/constants';

import { useApi } from '../services/api';

const axios = Axios.create();
delete axios.defaults.headers.put['Content-Type'];

type UploadFileProps = {
  // In MB
  maxSize?: number;
  type: 'content' | 'thumbnail' | 'text_editor_asset';
};
export const useUploadFile = ({ type, maxSize = 5 }: UploadFileProps) => {
  const uploadFileWithSignedUrl = useUploadFileWithSignedUrl();
  const uploadFileThroughApi = useUploadFileThroughApi();
  const [src, setSrc] = useState('');
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onUpload = useCallback(
    async (file: File) => {
      const fileSizeInMb = file.size / (1024 * 1024);
      if (fileSizeInMb > maxSize) {
        setErrorMessage(
          `Invalid file size. Maximum image size is ${maxSize} MB`,
        );
        return '';
      }
      setIsLoading(true);
      setErrorMessage('');
      try {
        const fileUrl =
          C.UPLOAD_STRATEGY === 'direct'
            ? await uploadFileWithSignedUrl(file, type)
            : await uploadFileThroughApi(file, type);
        setFileName(file.name);
        setErrorMessage('');
        setSrc(fileUrl);
        setIsLoading(false);
        return fileUrl;
      } catch (e) {
        setIsLoading(false);
        setErrorMessage(`Unable to upload file`);
        return '';
      }
    },
    [type, maxSize],
  );

  return {
    onUpload,
    src,
    fileName,
    errorMessage,
    isLoading,
  };
};

const useUploadFileWithSignedUrl = () => {
  const api = useApi();

  return useCallback(async (file, type) => {
    const { putSignedUrl, fileUrl } = await api.common.getSignedPutUrl(
      file.name,
      type,
    );
    await axios.put(putSignedUrl, file, {
      headers: { 'Content-Type': file.type },
    });
    return fileUrl;
  }, []);
};

const useUploadFileThroughApi = () => {
  const api = useApi();
  return useCallback(async (file, type) => {
    const { fileUrl } = await api.common.uploadFile(type, file);
    return fileUrl;
  }, []);
};
