import store from 'store';

import { getClients } from '@nl-lms/feature/registration/sdk';

import { apiBaseUrl } from './api';

const { raw: registrationApiClient, rtk: registrationRtkClient } = getClients({
  baseUrl: `${apiBaseUrl('v2')}`,
  tokenGetter: () => store.get('accessToken'),
});

export { registrationApiClient };

export const registrationApi = registrationRtkClient;
registrationApi.enhanceEndpoints<'Registration' | 'RegistrationForm'>({
  endpoints: {
    blockRegistrations: {
      invalidatesTags: [{ type: 'Registration', id: 'LIST' }],
    },
    completeRegistrations: {
      invalidatesTags: [{ type: 'Registration', id: 'LIST' }],
    },
    confirmRegistrationEmail: {
      invalidatesTags: [{ type: 'Registration', id: 'LIST' }],
    },
    listRegistrations: { providesTags: [{ type: 'Registration', id: 'LIST' }] },
    declineRegistrations: {
      invalidatesTags: [{ type: 'Registration', id: 'LIST' }],
    },
    markRegistrationsInReview: {
      invalidatesTags: [{ type: 'Registration', id: 'LIST' }],
    },
    register: {
      invalidatesTags: [{ type: 'Registration', id: 'LIST' }],
    },
    getRegistrationPublicData: {
      providesTags: (result, error, arg) => [
        { type: 'RegistrationForm', id: result?.id },
      ],
    },
  },
});
