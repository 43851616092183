import { zodResolver } from '@hookform/resolvers/zod';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { LearningAssignmentRule } from '@nl-lms/common/feature/types';
import { LearningAssignment } from '@nl-lms/feature/learning-assignments/sdk';
import {
  Box,
  Button,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { LearningAssignmentRulesListForm } from './LearningAssignmentRulesListForm';
import { LearningAssignmentRulesFormBaseSchema } from './utils/types';
import { parseFormRulesToAssignmentsPayload } from './utils/utils';

const LearningAssignmentRulesCreateBaseSchema = z.object({
  rules: z.array(
    z.union([
      //  when there are existing rules that will be taken out of the create payload
      z.object({
        id: z.string(),
      }),
      LearningAssignmentRulesFormBaseSchema,
    ]),
  ),
});

export const LearningAssignmentRulesCreateSideModal = (props: {
  currentRules?: LearningAssignmentRule[];
  currentAssignment?: LearningAssignment;
  onSubmit?: () => Promise<unknown>;
}) => {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(LearningAssignmentRulesCreateBaseSchema),
    mode: 'onSubmit',
    defaultValues: {
      rules: props?.currentRules ?? [],
    },
  });

  const { hide } = useModal();
  const {
    onSubmit: onSubmitAssignmentRules,
    isLoading,
    error,
  } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useAddLearningAssignmentRulesMutation',
    updateHookName: 'useUpdateLearningAssignmentRuleMutation',
    onSuccess: hide,
  });

  const onSubmit = async (payload) => {
    let parsedPayload = {
      rules: payload?.rules?.filter((rule) => !rule?.id),
    };
    if (parsedPayload?.rules && parsedPayload?.rules?.length) {
      parsedPayload = parsedPayload?.rules?.map((ruleData) => {
        const rulePayload = {
          id: ruleData?.entityId,
          createPlannedInstances: ruleData?.createPlannedInstances,
          rule: parseFormRulesToAssignmentsPayload(ruleData),
        };
        return rulePayload;
      });
    }
    await onSubmitAssignmentRules({ payload: parsedPayload });
    await props?.onSubmit?.();
  };

  const errorMessage = useMemo(() => {
    if (errors?.rules?.length) {
      return `${errors?.rules?.length} rules have incorrect data`;
    }
    return null;
  }, [errors, error]);

  const rules = watch('rules');
  const isSubmitDisabled = useMemo(
    () => rules?.every((rule) => !!rule?.id),
    [rules],
  );

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Create Assignation Rules</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Controller
          name="rules"
          control={control}
          render={({ field }) => (
            <LearningAssignmentCreateRulesForm
              currentRules={rules ?? []}
              currentAssignment={props?.currentAssignment || null}
              field={field}
            />
          )}
        />
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error) || <>{errorMessage}</>}
        </SideModal.Error>
        <div id="add-rule-button" />
        <Button
          label={props?.currentAssignment ? 'Update rules' : 'Create rules'}
          disabled={isSubmitDisabled}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};

const LearningAssignmentCreateRulesForm = (props) => (
  <>
    <Box margin={{ bottom: 'l' }}>
      <Typography.p type="muted">
        Use rules to dynamically create new learning assignment instances base
        on events that occur during the learning process. Check{' '}
        <a
          href="https://docs.niftylearning.io/lms/learning-assignments/planning-and-scheduling/"
          target="_blank"
        >
          the documentation
        </a>{' '}
        for more info on how rules work.
      </Typography.p>
    </Box>
    <LearningAssignmentRulesListForm
      {...props?.field}
      {...props}
      showAdvanced={false}
    />
  </>
);
