import { yupResolver } from '@hookform/resolvers/yup';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ExtendedLearner } from '@nl-lms/feature/organization/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  Sensitive,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';
import { organizationApi } from '@nl-lms/web/_common/services/api';

import { AdminLearnerSingleSelect } from './AdminLearnerSelect';

const { useUpdateLearnerMutation, useCreateLearnerMutation } = organizationApi;

type Props = {
  learner?: ExtendedLearner;
};

const parseLearner = (learnerPayload) => {
  const detailsFields = C.LEARNER_PROPERTIES.map((prop) => prop.name);
  const customerInternalId =
    learnerPayload.customerInternalId || learnerPayload.email;
  return {
    firstName: learnerPayload.firstName,
    lastName: learnerPayload.lastName,
    email: learnerPayload.email,
    customerInternalId,
    managerId: learnerPayload.managerId,
    details: detailsFields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: learnerPayload[field],
      }),
      {},
    ),
  };
};

const getLearnerManager = (learner?: ExtendedLearner) => {
  if (!learner?.hierarchicManagers?.length)
    return { managerId: '', managerName: '' };
  if (!learner.hierarchicManagers || !learner.hierarchicManagers.length)
    return { managerId: '', managerName: '' };
  return {
    managerId: learner.hierarchicManagers[0].id,
    managerName: `${learner.hierarchicManagers[0].firstName} ${learner.hierarchicManagers[0].lastName}`,
  };
};

export const AdminLearnerEditFormSideModal = ({ learner }: Props) => {
  const Schema = useMemo(() => {
    const detailsSchema = C.LEARNER_PROPERTIES.reduce(
      (acc, prop) => ({
        ...acc,
        [prop.name]: z.string(),
      }),
      {},
    );

    return z.object({
      firstName: z.string().min(1, { message: 'Field is required' }),
      lastName: z.string().min(1, { message: 'Field is required' }),
      email: z.string().email(),
      customerInternalId: z.string(),
      managerId: z.string().nullish(),
      ...detailsSchema,
    });
  }, []);
  const detailsFields = C.LEARNER_PROPERTIES.map((prop) => prop.name);
  const learnerManager = getLearnerManager(learner);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...learner,
      ...learnerManager,
      ...detailsFields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: learner?.details ? learner.details[field] : '',
        }),
        {},
      ),
    },
  });
  const { hide } = useModal();

  const [updateLearner, { error: updateError, isLoading: isUpdateLoading }] =
    useUpdateLearnerMutation();
  const [createLearner, { error: createError, isLoading: isCreateLoading }] =
    useCreateLearnerMutation();
  const onSubmit = useCallback(
    async (entity) => {
      const parsedLearner = parseLearner(entity);
      let isSuccess = false;
      if (learner?.id) {
        const result = await updateLearner({
          id: learner.id,
          ...parsedLearner,
        });
        isSuccess = 'data' in result;
      } else {
        const result = await createLearner(parsedLearner);
        isSuccess = 'data' in result;
      }

      if (isSuccess) {
        hide();
      }
    },
    [learner],
  );

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {learner?.id ? 'Edit Learner' : 'Create New Learner'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>General</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="First Name"
              required
              errorMessage={errors?.firstName?.message as string | undefined}
            >
              <Sensitive>
                <Input
                  required
                  {...register('firstName')}
                  hasError={!!errors?.firstName?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Last Name"
              errorMessage={errors?.lastName?.message as string | undefined}
              required
            >
              <Sensitive>
                <Input
                  {...register('lastName')}
                  hasError={!!errors?.lastName?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="Email"
              errorMessage={errors?.email?.message as string | undefined}
            >
              <Sensitive>
                <Input
                  type="email"
                  {...register('email')}
                  required
                  hasError={!!errors?.email?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <Box>
              <FormField
                label="Customer Internal ID"
                errorMessage={
                  errors?.customerInternalId?.message as string | undefined
                }
              >
                <Sensitive>
                  <Input
                    {...register('customerInternalId')}
                    required
                    hasError={!!errors?.customerInternalId?.message}
                  />
                </Sensitive>
              </FormField>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Manager">
              <Controller
                name="managerId"
                control={control}
                render={({ field }) => (
                  <AdminLearnerSingleSelect
                    {...field}
                    initialSelectedItem={
                      learnerManager.managerId
                        ? {
                            value: learnerManager.managerId,
                            label: learnerManager.managerName,
                          }
                        : undefined
                    }
                    returnEntireItemOnChange={false}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Details</Typography.h2>
        </Box>
        {C.LEARNER_PROPERTIES.map((prop, index) => (
          <Box key={`${prop.name}-${index}`}>
            <FormField label={prop.fieldName}>
              <Sensitive>
                <Input {...register(prop.name)} />
              </Sensitive>
            </FormField>
          </Box>
        ))}
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(updateError)}
          {getTranslatedMessageFromError(createError)}
        </SideModal.Error>
        <Button
          label={learner?.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isUpdateLoading || isCreateLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
