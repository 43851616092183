import { createApi } from '@reduxjs/toolkit/query/react';

import {
  ApiQueryFilter,
  AppQuery,
  AppQueryFilter,
} from '@nl-lms/common/shared';
import {
  ActivityLogItem,
  AdminSearchResult,
  Assessment,
  AssessmentFormItem,
  AssessmentInstance,
  AssessmentQuestion,
  AutomatedAction,
  CancelledSessionsReport,
  Checklist,
  ChecklistChecklistItemInstance,
  ChecklistInstance,
  ChecklistItem,
  Competency,
  CostPerLearnerReport,
  CostType,
  ElearningSession,
  EmailItem,
  IndividualLearning,
  IndividualLearningInstance,
  Learner,
  LearnerLearningActivity,
  LearnerTimeSpentLearning,
  LearningPath,
  LearningPathInstance,
  LearningPathLearnerInstance,
  ListResponse,
  LiveCourse,
  LiveSession,
  LiveSessionLearner,
  LiveSessionStatistics,
  MandatoryAssignmentsReport,
  MostAccessedSessionReport,
  MostActiveUserReport,
  SessionActivityProgressReport,
  SessionFillRateReport,
  Survey,
  SurveyInstance,
  SurveyInstanceQuestion,
  Tag,
  TrainedLearnersReport,
  Trainer,
  TrainerCourseStatistics,
  TrainerStatistics,
  Vendor,
  Venue,
} from '@nl-lms/sdk/backend';

import { axiosBaseQuery } from './axiosBaseQuery';
import { getTagsFunctions } from './getTagsFunctions';

const AdminTagTypes = [
  'Trainer',
  'Venue',
  'Vendor',
  'CostType',
  'Survey',
  'SurveyInstance',
  'SurveyInstanceQuestion',
  'Competency',
  'LiveSessionLearner',
  'Tag',
  'AssessmentQuestion',
  'ElearningSession',
  'ElearningCourse',
  'LiveCourse',
  'Learner',
  'LiveSession',
  'LearningPath',
  'LearningPathInstance',
  'Assessment',
  'LearnerLearningActivity',
  'Search',
  'LearningApproval',
  'AssessmentInstance',
  'AssessmentInstanceAttempt',
  'MandatoryAssignmentsReport',
  'MostAccessedSessionReport',
  'MostActiveUserReport',
  'SessionActivityProgressReport',
  'TrainedLearnersReport',
  'SessionFillRateReport',
  'CancelledSessionsReport',
  'CostPerLearnerReport',
  'LearnerTimeSpentLearningReport',
  'LearnerLearningProgressReport',
  'ActivityLog',
  'LiveSessionLearner',
  'Email',
  'EmailTemplate',
  'LiveSessionAutomatedAction',
  'IndividualLearning',
  'AssessmentFormItem',
  'LiveSessionStatisticsReport',
  'Checklist',
  'ChecklistItem',
  'ChecklistInstance',
  'ChecklistChecklistItemInstance',
  'User',
  'BudgetSpendingReport',
  'CostBreakdownReport',
] as const;

const { getProvidesTagsFunction, getInvalidatesTagsFunction } =
  getTagsFunctions<(typeof AdminTagTypes)[number]>();

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: AdminTagTypes,
  endpoints: (builder) => ({
    // Auth
    resetPassword: builder.mutation<boolean, string>({
      query: (email) => ({
        service: 'user',
        method: 'resetPassword',
        params: email,
      }),
      invalidatesTags: getInvalidatesTagsFunction('User'),
    }),
    // Trainer
    listTrainers: builder.query<
      ListResponse<Omit<Trainer, 'courses'>>,
      AppQuery
    >({
      query: (query) => ({
        service: 'trainer',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Trainer'),
    }),
    updateTrainer: builder.mutation<boolean, Partial<Trainer>>({
      query: (trainer) => ({
        service: 'trainer',
        method: 'update',
        params: trainer,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Trainer'),
    }),
    createTrainer: builder.mutation<boolean, Partial<Trainer>>({
      query: (trainer) => ({
        service: 'trainer',
        method: 'create',
        params: trainer,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Trainer'),
    }),
    removeTrainers: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'trainer',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Trainer'),
    }),
    getTrainer: builder.query<Trainer, string>({
      query: (id) => ({
        service: 'trainer',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Trainer'),
    }),
    getTrainerStatistics: builder.query<
      Trainer & {
        trainedMinutes: number | null;
        trainedLearners: number | null;
        deliveredSessions: number | null;
        canceledSessions: number | null;
      },
      string
    >({
      query: (id) => ({
        service: 'trainer',
        method: 'statistics',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Trainer'),
    }),
    getAllTrainersStatistics: builder.query<TrainerStatistics, AppQuery>({
      query: (query) => ({
        service: 'trainer',
        method: 'allStatistics',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Trainer'),
    }),
    getTrainersCourseStatistics: builder.query<
      TrainerCourseStatistics,
      AppQuery
    >({
      query: (query) => ({
        service: 'trainer',
        method: 'courseStatistics',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Trainer'),
    }),
    listTrainersWithSessions: builder.query<
      ListResponse<
        Trainer & { fullName: string; trainingSessions: LiveSession[] }
      >,
      AppQuery
    >({
      query: (query) => ({
        service: 'trainer',
        method: 'listWithSessions',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Trainer'),
    }),

    // Venue
    listVenues: builder.query<ListResponse<Venue>, AppQuery>({
      query: (query) => ({
        service: 'room',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Venue'),
    }),
    updateVenue: builder.mutation<boolean, Partial<Venue>>({
      query: (venue) => ({
        service: 'room',
        method: 'update',
        params: venue,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Venue'),
    }),
    createVenue: builder.mutation<boolean, Partial<Venue>>({
      query: (venue) => ({
        service: 'room',
        method: 'create',
        params: venue,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Venue'),
    }),
    removeVenues: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'room',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Venue'),
    }),
    getVenue: builder.query<string, Venue>({
      query: (id) => ({
        service: 'room',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Venue'),
    }),

    // Vendor
    listVendors: builder.query<ListResponse<Vendor>, AppQuery>({
      query: (query) => ({
        service: 'vendor',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Vendor'),
    }),
    listVendorsForSelect: builder.query<Vendor[], never>({
      query: () => ({
        service: 'vendor',
        method: 'listForSelect',
        params: '',
      }),
      providesTags: getProvidesTagsFunction('Vendor', 'LIST_FOR_SELECT'),
    }),
    updateVendor: builder.mutation<boolean, Partial<Vendor>>({
      query: (vendor) => ({
        service: 'vendor',
        method: 'update',
        params: vendor,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Vendor'),
    }),
    createVendor: builder.mutation<boolean, Partial<Vendor>>({
      query: (vendor) => ({
        service: 'vendor',
        method: 'create',
        params: vendor,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Vendor'),
    }),
    removeVendors: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'vendor',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Vendor'),
    }),
    getVendor: builder.query<Vendor, string>({
      query: (id) => ({
        service: 'vendor',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Vendor'),
    }),

    // Tag
    listTags: builder.query<ListResponse<Tag>, AppQuery>({
      query: (query) => ({
        service: 'tag',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Tag'),
    }),
    listTagsForSelect: builder.query<Tag[], string>({
      query: (query: string = '') => ({
        service: 'tag',
        method: 'listForSelect',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Tag', 'LIST_FOR_SELECT'),
    }),
    updateTag: builder.mutation<boolean, Partial<Tag>>({
      query: (tag) => ({
        service: 'tag',
        method: 'update',
        params: tag,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Tag'),
    }),
    createTag: builder.mutation<Tag, Partial<Tag>>({
      query: (tag) => ({
        service: 'tag',
        method: 'create',
        params: tag,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Tag'),
    }),
    removeTags: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'tag',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Tag'),
    }),
    getTag: builder.query<Tag, string>({
      query: (id) => ({
        service: 'tag',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Tag'),
    }),

    // Competency
    listCompetencies: builder.query<ListResponse<Competency>, AppQuery>({
      query: (query) => ({
        service: 'competency',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Competency'),
    }),
    listCompetenciesForSelect: builder.query<Competency[], never>({
      query: (query) => ({
        service: 'competency',
        method: 'listForSelect',
        params: null,
      }),
      providesTags: getProvidesTagsFunction('Competency', 'LIST_FOR_SELECT'),
    }),
    updateCompetency: builder.mutation<boolean, Partial<Competency>>({
      query: (competency) => ({
        service: 'competency',
        method: 'update',
        params: competency,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Competency'),
    }),
    createCompetency: builder.mutation<Competency, Partial<Competency>>({
      query: (competency) => ({
        service: 'competency',
        method: 'create',
        params: competency,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Competency'),
    }),
    removeCompetencies: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'competency',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Competency'),
    }),
    getCompetency: builder.query<Competency, string>({
      query: (id) => ({
        service: 'competency',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Competency'),
    }),

    // CostType
    listCostTypes: builder.query<ListResponse<CostType>, AppQuery>({
      query: (query) => ({
        service: 'costType',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('CostType'),
    }),
    updateCostType: builder.mutation<boolean, Partial<CostType>>({
      query: (costType) => ({
        service: 'costType',
        method: 'update',
        params: costType,
      }),
      invalidatesTags: getInvalidatesTagsFunction('CostType'),
    }),
    createCostType: builder.mutation<boolean, Partial<CostType>>({
      query: (costType) => ({
        service: 'costType',
        method: 'create',
        params: costType,
      }),
      invalidatesTags: getInvalidatesTagsFunction('CostType'),
    }),
    removeCostTypes: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'costType',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('CostType'),
    }),
    getCostType: builder.query<CostType, string>({
      query: (id) => ({
        service: 'costType',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('CostType'),
    }),

    // AssessmentQuestion
    listAssessmentQuestions: builder.query<
      ListResponse<AssessmentQuestion>,
      AppQuery
    >({
      query: (query) => ({
        service: 'assessmentQuestion',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('AssessmentQuestion'),
    }),
    listAssessmentQuestionsForSelect: builder.query<
      Partial<AssessmentQuestion>[],
      string
    >({
      query: (searchQuery) => ({
        service: 'assessmentQuestion',
        method: 'listForSelect',
        params: searchQuery,
      }),
      providesTags: getProvidesTagsFunction(
        'AssessmentQuestion',
        'LIST_FOR_SELECT',
      ),
    }),
    updateAssessmentQuestion: builder.mutation<
      boolean,
      Partial<AssessmentQuestion>
    >({
      query: (assessmentQuestion) => ({
        service: 'assessmentQuestion',
        method: 'update',
        params: assessmentQuestion,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentQuestion'),
    }),
    importAssessmentQuestions: builder.mutation<
      boolean,
      { file: File; questionType: 'assessment' | 'survey' }
    >({
      query: (file) => ({
        service: 'assessmentQuestion',
        method: 'import',
        params: file,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentQuestion'),
    }),
    createAssessmentQuestion: builder.mutation<
      boolean,
      Partial<AssessmentQuestion>
    >({
      query: (assessmentQuestion) => ({
        service: 'assessmentQuestion',
        method: 'create',
        params: assessmentQuestion,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentQuestion'),
    }),
    removeAssessmentQuestions: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'assessmentQuestion',
        method: 'removeQuestions',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentQuestion'),
    }),
    analyzeAssessmentQuestionImport: builder.mutation<
      any,
      { file: any; questionType: string }
    >({
      query: (params) => ({
        service: 'assessmentQuestion',
        method: 'analyzeImport',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentQuestion'),
    }),
    getAssessmentQuestion: builder.query<AssessmentQuestion, string>({
      query: (id) => ({
        service: 'assessmentQuestion',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('AssessmentQuestion'),
    }),
    exportAssessmentQuestions: builder.query<any, AppQuery>({
      query: (id) => ({
        service: 'assessmentQuestion',
        method: 'export',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('AssessmentQuestion'),
    }),
    downloadAssessmentQuestionsImportTemplate: builder.query<
      any,
      { questionType: string }
    >({
      query: ({ questionType }) => ({
        service: 'assessmentQuestion',
        method: 'downloadImportTemplate',
        params: { questionType },
      }),
      providesTags: getProvidesTagsFunction('AssessmentQuestion'),
    }),

    // Survey
    listSurveys: builder.query<ListResponse<Survey>, AppQuery>({
      query: (query) => ({
        service: 'survey',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Survey'),
    }),
    listSurveysForSelect: builder.query<Survey[], string>({
      query: (query = '') => ({
        service: 'survey',
        method: 'listForSelect',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Survey', 'LIST_FOR_SELECT'),
    }),
    listSurveyInstances: builder.query<ListResponse<SurveyInstance>, AppQuery>({
      query: (query) => ({
        service: 'survey',
        method: 'listInstances',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('SurveyInstance'),
    }),
    removeSurveyInstances: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'survey',
        method: 'bulkDeleteInstance',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('SurveyInstance'),
    }),
    listSurveyInstancesQuestions: builder.query<
      ListResponse<SurveyInstanceQuestion>,
      AppQuery
    >({
      query: (query) => ({
        service: 'survey',
        method: 'listInstanceQuestions',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('SurveyInstanceQuestion'),
    }),
    updateSurveyInstanceQuestion: builder.mutation<
      boolean,
      Partial<SurveyInstanceQuestion>
    >({
      query: (questionInstance) => ({
        service: 'survey',
        method: 'updateInstanceQuestion',
        params: questionInstance,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner'),
    }),
    updateSurvey: builder.mutation<boolean, Partial<Survey>>({
      query: (survey) => ({
        service: 'survey',
        method: 'update',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Survey'),
    }),
    createSurvey: builder.mutation<boolean, Partial<Survey>>({
      query: (survey) => ({
        service: 'survey',
        method: 'create',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Survey'),
    }),
    removeSurveys: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'survey',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Survey'),
    }),
    getSurvey: builder.query<Survey, string>({
      query: (id) => ({
        service: 'survey',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Survey'),
    }),
    listSurveysByConditionedQuestion: builder.query<Survey[], string>({
      query: (id) => ({
        service: 'survey',
        method: 'listByConditionedQuestion',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Survey'),
    }),

    // IndividualLearning
    listIndividualLearnings: builder.query<
      ListResponse<IndividualLearning>,
      AppQuery
    >({
      query: (query) => ({
        service: 'individualLearning',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('IndividualLearning'),
    }),
    listIndividualLearningInstances: builder.query<
      ListResponse<IndividualLearning>,
      AppQuery
    >({
      query: (query) => ({
        service: 'individualLearning',
        method: 'listInstances',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('IndividualLearning'),
    }),
    createIndividualLearning: builder.mutation<
      boolean,
      Partial<IndividualLearning>
    >({
      query: (data) => ({
        service: 'individualLearning',
        method: 'create',
        params: data,
      }),
      invalidatesTags: getInvalidatesTagsFunction('IndividualLearning'),
    }),
    createIndividualLearningWithInstances: builder.mutation<
      boolean,
      Partial<IndividualLearning>
    >({
      query: (data) => ({
        service: 'individualLearning',
        method: 'createWithInstances',
        params: data,
      }),
      invalidatesTags: getInvalidatesTagsFunction('IndividualLearning'),
    }),
    removeIndividualLearningItems: builder.mutation<boolean, string[]>({
      query: (data) => ({
        service: 'individualLearning',
        method: 'bulkDelete',
        params: data,
      }),
      invalidatesTags: getInvalidatesTagsFunction('IndividualLearning'),
    }),
    removeIndividualLearningInstances: builder.mutation<boolean, string[]>({
      query: (data) => ({
        service: 'individualLearning',
        method: 'bulkDeleteInstances',
        params: data,
      }),
      invalidatesTags: getInvalidatesTagsFunction('IndividualLearning'),
    }),
    updateIndividualLearning: builder.mutation<
      boolean,
      Partial<IndividualLearning>
    >({
      query: (params) => ({
        service: 'individualLearning',
        method: 'update',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('IndividualLearning'),
    }),
    updateIndividualLearningInstance: builder.mutation<
      boolean,
      Partial<IndividualLearning>
    >({
      query: (params) => ({
        service: 'individualLearning',
        method: 'updateInstance',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('IndividualLearning'),
    }),
    getIndividualLearning: builder.query<IndividualLearning, string>({
      query: (id) => ({
        service: 'individualLearning',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('IndividualLearning'),
    }),
    getIndividualLearningInstance: builder.query<
      IndividualLearningInstance,
      string
    >({
      query: (id) => ({
        service: 'individualLearning',
        method: 'getInstance',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('IndividualLearning'),
    }),
    listIndividualLearningsForSelect: builder.query<
      IndividualLearning[],
      { query: string; isAdmin?: boolean }
    >({
      query: (data) => ({
        service: 'individualLearning',
        method: 'listForSelect',
        params: data,
      }),
      providesTags: getProvidesTagsFunction(
        'IndividualLearning',
        'LIST_FOR_SELECT',
      ),
    }),

    //Checklist
    createChecklist: builder.mutation<boolean, Partial<Checklist>>({
      query: (checklist) => ({
        service: 'checklist',
        method: 'create',
        params: checklist,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Checklist'),
    }),
    createChecklistItem: builder.mutation<boolean, Partial<ChecklistItem>>({
      query: (checklistItem) => ({
        service: 'checklist',
        method: 'createItem',
        params: checklistItem,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ChecklistItem'),
    }),
    updateChecklist: builder.mutation<boolean, Partial<Checklist>>({
      query: (checklist) => ({
        service: 'checklist',
        method: 'update',
        params: checklist,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Checklist'),
    }),
    updateChecklistItem: builder.mutation<boolean, Partial<ChecklistItem>>({
      query: (checklistItem) => ({
        service: 'checklist',
        method: 'updateItem',
        params: checklistItem,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ChecklistItem'),
    }),
    removeChecklists: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'checklist',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Checklist'),
    }),
    removeChecklistItems: builder.mutation<boolean, string[]>({
      query: (itemIds) => ({
        service: 'checklist',
        method: 'bulkDeleteItems',
        params: itemIds,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ChecklistItem'),
    }),
    removeChecklistInstances: builder.mutation<boolean, string[]>({
      query: (instanceIds) => ({
        service: 'checklist',
        method: 'bulkDeleteInstances',
        params: instanceIds,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ChecklistInstance'),
    }),
    getChecklist: builder.query<Checklist, string>({
      query: (id) => ({
        service: 'checklist',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Checklist'),
    }),
    getChecklistItem: builder.query<ChecklistItem, string>({
      query: (itemId) => ({
        service: 'checklist',
        method: 'getItem',
        params: itemId,
      }),
      providesTags: getProvidesTagsFunction('ChecklistItem'),
    }),
    listChecklists: builder.query<ListResponse<Checklist>, AppQuery>({
      query: (query) => ({
        service: 'checklist',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Checklist'),
    }),
    listChecklistsForSelect: builder.query<Checklist[], string>({
      query: (query = '') => ({
        service: 'checklist',
        method: 'listForSelect',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Checklist', 'LIST_FOR_SELECT'),
    }),
    listChecklistItems: builder.query<ListResponse<ChecklistItem>, AppQuery>({
      query: (query) => ({
        service: 'checklist',
        method: 'listItems',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('ChecklistItem'),
    }),
    listChecklistItemsForSelect: builder.query<ChecklistItem[], string>({
      query: (query = '') => ({
        service: 'checklist',
        method: 'listItemsForSelect',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('ChecklistItem', 'LIST_FOR_SELECT'),
    }),
    listChecklistInstances: builder.query<
      ListResponse<ChecklistInstance>,
      AppQuery
    >({
      query: (query) => ({
        service: 'checklist',
        method: 'listInstances',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('ChecklistInstance'),
    }),
    listChecklistItemInstances: builder.query<
      ListResponse<ChecklistChecklistItemInstance>,
      string
    >({
      query: (instanceId) => ({
        service: 'checklist',
        method: 'listItemInstances',
        params: instanceId,
      }),
      providesTags: getProvidesTagsFunction('ChecklistChecklistItemInstance'),
    }),
    listChecklistItemInstancesByChecklistId: builder.query<
      ListResponse<ChecklistChecklistItemInstance>,
      string
    >({
      query: (id) => ({
        service: 'checklist',
        method: 'listItemInstancesByChecklistId',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('ChecklistChecklistItemInstance'),
    }),

    // Elearning Session
    listElearningSessions: builder.query<
      ListResponse<ElearningSession>,
      AppQuery
    >({
      query: (query) => ({
        service: 'elearningSession',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('ElearningSession'),
    }),
    updateElearningSession: builder.mutation<
      boolean,
      Partial<ElearningSession>
    >({
      query: (elearningSession) => ({
        service: 'elearningSession',
        method: 'update',
        params: elearningSession,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ElearningSession'),
    }),
    updateElearningSessionResult: builder.mutation<
      boolean,
      {
        id: string;
        score: number | null;
        status: number | null;
        startedOn: Date | null;
        finishedOn: Date | null;
        timeSpent: number | null;
      }
    >({
      query: (payload) => ({
        service: 'elearningSession',
        method: 'updateResult',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ElearningSession'),
    }),
    createElearningSession: builder.mutation<
      boolean,
      Partial<ElearningSession>
    >({
      query: (elearningSession) => ({
        service: 'elearningSession',
        method: 'create',
        params: elearningSession,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ElearningSession'),
    }),
    resetElearningSession: builder.mutation<boolean, string>({
      query: (id) => ({
        service: 'elearningSession',
        method: 'reset',
        params: id,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ElearningSession'),
    }),
    removeElearningSessions: builder.mutation<boolean, string>({
      query: (id) => ({
        service: 'elearningSession',
        method: 'remove',
        params: id,
      }),
      invalidatesTags: getInvalidatesTagsFunction('ElearningSession'),
    }),

    // Live Course
    getLiveCourse: builder.query<LiveCourse, string>({
      query: (id) => ({
        service: 'course',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('LiveCourse'),
    }),
    listLiveCourses: builder.query<ListResponse<LiveCourse>, AppQuery>({
      query: (query) => ({
        service: 'course',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LiveCourse'),
    }),
    listLiveCoursesWithSessions: builder.query<
      ListResponse<LiveCourse & { trainingSessions: LiveSession[] }>,
      AppQuery
    >({
      query: (query) => ({
        service: 'course',
        method: 'listWithSessions',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LiveCourse'),
    }),
    updateLiveCourse: builder.mutation<boolean, Partial<LiveCourse>>({
      query: (survey) => ({
        service: 'course',
        method: 'update',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveCourse'),
    }),
    createLiveCourse: builder.mutation<boolean, Partial<LiveCourse>>({
      query: (survey) => ({
        service: 'course',
        method: 'create',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveCourse'),
    }),
    removeLiveCourse: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'course',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveCourse'),
    }),
    getCourseStatistics: builder.query<
      LiveCourse & {
        totalSessionMinutes: Record<string, number | null>[];
        totalLearnerStatuses: Record<string, number | null>[];
        totalSessionStatuses: Record<string, number | null>[];
        totalSessionTypes: Record<string, number | null>[];
      },
      string
    >({
      query: (id) => ({
        service: 'course',
        method: 'statistics',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('LiveCourse'),
    }),
    getAllCoursesStatistics: builder.query<
      LiveCourse & {
        totalSessionMinutes: Record<string, number | null>[];
        totalLearnerStatuses: Record<string, number | null>[];
        totalSessionStatuses: Record<string, number | null>[];
        totalSessionTypes: Record<string, number | null>[];
      },
      AppQuery
    >({
      query: (query) => ({
        service: 'course',
        method: 'allStatistics',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LiveCourse'),
    }),

    // Live Session
    listLiveSessions: builder.query<ListResponse<LiveSession>, AppQuery>({
      query: (query) => ({
        service: 'session',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LiveSession'),
    }),
    getLiveSession: builder.query<LiveSession, string>({
      query: (id) => ({
        service: 'session',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('LiveSession'),
    }),
    updateLiveSession: builder.mutation<boolean, Partial<LiveSession>>({
      query: (survey) => ({
        service: 'session',
        method: 'update',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    updateLiveSessionStatus: builder.mutation<
      boolean,
      { id: string; status: number }
    >({
      query: (survey) => ({
        service: 'session',
        method: 'updateStatus',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    createLiveSession: builder.mutation<LiveSession, Partial<LiveSession>>({
      query: (survey) => ({
        service: 'session',
        method: 'create',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    updateLiveSessionTrainerStatus: builder.mutation<
      boolean,
      { id: string; sessionTrainerId: string; status: string }
    >({
      query: (payload) => ({
        service: 'session',
        method: 'updateTrainerStatus',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    removeLiveSessionTrainer: builder.mutation<
      boolean,
      { id: string; sessionTrainerId: string }
    >({
      query: (id) => ({
        service: 'session',
        method: 'deleteTrainer',
        params: id,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    addLiveSessionTrainer: builder.mutation<
      boolean,
      { id: string; trainerId: string }
    >({
      query: (payload) => ({
        service: 'session',
        method: 'addTrainer',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    bookLiveSessionTrainer: builder.mutation<boolean, string>({
      query: (payload) => ({
        service: 'email',
        method: 'bookTrainer',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    updateLiveSessionVenueStatus: builder.mutation<
      boolean,
      { id: string; status: string }
    >({
      query: (payload) => ({
        service: 'session',
        method: 'updateVenueStatus',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    removeLiveSessionVenue: builder.mutation<boolean, string>({
      query: (id) => ({
        service: 'session',
        method: 'deleteVenue',
        params: id,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    upsertLiveSessionVenue: builder.mutation<
      boolean,
      { id: string; venueId: string }
    >({
      query: (payload) => ({
        service: 'session',
        method: 'upsertVenue',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    bookLiveSessionVenue: builder.mutation<boolean, string>({
      query: (payload) => ({
        service: 'email',
        method: 'bookRoom',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    changeLiveSessionTrainer: builder.mutation<
      boolean,
      { id: string; sessionTrainerId: string; resourceTrainerId: string }
    >({
      query: (id) => ({
        service: 'session',
        method: 'changeTrainer',
        params: id,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    removeLiveSession: builder.mutation<boolean, string>({
      query: (id) => ({
        service: 'session',
        method: 'remove',
        params: id,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    //listSessionCosts
    listLiveSessionCosts: builder.query<
      any,
      { sessionId: string; query: AppQuery }
    >({
      query: (params) => ({
        service: 'session',
        method: 'listSessionCosts',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LiveSession'),
    }),
    getSessionMarkAttendanceData: builder.query<any, { token: string }>({
      query: (params) => ({
        service: 'session',
        method: 'getSessionMarkAttendanceData',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LiveSessionLearner'),
    }),
    exportSessionAttendanceForTrainer: builder.query<
      any,
      { token: string; ids: string[] }
    >({
      query: (params) => ({
        service: 'session',
        method: 'exportSessionAttendanceForTrainer',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LiveSessionLearner'),
    }),
    markLearnersAttendance: builder.mutation<
      any,
      { token: string; status: number; ids: string[] }
    >({
      query: (params) => ({
        service: 'session',
        method: 'markLearnersAttendance',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner'),
    }),
    addLiveSessionGoShows: builder.mutation<
      any,
      { token: string; emails: string[] }
    >({
      query: (params) => ({
        service: 'session',
        method: 'addGoShows',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner'),
    }),
    sendTrainerEmailToLiveSessionAdmin: builder.mutation<
      boolean,
      { token: string; message: string; subject: string }
    >({
      query: (params) => ({
        service: 'session',
        method: 'sendTrainerEmailToLiveSessionAdmin',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    removeLiveSessionCosts: builder.mutation<
      boolean,
      { sessionId: string; ids: string[] }
    >({
      query: (params) => ({
        service: 'session',
        method: 'removeCosts',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession', [
        'LiveSessionStatisticsReport',
      ]),
    }),
    createLiveSessionCosts: builder.mutation<
      boolean,
      { sessionId: string; data: any[] }
    >({
      query: (params) => ({
        service: 'session',
        method: 'createCosts',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession', [
        'LiveSessionStatisticsReport',
      ]),
    }),
    upsertSurveyFormAnswers: builder.mutation<
      boolean,
      { id: string; surveyFormId: string; answers: any[] } & (
        | { list: string[] }
        | { filters: AppQueryFilter }
      )
    >({
      query: (params) => ({
        service: 'session',
        method: 'upsertSurveyFormAnswers',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner', [
        'SurveyInstance',
      ]),
    }),

    // Assessment Instance
    listAssessmentInstances: builder.query<
      ListResponse<AssessmentInstance>,
      AppQuery
    >({
      query: (query) => ({
        service: 'assessment',
        method: 'listInstances',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('AssessmentInstance'),
    }),
    listAssessmentInstancesByAssessmentId: builder.query<
      ListResponse<AssessmentInstance>,
      { id: string; query: AppQuery }
    >({
      query: (params) => ({
        service: 'assessment',
        method: 'listInstancesByAssessmentId',
        params,
      }),
      providesTags: getProvidesTagsFunction('AssessmentInstance'),
    }),
    removeAssessmentInstances: builder.mutation<boolean, string[]>({
      query: (params) => ({
        service: 'assessment',
        method: 'removeInstances',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentInstance', [
        'Assessment',
      ]),
    }),
    getAssessmentItems: builder.query<
      ListResponse<AssessmentFormItem>,
      { id: string; query: AppQuery }
    >({
      query: (params) => ({
        service: 'assessment',
        method: 'getItems',
        params,
      }),
      providesTags: getProvidesTagsFunction('AssessmentFormItem'),
    }),

    // Activity Log
    listActivityLogIndividual: builder.query<
      ListResponse<ActivityLogItem>,
      AppQuery
    >({
      query: (filters) => ({
        service: 'activityLog',
        method: 'listIndividual',
        params: filters,
      }),
      providesTags: getProvidesTagsFunction('ActivityLog'),
    }),
    listActivityLogGrouped: builder.query<ActivityLogItem[], AppQueryFilter>({
      query: (filters) => ({
        service: 'activityLog',
        method: 'listGrouped',
        params: filters,
      }),
      providesTags: getProvidesTagsFunction('ActivityLog'),
    }),
    listSessionActivityLogs: builder.query<ActivityLogItem[], { id: string }>({
      query: (filters) => ({
        service: 'activityLog',
        method: 'listSessionActivityLogs',
        params: filters,
      }),
      providesTags: getProvidesTagsFunction('ActivityLog'),
    }),
    confirmLiveSessionApproval: builder.mutation<boolean, string>({
      query: (token) => ({
        service: 'session',
        method: 'confirmApproval',
        params: token,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    declineLiveSessionApproval: builder.mutation<boolean, string>({
      query: (token) => ({
        service: 'session',
        method: 'declineApproval',
        params: token,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    confirmLiveSessionVenue: builder.mutation<boolean, string>({
      query: (token) => ({
        service: 'session',
        method: 'confirmVenue',
        params: token,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    declineLiveSessionVenue: builder.mutation<boolean, string>({
      query: (token) => ({
        service: 'session',
        method: 'declineVenue',
        params: token,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    declineLiveSessionTrainer: builder.mutation<boolean, string>({
      query: (token) => ({
        service: 'session',
        method: 'declineTrainer',
        params: token,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),
    confirmLiveSessionTrainer: builder.mutation<boolean, string>({
      query: (token) => ({
        service: 'session',
        method: 'confirmTrainer',
        params: token,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSession'),
    }),

    // Learning Path
    getLearningPath: builder.query<LearningPath, string>({
      query: (id) => ({
        service: 'learningPath',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('LearningPath'),
    }),
    listLearningPaths: builder.query<ListResponse<LearningPath>, AppQuery>({
      query: (query) => ({
        service: 'learningPath',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearningPath'),
    }),
    updateLearningPath: builder.mutation<boolean, Partial<LearningPath>>({
      query: (survey) => ({
        service: 'learningPath',
        method: 'update',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearningPath'),
    }),
    createLearningPath: builder.mutation<boolean, Partial<LearningPath>>({
      query: (survey) => ({
        service: 'learningPath',
        method: 'create',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearningPath'),
    }),
    removeLearningPaths: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'learningPath',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearningPath'),
    }),
    listLearningPathInstances: builder.query<
      ListResponse<LearningPathLearnerInstance>,
      { query: AppQuery; id: string }
    >({
      query: (query) => ({
        service: 'learningPath',
        method: 'getInstancesWithItemsAsColumns',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearningPathInstance'),
    }),
    getLearningPathInstance: builder.query<
      ListResponse<LearningPathInstance>,
      { id: string; instanceId: string }
    >({
      query: (query) => ({
        service: 'learningPath',
        method: 'getInstance',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearningPathInstance'),
    }),
    removeLearningPathInstances: builder.mutation<boolean, string[]>({
      query: (instanceIds) => ({
        service: 'learningPath',
        method: 'bulkDeleteInstances',
        params: instanceIds,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearningPathInstance'),
    }),

    // Assessment
    getAssessment: builder.query<Assessment, string>({
      query: (id) => ({
        service: 'assessment',
        method: 'get',
        params: id,
      }),
      providesTags: getProvidesTagsFunction('Assessment'),
    }),
    listAssessments: builder.query<ListResponse<Assessment>, AppQuery>({
      query: (query) => ({
        service: 'assessment',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Assessment'),
    }),
    updateAssessment: builder.mutation<boolean, Partial<Assessment>>({
      query: (survey) => ({
        service: 'assessment',
        method: 'update',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Assessment'),
    }),
    addAssessmentItems: builder.mutation<
      boolean,
      {
        id: string;
        items:
          | { assessmentQuestionId: string; type: number }[]
          | {
              type: number;
              questionFilter: ApiQueryFilter;
              questionCount: number;
              tagIds: string[];
            }[];
      }
    >({
      query: (survey) => ({
        service: 'assessment',
        method: 'addItems',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentFormItem', [
        'Assessment',
      ]),
    }),
    reorderAssessmentItems: builder.mutation<
      boolean,
      {
        id: string;
        items: string[];
      }
    >({
      query: (survey) => ({
        service: 'assessment',
        method: 'reorderItems',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentFormItem', [
        'Assessment',
      ]),
    }),
    removeAssessmentItems: builder.mutation<
      boolean,
      {
        id: string;
        itemIds: string[];
      }
    >({
      query: (survey) => ({
        service: 'assessment',
        method: 'removeItems',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Assessment', [
        'AssessmentFormItem',
      ]),
    }),
    createAssessment: builder.mutation<boolean, Partial<Assessment>>({
      query: (survey) => ({
        service: 'assessment',
        method: 'create',
        params: survey,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Assessment'),
    }),
    removeAssessments: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'assessment',
        method: 'bulkDelete',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Assessment'),
    }),
    getAssessmentAttemptById: builder.query<
      any,
      { instanceId: string; attemptId: string }
    >({
      query: (params) => ({
        service: 'assessment',
        method: 'getAttemptById',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('Assessment'),
    }),
    scoreAssessmentAttempt: builder.mutation<
      boolean,
      { instanceId: string; attemptId: string; answers: any[] }
    >({
      query: (params) => ({
        service: 'assessment',
        method: 'scoreAttempt',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentInstance', [
        'AssessmentInstanceAttempt',
      ]),
    }),
    removeAssessmentInstanceAttempt: builder.mutation<
      boolean,
      { instanceId: string; attemptId: string }
    >({
      query: (params) => ({
        service: 'assessment',
        method: 'removeInstanceAttempt',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('AssessmentInstanceAttempt'),
    }),
    listAssessmentInstanceAttempts: builder.query<any, { id: string }>({
      query: (params) => ({
        service: 'assessment',
        method: 'listInstanceAttempts',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('AssessmentInstanceAttempt'),
    }),

    // Learner
    listLearnersByIds: builder.query<
      any,
      { id: string; firstName: string; lastName: string }
    >({
      query: (params) => ({
        service: 'learner',
        method: 'listByIds',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('Learner'),
    }),

    importLearners: builder.mutation<boolean, Partial<Learner>>({
      query: (file) => ({
        service: 'learner',
        method: 'importLearners',
        params: file,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Learner'),
    }),

    // Live Session Learner
    listLiveSessionLearners: builder.query<
      ListResponse<LiveSessionLearner>,
      AppQuery
    >({
      query: (body) => {
        return {
          service: 'session',
          method: 'listLearners',
          params: body,
        };
      },
      providesTags: getProvidesTagsFunction('LiveSessionLearner'),
    }),
    listLiveSessionAddableLearners: builder.query<
      Learner[],
      { id: string; query: { search: string } }
    >({
      query: (query) => {
        return {
          service: 'session',
          method: 'addableLearners',
          params: query,
        };
      },
      providesTags: getProvidesTagsFunction('LiveSessionLearner'),
    }),
    downloadLiveSessionAttendanceSheet: builder.query<any, { id: string }>({
      query: (params) => {
        return {
          service: 'session',
          method: 'downloadSessionAttendanceSheet',
          params: params,
        };
      },
      providesTags: getProvidesTagsFunction('LiveSessionLearner'),
    }),
    addLiveSessionLearners: builder.mutation<
      any,
      { status: number; learnerIds: string[]; liveSessionId: string }
    >({
      query: (params) => ({
        service: 'session',
        method: 'addLearners',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction(
        'LiveSessionLearner',
        (result, error, arg) => {
          const liveSessionId =
            'liveSessionId' in arg ? (arg.liveSessionId as string) : '';
          return [{ type: 'LiveSession' as const, id: liveSessionId }];
        },
      ),
    }),
    copyLiveSessionLearners: builder.mutation<
      boolean,
      { sessionIds: string[]; list: string[] | AppQueryFilter; status: number }
    >({
      query: (params) => ({
        service: 'session',
        method: 'copyLearners',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner'),
    }),
    updateLiveSessionLearnerStatus: builder.mutation<
      boolean,
      { status: number; list: string[] | AppQueryFilter }
    >({
      query: (params) => ({
        service: 'session',
        method: 'updateLearnerStatus',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner'),
    }),
    confirmLiveSessionLearner: builder.mutation<boolean, string[]>({
      query: (params) => ({
        service: 'session',
        method: 'confirmLearnerApprovals',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner'),
    }),
    declineLiveSessionLearner: builder.mutation<boolean, string[]>({
      query: (params) => ({
        service: 'session',
        method: 'declineLearnerApprovals',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner'),
    }),
    removeLiveSessionLearners: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        service: 'session',
        method: 'removeLearners',
        params: ids,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionLearner'),
    }),

    // Emails
    listEmails: builder.query<ListResponse<EmailItem>, AppQuery>({
      query: (query) => ({
        service: 'email',
        method: 'list',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('Email'),
    }),
    getEmailTemplatePreview: builder.query<any, any>({
      query: (payload) => ({
        service: 'email',
        method: 'getEmailTemplatePreview',
        params: payload,
      }),
      providesTags: getProvidesTagsFunction('EmailTemplate'),
    }),
    fetchAllEmailTemplates: builder.query<any, void>({
      query: (payload) => ({
        service: 'email',
        method: 'fetchAllTemplates',
        params: payload,
      }),
      providesTags: getProvidesTagsFunction('EmailTemplate'),
    }),

    // Search
    search: builder.query<AdminSearchResult[], string>({
      query: (searchTerm) => ({
        service: 'common',
        method: 'search',
        params: searchTerm,
      }),
      providesTags: ['Search'],
    }),

    listLearnerLearningActivity: builder.query<
      ListResponse<LearnerLearningActivity>,
      { query: AppQuery; learnerId: string }
    >({
      query: (params) => ({
        service: 'learner',
        method: 'listLearningActivity',
        params,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningActivity'),
    }),

    listAutomatedActions: builder.query<
      AutomatedAction[],
      { query: AppQuery; sessionId: string }
    >({
      query: (params) => ({
        service: 'session',
        method: 'fetchAutomatedActions',
        params,
      }),
      providesTags: getProvidesTagsFunction('LiveSessionAutomatedAction'),
    }),
    enableAutomatedAction: builder.mutation<
      boolean,
      { id: string; actionId: string }
    >({
      query: (payload) => ({
        service: 'session',
        method: 'enableAutomatedAction',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionAutomatedAction'),
    }),
    disableAutomatedAction: builder.mutation<
      boolean,
      { id: string; actionId: string }
    >({
      query: (payload) => ({
        service: 'session',
        method: 'disableAutomatedAction',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionAutomatedAction'),
    }),
    enableAutomation: builder.mutation<boolean, { id: string }>({
      query: (payload) => ({
        service: 'session',
        method: 'enableAutomation',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionAutomatedAction'),
    }),
    disableAutomation: builder.mutation<boolean, { id: string }>({
      query: (payload) => ({
        service: 'session',
        method: 'disableAutomation',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LiveSessionAutomatedAction'),
    }),
    sendEmail: builder.mutation<
      boolean,
      {
        payload: any;
        resourceName: any;
        actionType: any;
        getPreview: boolean;
        index?: number;
      }
    >({
      query: (payload) => ({
        service: 'email',
        method: 'sendEmail',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Email'),
    }),
    sendEmailWithTokenAuth: builder.mutation<
      boolean,
      {
        payload: any;
        resourceName: any;
        actionType: any;
        getPreview: boolean;
        token: string;
        index?: number;
      }
    >({
      query: (payload) => ({
        service: 'email',
        method: 'sendEmailWithTokenAuth',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('Email'),
    }),

    getUser: builder.query<any, {}>({
      query: (params) => ({
        service: 'user',
        method: 'get',
        params,
      }),
      providesTags: getProvidesTagsFunction('User'),
    }),
    updateUser: builder.mutation<
      boolean,
      { id: string; email: string; firstName: string; lastName: string }
    >({
      query: (payload) => ({
        service: 'user',
        method: 'edit',
        params: payload,
      }),
      invalidatesTags: getInvalidatesTagsFunction('User'),
    }),
    sendSupportEmail: builder.mutation<
      boolean,
      { subject: string; message: string; metadata: Record<string, unknown> }
    >({
      query: (payload) => ({
        service: 'common',
        method: 'sendSupportEmail',
        params: payload,
      }),
      invalidatesTags: [],
    }),

    // Generator entry point
  }),
});

export type AdminApiEndpointNames = keyof typeof adminApi.endpoints;
type FilterEndingWith<
  Set,
  Suffix extends string,
> = Set extends `${infer _X}${Suffix}` ? Set : never;
export type AdminApiMutationNames = FilterEndingWith<
  keyof typeof adminApi,
  'Mutation'
>;
