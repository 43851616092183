import { useAbility } from '@casl/react';
import React, { useCallback, useContext, useMemo } from 'react';

import {
  AppQueryFilter,
  FieldType,
  QueryFilter,
  QueryOperator,
} from '@nl-lms/common/shared';
import { LiveSessionLearnerStatus } from '@nl-lms/feature/live-learning/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Card,
  FloatingMenu,
  Icon,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  StatusTag,
  TableColumn,
  TableWithFullPagination,
  Tooltip,
  TooltipAlignment,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useClassNameProps, useRoutingAction } from '@nl-lms/ui/hooks';
import {
  FilterBar,
  ListViewProvider,
  useListViewContext,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { formatConstantString } from '@nl-lms/ui/utils';
import { AbilityContext } from '@nl-lms/web/Can';
import { authStore } from '@nl-lms/web/_common/modules/Auth/auth';
import {
  fetchAndMapLearners,
  fetchAndMapLiveSessions,
  mapAndLoadEnumsForSelect,
} from '@nl-lms/web/adminApp/_common/utils/fetchEntitiesForSelectMethods';
import { routes } from '@nl-lms/web/lib/routes';

import { liveLearningApi } from '../../../../../_common/services/api';
import { IWidgetComponent } from '../widget.types';
import './LiveSessionLearners.scss';

const { useListLiveSessionLearnersQuery } = liveLearningApi;

export const LiveSessionLearners: IWidgetComponent = ({
  label,
  baseFilters,
  description,
  ...props
}) => {
  const _baseFilters = useMemo<AppQueryFilter>(() => {
    const baseQuery = new QueryFilter({ filters: baseFilters });
    baseQuery.add({
      field: 'status',
      operator: QueryOperator.Includes,
      value: [
        LiveSessionLearnerStatus.INVITED,
        LiveSessionLearnerStatus.REGISTERED,
        LiveSessionLearnerStatus.APPROVAL,
      ],
    });

    return baseQuery.appQueryFilter;
  }, [baseFilters]);

  const classNameProps = useClassNameProps('live-session-learners', props);

  return (
    <ListViewProvider
      key="list-live-session-learners"
      name="list-live-session-learners"
      paginationLimit={10}
      initialSorting={[['updatedAt', 'desc']]}
      baseFilters={_baseFilters}
      useUrlQuery={false}
      persistToLocalStorage={false}
    >
      <Card {...classNameProps}>
        <Card.Header separatorMarginBottom={0}>
          <Card.HeaderTitle>
            {label || 'Live Session Attendance'}
            {!!description && (
              <span>
                <Tooltip
                  title={description}
                  className="dashboard-widget__tooltip"
                  align={TooltipAlignment.BOTTOM}
                >
                  <Icon.HelpIcon />
                </Tooltip>
              </span>
            )}
          </Card.HeaderTitle>
        </Card.Header>
        <Card.Content className="live-session-learners__table">
          <div className="live-session-learners__filters">
            <LiveSessionLearnersCardFilters />
          </div>
          <LiveSessionLearnersCardContent />
        </Card.Content>
      </Card>
    </ListViewProvider>
  );
};

const LiveSessionLearnersCardContent = () => {
  const {
    sorting,
    query,
    onChangePagination,
    onChangeSorting,
    filters,
    onChangeColumns,
  } = useListViewContext();
  const { data, isLoading, isFetching } = useListLiveSessionLearnersQuery({
    query: { query: transformTsRestQuery(query) },
  });
  const [rows, pagination] = useListViewTableData(data);

  return (
    <TableWithFullPagination
      data={rows}
      isLoading={isLoading || isFetching}
      columns={LiveSessionLearnersCardTableColumns}
      sorting={sorting}
      pagination={pagination}
      selectionMode="checkbox"
      onChangePagination={onChangePagination}
      onChangeSorting={onChangeSorting}
      onChangeColumns={onChangeColumns}
    >
      <NoDataPlaceholder.Container>
        <NoDataPlaceholder.Icon
          name={filters ? 'FileIcon' : 'FileTextIcon'}
          size="large"
          color={
            filters
              ? NoDataPlaceholderColor.warning
              : NoDataPlaceholderColor.default
          }
        />
        <NoDataPlaceholder.Title>
          {filters
            ? 'There are no live session learners that match your search'
            : 'There are no live session learners created'}
        </NoDataPlaceholder.Title>
        <NoDataPlaceholder.Subtitle>
          {filters
            ? 'You can try changing the active filters'
            : 'You can add live session learners if your current role allows it from the session view page. '}
        </NoDataPlaceholder.Subtitle>
      </NoDataPlaceholder.Container>
    </TableWithFullPagination>
  );
};

const LiveSessionLearnersCardFilters = () => {
  const { filters, onChangeFilters } = useListViewContext();

  return (
    <FilterBar
      id="live-session-learners-filters"
      fields={LiveSessionLearnersCardFilterFields}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

const LiveSessionLearnersCardTableColumns: TableColumn[] = [
  {
    Header: 'Learner',
    accessor: 'learner.id',
    sortField: 'learner_name',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      return (
        <Sensitive>
          {ability.can('view', 'learner_page') ? (
            <Link
              to={routes.admin.learners.item.path.full(row.original.learnerId)}
            >
              {row.original.learner.firstName} {row.original.learner.lastName}
            </Link>
          ) : (
            <span>
              {row.original.learner.firstName} {row.original.learner.lastName}
            </span>
          )}
        </Sensitive>
      );
    },
  },
  {
    Header: 'Session',
    accessor: 'trainingSessionName',
    sortField: 'trainingSessionName',
    Cell: ({ row }) => row?.original?.liveSession?.name,
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortField: 'status',
    Cell: ({ row }) => {
      return (
        <StatusTag
          status={formatConstantString(C.LEARNER_STATUS[row?.original?.status])}
        />
      );
    },
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    sortField: 'startDate',
    Cell: ({ row }) => (
      <PrettyDate value={row.original.liveSession?.startDate} withTime />
    ),
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    sortField: 'endDate',
    Cell: ({ row }) => (
      <PrettyDate value={row.original.liveSession?.endDate} withTime />
    ),
  },
];

const LiveSessionLearnersCardFilterFields = [
  {
    name: 'learner_id',
    label: 'Learner',
    type: FieldType.select,
    loadOptions: fetchAndMapLearners,
    ValueComponent: ({ children }) => {
      return <Sensitive>{children}</Sensitive>;
    },
  },
  {
    name: 'training_session_id',
    label: 'Training Session Id',
    type: FieldType.select,
    loadOptions: fetchAndMapLiveSessions,
  },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.select,
    loadOptions: mapAndLoadEnumsForSelect(LiveSessionLearnerStatus),
  },
  {
    name: 'start_date',
    label: 'Start Date',
    type: FieldType.datetime,
  },
  {
    name: 'end_date',
    label: 'End Date',
    type: FieldType.datetime,
  },
];
