import { useAbility } from '@casl/react';
import React, { useCallback, useMemo, useReducer, useState } from 'react';

import { LearningAssignmentLearningContentType } from '@nl-lms/common/feature/types';
import { FieldType, QueryFilter, QueryOperator } from '@nl-lms/common/shared';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Breadcrumbs,
  Icon,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Separator,
  TableWithFullPagination,
  Typography,
  TypographyProps,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  FilterBar,
  ListViewProvider,
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { formatConstantString, formatTimeDelta } from '@nl-lms/ui/utils';
import MainLayout from '@nl-lms/web/_common/layouts/MainLayout/MainLayout';

import { AbilityContext, Can } from '../../../Can';
import { useTableAction } from '../../../_common/hooks';
import { learningAssignmentsApi } from '../../../_common/services/api';
import { getActionAbility } from '../../../_common/utils/getActionAbility';
import { routes } from '../../../lib/routes';
import {
  fetchAndMapAssessmentForms,
  fetchAndMapAssignments,
  fetchAndMapAvailableElearningCourses,
  fetchAndMapChecklists,
  fetchAndMapLearnerGroups,
  fetchAndMapLearningPaths,
  fetchAndMapLiveCourses,
  fetchAndMapSurveys,
  mapAndLoadConstantsForSelect,
} from '../../_common/utils/fetchEntitiesForSelectMethods';
import { LearningAssignmentEditSideModal } from './LearningAssignmentEditSideModal';

const {
  useListLearningAssignmentsQuery,
  useRemoveLearningAssignmentsMutation,
} = learningAssignmentsApi;

export const LearningAssignmentListTablePage = () => {
  const [
    includeAssignmentsFromLearningPrograms,
    toggleIncludeAssignmentsFromLearningPrograms,
  ] = useReducer((state) => !state, false);

  const baseFilters = useMemo(() => {
    if (!includeAssignmentsFromLearningPrograms) {
      const queryFilter = new QueryFilter();
      queryFilter.add({
        field: 'learningProgramId',
        operator: QueryOperator.IsNull,
        value: '',
      });
      return queryFilter.appQueryFilter;
    }
    return undefined;
  }, [includeAssignmentsFromLearningPrograms]);
  return (
    <Can do="list" on="assignment">
      <MainLayout.Breadcrumbs>
        <Breadcrumbs>
          <Breadcrumbs.List>
            <Breadcrumbs.Item link={routes.admin.manage.path.full()}>
              Manage
            </Breadcrumbs.Item>
            <Breadcrumbs.Separator />
            <Breadcrumbs.Item isActive>Assignments</Breadcrumbs.Item>
          </Breadcrumbs.List>
        </Breadcrumbs>
      </MainLayout.Breadcrumbs>
      <ListViewProvider
        key="list-assignments"
        name="list-assignments"
        paginationLimit={10}
        baseFilters={baseFilters}
        initialSorting={[['createdAt', 'desc']]}
      >
        <LearningAssignmentListTableFilters
          includeAssignmentsFromLearningPrograms={
            includeAssignmentsFromLearningPrograms
          }
          toggleIncludeAssignmentsFromLearningPrograms={
            toggleIncludeAssignmentsFromLearningPrograms
          }
        />
        {/*// @ts-ignore */}
        <Separator marginTop={20} marginBottom={20} />
        <LearningAssignmentListTable.Label />
        <LearningAssignmentListTable
          includeAssignmentsFromLearningPrograms={
            includeAssignmentsFromLearningPrograms
          }
        />
      </ListViewProvider>
    </Can>
  );
};
export const LearningAssignmentListTable = ({
  includeAssignmentsFromLearningPrograms,
}: {
  includeAssignmentsFromLearningPrograms: boolean;
}) => {
  const { sorting, query, onChangePagination, onChangeSorting, filters } =
    useListViewContext();

  const { data, isLoading } = useListLearningAssignmentsQuery({
    query: { query: transformTsRestQuery(query) },
  });

  const ability = useAbility(AbilityContext);

  const [rows, pagination] = useListViewTableData(data);
  const [removeAssignments] = useRemoveLearningAssignmentsMutation();
  const removeWithContent = useCallback((params) => {
    return removeAssignments({ ...params, removeContent: true });
  }, []);
  const removeWithoutContent = useCallback((params) => {
    return removeAssignments({ ...params, removeContent: false });
  }, []);
  const showAssignmentEditModal = useShowModal(LearningAssignmentEditSideModal);
  const onEditRow = useCallback((assignment) => {
    showAssignmentEditModal({ assignment });
  }, []);
  const onRemoveRow = useTableAction(removeWithoutContent, {
    withRowCountProp: true,
    confirmationMessage:
      'This will ony remove the assignment data, but the corresponding learning sessions wil not be removed. Are you sure you want to remove the selected assignment? ',
  });
  const onRemoveRowWithContent = useTableAction(removeWithContent, {
    withRowCountProp: true,
    confirmationMessage:
      'This will ony remove the assignment data, AND ALL corresponding learning sessions. Are you sure you want to remove the selected assignment? ',
  });

  const rowActions = [
    ...getActionAbility('edit', 'assignment', {
      name: 'Edit',
      handler: onEditRow,
      Icon: Icon.EditIcon,
    }),
    ...getActionAbility('delete', 'assignment', {
      name: 'Delete',
      handler: onRemoveRowWithContent,
      options: [
        { name: 'Without Learning Sessions', handler: onRemoveRow },
        { name: 'With Learning Sessions', handler: onRemoveRowWithContent },
      ],
      Icon: Icon.DeleteIcon,
    }),
    // ...getActionAbility('convert', 'assignment', { name: 'Convert', handler: onConvertAssignment }),
    // ...getActionAbility('update_result', 'assignment', {
    //   name: 'Update with best result',
    //   handler: onUpdateAssignmentWithBestResult,
    // })
  ];

  const tableActions = [
    ...getActionAbility('delete', 'assignment', {
      name: 'Delete without learning sessions',
      handler: onRemoveRow,
    }),
    ...getActionAbility('delete', 'assignment', {
      name: 'Delete with learning sessions',
      handler: onRemoveRowWithContent,
    }),
  ];

  const [columns, onChangeColumns] = useListViewTableColumns([
    {
      Header: 'Assignment',
      accessor: 'name',
      sortField: 'name',
      Cell: ({ row }) => {
        if (row.original.name) {
          if (ability.can('view', 'assignment_page')) {
            return (
              <Link
                to={routes.admin.manage.assignments.item.path.full(
                  row.original.id,
                )}
              >
                {row.original.name}
              </Link>
            );
          } else return <span>{row.original.name}</span>;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Learning Item',
      accessor: 'item',
      sortField: 'item',
      Cell: ({ row }) => {
        if (row.original.elearningCourse) {
          return (
            <Link
              to={routes.admin.catalogue.elearningCourses.item.path.full(
                row.original.elearningCourse.id,
              )}
            >
              {row.original.elearningCourse.name}
            </Link>
          );
        } else if (row.original.learningPath) {
          return (
            <Link
              to={routes.admin.catalogue.learningPaths.item.path.full(
                row.original.learningPath.id,
              )}
            >
              {row.original.learningPath.name}
            </Link>
          );
        } else if (row.original.assessmentForm) {
          return (
            <Link
              to={routes.admin.catalogue.assessments.item.path.full(
                row.original.assessmentForm.id,
              )}
            >
              {row.original.assessmentForm.name}
            </Link>
          );
        } else if (row.original.surveyForm) {
          return (
            <Link
              to={routes.admin.catalogue.surveys.item.path.full(
                row.original.surveyForm.id,
              )}
            >
              {row.original.surveyForm.name}
            </Link>
          );
        } else if (row.original.checklist) {
          return (
            <Link
              to={routes.admin.catalogue.checklists.item.path.full(
                row.original.checklist.id,
              )}
            >
              {row.original.checklist.name}
            </Link>
          );
        } else if (row.original.liveCourse) {
          return (
            <Link
              to={routes.admin.catalogue.liveCourses.item.path.full(
                row.original.liveCourse.id,
              )}
            >
              {row.original.liveCourse.name}
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      sortField: 'type',
      Cell: ({ row }) =>
        formatConstantString(
          LearningAssignmentLearningContentType[row?.original?.content?.type],
        ),
    },
    // {
    //   Header: 'Total',
    //   accessor: 'total',
    //   Cell: ({ row }) => row.original.total,
    // },
    {
      Header: 'Mandatory',
      accessor: 'mandatory',
      sortField: 'mandatory',
      Cell: ({ row }) => (row.original.mandatory ? 'Yes' : 'No'),
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      sortField: 'dueDate',
      Cell: ({ row }) => {
        if (row.original.dueDateDelta) {
          return `${formatTimeDelta(row.original.dueDateDelta)}`;
        }

        return <PrettyDate value={row.original.dueDate} withTime />;
      },
    },
    {
      Header: 'Learning Program',
      accessor: 'learningProgram',
      sortField: 'learningProgram',
      Cell: ({ row }) =>
        row.original.learningProgramId ? (
          <Link
            to={routes.admin.manage.programs.item.path.full(
              row.original.learningProgramId,
            )}
          >
            {row.original.learningProgram?.name}
          </Link>
        ) : (
          '-'
        ),
    },
    // {
    //   Header: 'Assigned To',
    //   accessor: 'assignedTo',
    //   Cell: ({ row }) => (
    //     <Sensitive>
    //       <BadgeGroup name="assignmentAssignedTo" type={BadgeTypes.LIGHT}>
    //         {[...row.original.learners, ...row.original.learnerGroups].map(
    //           (row, index) => (
    //             <Badge
    //               key={index}
    //               label={row.title ? row.title : `${row.email}`}
    //               type={BadgeTypes.LIGHT}
    //             />
    //           )
    //         )}
    //       </BadgeGroup>
    //     </Sensitive>
    //   ),
    // },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortField: 'createdAt',
      Cell: ({ row }) => <PrettyDate value={row.original.createdAt} withTime />,
    },
  ]);

  const filteredColumns = useMemo(() => {
    if (!includeAssignmentsFromLearningPrograms) {
      return columns.filter((c) => c.accessor !== 'learningProgram');
    }
    return columns;
  }, [includeAssignmentsFromLearningPrograms, columns]);

  return (
    <TableWithFullPagination
      columns={filteredColumns}
      data={rows}
      isLoading={isLoading}
      sorting={sorting}
      tableActions={tableActions}
      rowActions={rowActions}
      pagination={pagination}
      onChangePagination={onChangePagination}
      onChangeSorting={onChangeSorting}
      onChangeColumns={onChangeColumns}
    >
      <NoDataPlaceholder
        iconName={filters ? 'FileIcon' : 'FileTextIcon'}
        color={
          filters
            ? NoDataPlaceholderColor.warning
            : NoDataPlaceholderColor.default
        }
        title={
          filters
            ? 'There are no assignments that match your search'
            : 'There are no assignments created'
        }
        // @ts-ignore
        subtitle={filters ? 'You can try changing the active filters' : null}
      />
    </TableWithFullPagination>
  );
};

export const LearningAssignmentListTableFilters = ({
  includeAssignmentsFromLearningPrograms,
  toggleIncludeAssignmentsFromLearningPrograms,
}: {
  includeAssignmentsFromLearningPrograms: boolean;
  toggleIncludeAssignmentsFromLearningPrograms: () => void;
}) => {
  const { filters, onChangeFilters } = useListViewContext();
  const ability = useAbility(AbilityContext);

  return (
    <FilterBar
      id="learning-assignment-view"
      settingsMenuOptions={[
        {
          name: includeAssignmentsFromLearningPrograms
            ? 'Exclude Learning Programs'
            : 'Include Learning Programs',
          handler: toggleIncludeAssignmentsFromLearningPrograms,
        },
      ]}
      fields={[
        {
          name: 'id',
          label: 'Assignment',
          type: FieldType.select,
          loadOptions: fetchAndMapAssignments,
        },
        ...getActionAbility('list', 'elearning_course', {
          name: 'elearning_course_id',
          label: 'Elearning Course',
          type: FieldType.select,
          loadOptions: fetchAndMapAvailableElearningCourses,
        }),
        ...getActionAbility('list', 'assessment_form', {
          name: 'assessment_form_id',
          label: 'Assessment Form',
          type: FieldType.select,
          loadOptions: fetchAndMapAssessmentForms,
        }),
        ...getActionAbility('list', 'survey', {
          name: 'survey_form_id',
          label: 'Survey Form',
          type: FieldType.select,
          loadOptions: fetchAndMapSurveys,
        }),
        ...getActionAbility('list', 'learning_path', {
          name: 'learning_path_id',
          label: 'Learning Path',
          type: FieldType.select,
          loadOptions: fetchAndMapLearningPaths,
        }),
        ...getActionAbility('list', 'live_course', {
          name: 'course_id',
          label: 'Live Course',
          type: FieldType.select,
          loadOptions: fetchAndMapLiveCourses,
        }),
        ...getActionAbility('list', 'checklist', {
          name: 'checklist_id',
          label: 'Checklist',
          type: FieldType.select,
          loadOptions: fetchAndMapChecklists,
        }),
        {
          name: 'name',
          label: 'Name',
          type: FieldType.string,
        },
        {
          name: 'type',
          label: 'Type',
          type: FieldType.select,
          loadOptions: mapAndLoadConstantsForSelect({
            ...(ability.can('list', 'elearning_course')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.ELEARNING]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.ELEARNING
                    ],
                }
              : {}),
            ...(ability.can('list', 'assessment_form')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.ASSESSMENT]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.ASSESSMENT
                    ],
                }
              : {}),
            ...(ability.can('list', 'learning_path')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.LEARNING_PATH]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.LEARNING_PATH
                    ],
                }
              : {}),
            ...(ability.can('list', 'live_course')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.LIVE_COURSE]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.LIVE_COURSE
                    ],
                }
              : {}),
            ...(ability.can('list', 'survey')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.SURVEY]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.SURVEY
                    ],
                }
              : {}),
            ...(ability.can('list', 'checklist')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.CHECKLIST]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.CHECKLIST
                    ],
                }
              : {}),
          }),
        },
        {
          name: 'mandatory',
          label: 'Mandatory',
          type: FieldType.boolean,
        },
        {
          name: 'show_after_due_date',
          label: 'Show After Due Date',
          type: FieldType.boolean,
        },
        {
          name: 'start_date',
          label: 'Start Date',
          type: FieldType.datetime,
        },
        {
          name: 'due_date',
          label: 'Due Date',
          type: FieldType.datetime,
        },
        {
          name: 'auto_assign',
          label: 'Auto Assign',
          type: FieldType.boolean,
        },
        {
          name: 'learner_group_ids',
          label: 'Assigned To',
          type: FieldType.jsonArraySelect,
          loadOptions: fetchAndMapLearnerGroups,
        },
        {
          name: 'reminder_offset',
          label: 'Reminder Offset',
          type: FieldType.number,
        },
        {
          name: 'created_at',
          label: 'Created At',
          type: FieldType.date,
        },
        {
          name: 'search',
          label: 'Search',
          type: FieldType.string,
        },
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

const LearningAssignmentListTableLabel = ({
  variant = 'h3',
}: {
  variant?: TypographyProps['variant'];
}) => {
  const { query } = useListViewContext();
  const { data, isLoading } = useListLearningAssignmentsQuery({
    query: { query: transformTsRestQuery(query) },
  });

  if (isLoading) {
    return <Typography variant={variant}>Fetching Rows</Typography>;
  }

  const actualCount = data?.count || 0;
  return (
    <Typography variant={variant}>
      {actualCount || 0} Learning Assignment
      {actualCount > 1 ? 's' : ''}
    </Typography>
  );
};

LearningAssignmentListTable.Label = LearningAssignmentListTableLabel;
